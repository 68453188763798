---
de_CH:
  txt:
    title: 'Tierhaltungs-Rechner'
    buttonNext: 'Weiter'
    buttonPrevious: 'Zurück'
    buttonClose: 'Schliessen'
    buttonAcceptWarning: 'Verstanden'
    domain: 'tierhaltungsrechner.ch'
    link: 'https://www.tierhaltungsrechner.ch'
    trButton:
      cta: 'Erfahre mehr über artgerechte Haltung:'
      buttonText: 'Tierhaltungsrechner öffnen'
    categories:
      rodents:
        name: 'Nager & Kaninchen'
      reptiles:
        name: 'Reptilien'
      birds:
        name: 'Vögel'
      equidae:
        name: 'Equiden'
    animals:
      rodents:
        degu:
          name: 'Degu'
          namePlural: 'Degus'
          minAnimalsErrorMessage: 'Degus sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Maximale Gruppengrösse überschritten. Degus sollten in Gruppen von 2-5 Tieren gehalten werden.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Degus sollten in Gruppen von 2-5 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Gruppengrösse: 2 - 5 Tiere\nGruppenzusammenstellung: reine Weibchengruppe, reine Männchengruppe, ein kastriertes Männchen mit mehreren Weibchen\n"
          laws:
            furnishing: "Geeignete Einstreu zum Graben, Rückzugsmöglichkeiten für alle Tiere, grob strukturiertes Futter (Heu oder Stroh), Nageobjekte (frische Äste), Sandbad\n"
            raisedAreas: "Erhöhte Flächen dürfen nicht bei der Mindestfläche angerechnet werden.\n"
            socialStructure: "Degus sind soziale Tiere und dürfen nicht ohne Artgenossen gehalten werden.\n"
          links:
            '0':
              text: 'STS Merblatt Degu'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_degu_de.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        dwarfHamster:
          name: 'Zwerghamster'
          namePlural: 'Zwerghamster'
          minAnimalsWarningMessage: 'Achtung, die Haltung von Zwerghamstern ist sehr anspruchsvoll und höchstens für erfahrene Nagerhalter möglich.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich!'
          recommendations:
            socialStructure: "Die Zwerghamsterhaltung ist anspruchsvoll und erfordert viel Erfahrung. Erkundigen Sie sich vor dem Kauf bei Fachpersonen, welche Arten in welchen Gruppengrössen und Gruppenzusammenstellungen gehalten werden können.\n"
          laws:
            furnishing: "Klettermöglichkeiten, geeignete Einstreu zum Graben, Rückzugsmöglichkeiten für alle Tiere, geeignetes Nestmaterial, grob strukturiertes Futter (Heu, Stroh, Körnerbeimischungen), Nageobjekte (frische Äste)\n"
            raisedAreas: "Erhöhte Flächen dürfen nicht bei der Mindestfläche angerechnet werden.\n"
            socialStructure: "Sowohl die Einzel- wie auch Gruppenhaltung von Zwerghamstern ist erlaubt.\n"
          links:
            '0':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        fancyMouse:
          name: 'Farbmaus'
          namePlural: 'Farbmäuse'
          minAnimalsErrorMessage: 'Farbmäuse sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Maximale Gruppengrösse überschritten. Farbmäuse sollten in Gruppen von 2-6 Tieren gehalten werden.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Farbmäuse sollten in Gruppen von 2-6 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Gruppengrösse: 2 - 6 Tiere\nGruppenzusammenstellung: reine Weibchengruppe, ein kastriertes Männchen mit mehreren Weibchen\n"
          laws:
            furnishing: "Klettermöglichkeiten, geeignete Einstreu, Rückzugsmöglichkeiten für alle Tiere, geeignetes Nestmaterial, grob strukturiertes Futter (Heu, Stroh, Körnerbeimischungen), Nageobjekte (frische Äste)\n"
            raisedAreas: "Erhöhte Flächen dürfen nicht bei der Mindestfläche angerechnet werden.\n"
            socialStructure: "Farbmäuse sind soziale Tiere und dürfen nicht ohne Artgenossen gehalten werden.\n"
          links:
            '0':
              text: 'STS Merblatt Farbmaus'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        gerbil:
          name: 'Rennmaus'
          namePlural: 'Rennmäuse'
          minAnimalsErrorMessage: 'Rennmäuse sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Maximale Gruppengrösse überschritten. Rennmäuse sollten in Gruppen von 2 Tieren gehalten werden.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Rennmäuse sollten in Gruppen von 2 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Gruppengrösse: 2 Tiere\nGruppenzusammenstellung: reine Weibchengruppe, reine Männchengruppe, ein kastriertes Männchen mit einem Weibchen\n"
          laws:
            furnishing: "Geeignete Einstreu zum Graben, Rückzugsmöglichkeiten für alle Tiere, geeignetes Nestmaterial, grob strukturiertes Futter (Heu oder Stroh), Nageobjekte (frische Äste), Sandbad\n"
            raisedAreas: "Erhöhte Flächen dürfen nicht bei der Mindestfläche angerechnet werden.\n"
            socialStructure: "Rennmäuse sind soziale Tiere und dürfen nicht ohne Artgenossen gehalten werden.\n"
          links:
            '0':
              text: 'STS Merblatt Rennmaus'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_rennmaus_de.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        goldenHamster:
          name: 'Goldhamster'
          namePlural: 'Goldhamster'
          maxAnimalsErrorMessage: 'Goldhamster sind Einzelgänger und müssen alleine gehalten werden.'
          recommendations:
            socialStructure: "Gruppengrösse: Einzelhaltung\n"
          laws:
            furnishing: "Klettermöglichkeiten, geeignete Einstreu zum Graben, Rückzugsmöglichkeiten für alle Tiere, geeignetes Nestmaterial, grob strukturiertes Futter (Heu, Stroh, Körnerbeimischungen), Nageobjekte (frische Äste)\n"
            raisedAreas: "Erhöhte Flächen dürfen nicht bei der Mindestfläche angerechnet werden.\n"
            socialStructure: "Goldhamster sind Einzelgänger und müssen alleine gehalten werden.\n"
          links:
            '0':
              text: 'STS Merblatt Goldhamster'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_goldhamster_de.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        guineaPig:
          name: 'Meerschweinchen'
          namePlural: 'Meerschweinchen'
          minAnimalsErrorMessage: 'Meerschweinchen sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Maximale Gruppengrösse überschritten. Meerschweinchen sollten in Gruppen von 2-6 Tieren gehalten werden.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Meerschweinchen sollten in Gruppen von 2-6 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Gruppengrösse: 2 - 6 Tiere\nGruppenzusammenstellung: reine Weibchengruppe, ein kastriertes Männchen mit mehreren Weibchen, z.T. auch reine Männchengruppen möglich\n"
          laws:
            furnishing: "Geeignete Einstreu, Rückzugsmöglichkeiten für alle Tiere,  grob strukturiertes Futter (Heu oder Stroh), Nageobjekte (frische Äste), Vitamin C-haltiges Futter\n"
            raisedAreas: "Begehbare erhöhte Flächen können bis zu einem Drittel der geforderten Mindestfläche angerechnet werden, vorausgesetzt die Fläche unter der erhöhten Ebene kann von den Tieren auch genutzt werden.\n"
            socialStructure: "Meerschweinchen sind soziale Tiere und dürfen nicht ohne Artgenossen gehalten werden.\n"
          links:
            '0':
              text: 'STS Merblatt Meerschweinchen'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_meerschweinchen_de.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        rabbit:
          name: 'Kaninchen'
          namePlural: 'Kaninchen'
          minAnimalsErrorMessage: 'Kaninchen sind soziale Tiere. Die Einzelhaltung ist nur erlaubt, wenn Hör- bzw. Geruchkontakt zu Artgenossen besteht!'
          maxAnimalsWarningMessage: 'Ab einer Gruppengrösse von 3 Tieren existieren derzeit nur Empfehlungen zur Mindestfläche. Die aktuellen Flächenempfehlungen sind sehr klein und werden derzeit überarbeitet.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Kaninchen sollten in Gruppen von 2-6 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Gruppengrösse: 2 - 6 Tiere\nGruppenzusammenstellung: ein kastriertes Männchen mit 1 oder 2 Weibchen, 2 kastrierte Männchen mit 2 - 4 Weibchen, reine Weibchengruppen z.T. auch möglich\n"
          laws:
            furnishing: "Rückzugsmöglichkeiten für alle Tiere, grob strukturiertes Futter (Heu oder Stroh), Nageobjekte (frische Äste)\n"
            raisedAreas: "Begehbare erhöhte Flächen können der geforderten Mindestfläche angerechnet werden, vorausgesetzt die Fläche unter der erhöhten Ebene kann von den Tieren auch genutzt werden (mind. 20 cm).\n"
            socialStructure: "Kaninchen sind soziale Tiere. Eine Einzelhaltung ist nur erlaubt, wenn sie Hör- und Geruchkontakt zu Artgenossen haben.\n"
          links:
            '0':
              text: 'STS Merblatt Kaninchen'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_kaninchen_de.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
        rat:
          name: 'Ratte'
          namePlural: 'Ratten'
          minAnimalsErrorMessage: 'Ratten sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Maximale Gruppengrösse überschritten. Ratten sollten in Gruppen von 2 - 6 Tieren gehalten werden.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Ratten sollten in Gruppen von 2-6 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Empfohlene Gruppengrösse: 2 - 6 Tiere\nGruppenzusammenstellungen: reine Männchengruppen, reine Weibchengruppen, gemischte Gruppe mit kastrierten Männchen\n"
          laws:
            furnishing: "geeignete Einstreu, Rückzugsmöglichkeiten für alle Tiere, geeignetes Nestmaterial, grob strukturiertes Futter (Heu oder Stroh), Nageobjekte (frische Äste)\n"
            raisedAreas: "Erhöhte Flächen dürfen nicht bei der Mindestfläche angerechnet werden.\n"
            socialStructure: "Ratten sind soziale Tiere und dürfen nicht ohne Artgenossen gehalten werden.\n"
          links:
            '0':
              text: 'STS Merblatt Ratten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_ratten_de.pdf'
            '1':
              text: 'Broschüre Nager und Kaninchen tiergerecht halten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste Tierhaltung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_de.pdf'
            '3':
              text: 'STS Merkblatt Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Checkliste Zoofachgeschäft'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_de.pdf'
      reptiles:
        royalPython:
          name: 'Königspython'
          namePlural: 'Königspythons'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schlangen wird die gesamte Körperlänge gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Königspythons. Königspythons sind gut zu vergesellschaften mit Artgenossen.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Königspythons. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Königspythons. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Bademöglichkeit, Versteckmöglichkeiten, artgemässe Temperatur und klimatische Bedingungen, Klettermöglichkeiten\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: 'Ja'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, die Beleuchtung muss so installiert sein, dass sich die Tiere nicht daran verbrennen können, Klettermöglichkeiten, Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere, z.B. Korkröhren, Wurzeln, Äste und erhöhte Flächen,  Wetbox, Badebecken,  Wasser sowie artgemässes Futter (Futtertiere)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 26-32°C, lokaler Wärmespot 36°C, nachts Abkühlung, Königspythons sind untereinander recht gut verträglich und können bei ausreichend grossem Terrarium auch zu zweit oder zu dritt gehalten werden.\n"
            waterBasin: 'Badebecken'
          links:
            '0':
              text: 'STS Merkblatt Königspython'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_k%C3%B6nigspython_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        cornSnake:
          name: 'Kornnatter'
          namePlural: 'Kornnattern'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schlangen wird die gesamte Körperlänge gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Kornnattern. Kornnattern sind gut zu vergesellschaften mit Artgenossen.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Kornnattern. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Kornnattern. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Artgemässe Temperatur und klimatische Bedingungen, Versteckmöglichkeiten, Klettermöglichkeiten\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, die Beleuchtung muss so installiert sein, dass sich die Tiere nicht daran verbrennen können, Badebecken, Klettermöglichkeiten, lockeres Bodensubstrat, Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere, z.B. Korkröhren, Wurzeln, Äste, Wetbox, Wasser sowie artgemässes Futter (Futtertiere)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 24-28°C, lokaler Wärmespot 35°C, nachts Abkühlung, je nach Herkunftsklima ist eine kühle Überwinterung zu empfehlen.\n"
            waterBasin: 'Badebecken'
          links:
            '0':
              text: 'STS Merkblatt Kornnatter'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_kornnatter_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        greekTortoise:
          name: 'Griechische Landschildkröte'
          namePlural: 'Griechische Landschildkröten'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schildkröten wird die Panzerlänge des grössten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Versteckmöglichkeiten und Boden zum Graben, Sonnenplatz sowie eine UV-emittierende Lichtquelle, Zugang zu natürlichem Sonnenlicht, zusätzlich Auslauf im Freien, Möglichkeit zum Überwintern\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Freilandgehege mit ausbruchsicherer Umzäunung und Abtrennmöglichkeiten, Schutzhaus, z.B. Treibhaus, Frühbeet, abwechslungsreicher, teilweise grabbarer Bodengrund (Steine, Sand, Kies, Jurakalk etc.), Sonnenplätze, Rückzugsmöglichkeiten, z.B. Bepflanzung, Wurzeln, Steine etc., Wasser sowie artgemässes Futter (Heu, Kräuter)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Überwinterung, an die Einrichtung des Geheges und an die Fütterung müssen berücksichtigt werden. Eine sichere Überwinterungsmöglichkeit ist wichtig.\n"
            more: "Landschildkröten haben eine sehr hohe Lebenserwartung (40-80 Jahre)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'STS Merkblatt Europäische Landschildkröten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_de.pdf'
            '1':
              text: 'Tierfreundliche Schildkrötenhaltung in der Praxis'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_de.pdf'
            '2':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '3':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        moorishTortoise:
          name: 'Maurische Landschildkröte'
          namePlural: 'Maurische Landschildkröten'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schildkröten wird die Panzerlänge des grössten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Versteckmöglichkeiten und Boden zum Graben, Sonnenplatz sowie eine UV-emittierende Lichtquelle, Zugang zu natürlichem Sonnenlicht, zusätzlich Auslauf im Freien, Möglichkeit zum Überwintern\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Freilandgehege mit ausbruchsicherer Umzäunung und Abtrennmöglichkeiten, Schutzhaus, z.B. Treibhaus, Frühbeet, abwechslungsreicher, teilweise grabbarer Bodengrund (Steine, Sand, Kies, Jurakalk etc.), Sonnenplätze, Rückzugsmöglichkeiten, z.B. Bepflanzung, Wurzeln, Steine etc., Wasser sowie artgemässes Futter (Heu, Kräuter)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Überwinterung, an die Einrichtung des Geheges und an die Fütterung müssen berücksichtigt werden. Eine sichere Überwinterungsmöglichkeit ist wichtig.\n"
            more: "Landschildkröten haben eine sehr hohe Lebenserwartung (40-80 Jahre)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'STS Merkblatt Europäische Landschildkröten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_de.pdf'
            '1':
              text: 'Tierfreundliche Schildkrötenhaltung in der Praxis'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_de.pdf'
            '2':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '3':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        wideShelledTortoise:
          name: 'Breitrandschildkröte'
          namePlural: 'Breitrandschildkröten'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schildkröten wird die Panzerlänge des grössten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Landschildkröten. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Versteckmöglichkeiten und Boden zum Graben, Sonnenplatz sowie eine UV-emittierende Lichtquelle, Zugang zu natürlichem Sonnenlicht, zusätzlich Auslauf im Freien, Möglichkeit zum Überwintern\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Freilandgehege mit ausbruchsicherer Umzäunung und Abtrennmöglichkeiten, Schutzhaus, z.B. Treibhaus, Frühbeet, abwechslungsreicher, teilweise grabbarer Bodengrund (Steine, Sand, Kies, Jurakalk etc.), Sonnenplätze, Rückzugsmöglichkeiten, z.B. Bepflanzung, Wurzeln, Steine etc., Wasser sowie artgemässes Futter (Heu, Kräuter)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Überwinterung, an die Einrichtung des Geheges und an die Fütterung müssen berücksichtigt werden. Eine sichere Überwinterungsmöglichkeit ist wichtig.\n"
            more: "Landschildkröten haben eine sehr hohe Lebenserwartung (40-80 Jahre)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'STS Merkblatt Europäische Landschildkröten'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_de.pdf'
            '1':
              text: 'Tierfreundliche Schildkrötenhaltung in der Praxis'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_de.pdf'
            '2':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '3':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        crownedGecko:
          name: 'Kronengecko'
          namePlural: 'Kronengeckos'
          article: 'der'
          articlePlural: 'die'
          textLength: 'Bei Echsen wird die Körperlänge (ohne Schwanz) des längsten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Kronengeckos. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Kronengeckos. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Kronengeckos. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Versteckmöglichkeiten, artgemässe Temperatur und klimatische Bedingungen, Klettermöglichkeiten\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere (z.B. Korkröhren, Wurzeln, Pflanzen), Klettermöglichkeiten (z.B. fein verzweigte Äste, Pflanzen), Bodengrund zum Graben, Wasser sowie artgemässes Futter (Insekten)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-30°C, nachts Abkühlung. Wintertemperaturen 5°-6°C tiefer. Männliche Tiere sind untereinander unverträglich, ihre Vergesellschaftung ist nicht vorzusehen.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '1':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        boaConstrictor:
          name: 'Abgottschlange'
          namePlural: 'Abgottschlangen'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schlangen wird die gesamte Körperlänge gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Abgottschlangen. Abgottschlangen sind untereinander recht gut verträglich und können bei ausreichend grossem Terrarium auch zu zweit oder zu dritt gehalten werden.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Abgottschlangen. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Abgottschlangen. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Bademöglichkeit, Versteckmöglichkeiten, artgemässe Temperatur und klimatische Bedingungen, Klettermöglichkeiten\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: 'Ja'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, die Beleuchtung muss so installiert sein, dass sich die Tiere nicht daran verbrennen können, Klettermöglichkeiten, Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere, z.B. Korkröhren, Wurzeln, Äste und erhöhte Flächen,  Wetbox, Badebecken,  Wasser sowie artgemässes Futter (Futtertiere)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 26-32°C, lokaler Wärmespot 36°C, nachts Abkühlung, Abgottschlangen sind untereinander recht gut verträglich und können bei ausreichend grossem Terrarium auch zu zweit oder zu dritt gehalten werden.\n"
            more: "Abgottschlangen können über 4m lang werden. Die hier angegebenen Werte gelten für Tiere bis 250cm Länge. Grössere Tiere benötigen grössere Gehege.\n"
            waterBasin: 'Badebecken 0.5 m²'
          links:
            '0':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '1':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        muskTurtle:
          name: 'Gewöhnliche Moschusschildkröte'
          namePlural: 'Gewöhnliche Moschusschildkröten'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schildkröten wird die Panzerlänge des grössten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Moschusschildkröten. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Moschusschildkröten. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Moschusschildkröten. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Artgemässe Temperatur und klimatische Bedingungen, Sonnenplatz sowie eine UV-emittierende Lichtquelle, Versteckmöglichkeiten\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: 'zusätzlich zur Fläche'
          recommendations:
            furnishing: "Artgemässe Temperatur und klimatische Bedingungen, Sonnenplatz sowie eine UV-emittierende Lichtquelle, Versteckmöglichkeiten\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-28°C, lokaler Wärmespot 35°C, nachts Abkühlung, eine kühle Überwinterung ist zu empfehlen.\n"
            waterBasin: 'der Gesamtfläche'
          links:
            '0':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '1':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        kingSnake:
          name: 'Königsnatter'
          namePlural: 'Königsnattern'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Schlangen wird die gesamte Körperlänge gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Königsnattern.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Königsnattern. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Königsnattern. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Artgemässe Temperatur und klimatische Bedingungen, Versteckmöglichkeiten\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, die Beleuchtung muss so installiert sein, dass sich die Tiere nicht daran verbrennen können, Badebecken, Klettermöglichkeiten, lockeres Bodensubstrat, Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere, z.B. Korkröhren, Wurzeln, Äste, Wetbox, Wasser sowie artgemässes Futter (Futtertiere)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-30°C, lokaler Wärmespot 35°C, nachts Abkühlung, je nach Herkunftsklima ist eine kühle Überwinterung zu empfehlen.\n"
            more: "Königsnattern ernähren sich auch von anderen Schlangen und dürfen deshalb nicht mit anderen Schlangen vergesellschaftet werden.\n"
            waterBasin: 'Badebecken'
          links:
            '0':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '1':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        madagascarDayGecko:
          name: 'Grosser Madagaskar Taggecko'
          namePlural: 'Grosse Madagaskar Taggeckos'
          article: 'der'
          articlePlural: 'die'
          textLength: 'Bei Echsen wird die Körperlänge (ohne Schwanz) des längsten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Taggeckos. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Taggeckos. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Taggeckos. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Horizontale und vertikale Klettermöglichkeiten, Versteckmöglichkeiten, artgemässe klimatische Bedingungen und Sonnenplatz sowie eine UV-emittierende Lichtquelle\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, die UV-Beleuchtung muss so installiert sein, dass alle Tiere Zugang zum Licht haben (passende Platzierung und ausreichende Anzahl Sonnenplätze), sich aber nicht an den Lampen verbrennen können, System zur Produktion der für die jeweilige Art nötigen Luftfeuchtigkeit, z.B. Vernebler, Beregnungsanlage, Sprühflasche, Bepflanzung, Wasserfall etc., Messsystem für Temperatur und Luftfeuchtigkeit, vielfältige und artgemässe, mindestens körperdicke Klettermöglichkeiten (Rückwand, Äste, Bambusstäbe, breitblättrige Pflanzen etc.), artgemässe Rückzugsmöglichkeiten durch Bepflanzung, Wurzeln, Steine, aufgeschnittene Bambusrohre etc., artgemässer Bodengrund, Wasser sowie artgemässes Futter (Insekten, Nektar, Fruchtbrei)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-30°C, lokaler Wärmespot 40°C, nachts Abkühlung. Männliche Tiere sind untereinander unverträglich, ihre Vergesellschaftung ist nicht vorzusehen.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Merkblatt Grosser Madagaskar-Taggecko'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_taggecko_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        goldDustDayGecko:
          name: 'Goldstaub Taggecko'
          namePlural: 'Goldstaub Taggeckos'
          article: 'der'
          articlePlural: 'die'
          textLength: 'Bei Echsen wird die Körperlänge (ohne Schwanz) des längsten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Taggeckos. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Taggeckos. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Taggeckos. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Horizontale und vertikale Klettermöglichkeiten, Versteckmöglichkeiten, artgemässe klimatische Bedingungen und Sonnenplatz sowie eine UV-emittierende Lichtquelle\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung, die UV-Beleuchtung muss so installiert sein, dass alle Tiere Zugang zum Licht haben (passende Platzierung und ausreichende Anzahl Sonnenplätze), sich aber nicht an den Lampen verbrennen können, System zur Produktion der für die jeweilige Art nötigen Luftfeuchtigkeit, z.B. Vernebler, Beregnungsanlage, Sprühflasche, Bepflanzung, Wasserfall etc., Messsystem für Temperatur und Luftfeuchtigkeit, vielfältige und artgemässe, mindestens körperdicke Klettermöglichkeiten (Rückwand, Äste, Bambusstäbe, breitblättrige Pflanzen etc.), artgemässe Rückzugsmöglichkeiten durch Bepflanzung, Wurzeln, Steine, aufgeschnittene Bambusrohre etc., artgemässer Bodengrund, Wasser sowie artgemässes Futter (Insekten, Nektar, Fruchtbrei)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-30°C, lokaler Wärmespot 40°C, nachts Abkühlung. Männliche Tiere sind untereinander unverträglich, ihre Vergesellschaftung ist nicht vorzusehen.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Merkblatt Grosser Madagaskar-Taggecko'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_taggecko_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        leopardGecko:
          name: 'Leopardgecko'
          namePlural: 'Leopardgeckos'
          article: 'der'
          articlePlural: 'die'
          textLength: 'Bei Echsen wird die Körperlänge (ohne Schwanz) des längsten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Leopardgeckos. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Leopardgeckos. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Leopardgeckos. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Versteckmöglichkeiten, Grabmöglichkeiten, artgemässe klimatische Bedingungen und Beleuchtung.\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "UV-emittierende Lampen und Grundbeleuchtung; Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere (z.B. Korkröhren, Wurzeln, mind. ein leicht feuchtes Versteck), Klettermöglichkeiten (z.B. Steinaufbauten, Rückwand, Wurzeln), Bodengrund zum Graben, Wasser sowie artgemässes Futter (Insekten)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Winterruhe, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 22-28°C, lokaler Wärmespot 35°C, nachts Abkühlung. Männliche Tiere sind territorial, ihre Vergesellschaftung ist nicht empfehlenswert.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Merkblatt Grosser Leopardgeckos'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_leopardgecko_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        dwarfBeardedDragon:
          name: 'Zwergbartagame'
          namePlural: 'Zwergbartagamen'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Echsen wird die Körperlänge (ohne Schwanz) des längsten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Bartagamen. Männliche Tiere sind untereinander meist unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Bartagamen. Männliche Tiere sind untereinander unverträglich. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Bartagamen. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Kletter- und Versteckmöglichkeiten, artgemässe klimatische Bedingungen und Sonnenplatz sowie eine UV-emittierende Lichtquelle.\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Erhöhte Liegeflächen, UV-emittierende Lampen und Grundbeleuchtung, die UV-Beleuchtung muss so installiert sein, dass alle Tiere Zugang haben (passende Platzierung und ausreichende Anzahl Sonnenplätze), sich aber nicht daran verbrennen können (kein direkter Kontakt zum Leuchtkörper möglich), Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere, z.B. Korkröhren, Wurzeln, Bademöglichkeit, Klettermöglichkeiten und Felsen, Sand zum Graben, Wasser sowie artgemässes Futter (Frischfutter, Insekten)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Winterruhe, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-30°C, lokaler Wärmespot 45°C, nachts Abkühlung, je nach Herkunftsklima ist eine kühle Überwinterung zu empfehlen. Auch wenn Bartagamen ausserhalb der Paarungszeit Einzelgänger sind, ist die Gesellschaft von Artgenossen eine wichtige Bereicherung der Verhaltensoptionen im Terrarium, sofern die Tiere verträglich sind.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Merkblatt Streifenköpfige Bartagame'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_bartagame_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
        centralBeardedDragon:
          name: 'Streifenköpfige Bartagame'
          namePlural: 'Streifenköpfige Bartagamen'
          article: 'die'
          articlePlural: 'die'
          textLength: 'Bei Echsen wird die Körperlänge (ohne Schwanz) des längsten Tiers gemessen.'
          minAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Bartagamen. Männliche Tiere sind untereinander unverträglich.'
          maxAnimalsWarningMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Bartagamen. Männliche Tiere sind untereinander unverträglich. Eine solch hohe Anzahl Tiere ist nicht zu empfehlen.'
          maxAnimalsErrorMessage: 'Bitte informieren Sie sich über die Sozialstruktur von Bartagamen. Eine solch hohe Anzahl Tiere ist abzulehnen.'
          laws:
            furnishing: "Kletter- und Versteckmöglichkeiten, artgemässe klimatische Bedingungen und Sonnenplatz sowie eine UV-emittierende Lichtquelle.\n"
            socialStructure: "Soziale Struktur beachten. Unter Umständen müssen die Tiere einzeln gehalten werden.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Erhöhte Liegeflächen, UV-emittierende Lampen und Grundbeleuchtung, die UV-Beleuchtung muss so installiert sein, dass alle Tiere Zugang haben (passende Platzierung und ausreichende Anzahl Sonnenplätze), sich aber nicht daran verbrennen können (kein direkter Kontakt zum Leuchtkörper möglich), Messsystem für Temperatur- und Feuchtigkeitskontrolle, mindestens so viele Rückzugsmöglichkeiten wie Tiere, z.B. Korkröhren, Wurzeln, Bademöglichkeit, Klettermöglichkeiten und Felsen, Sand zum Graben, Wasser sowie artgemässes Futter (Frischfutter, Insekten)\n"
            socialStructure: "Die artspezifischen Ansprüche an den Lebensraum, an das Sozialverhalten, an das Klima, an die Winterruhe, an die Einrichtung des Terrariums und an die Fütterung müssen berücksichtigt werden. Grundtemperatur 25-30°C, lokaler Wärmespot 45°C, nachts Abkühlung, je nach Herkunftsklima ist eine kühle Überwinterung zu empfehlen. Auch wenn Bartagamen ausserhalb der Paarungszeit Einzelgänger sind, ist die Gesellschaft von Artgenossen eine wichtige Bereicherung der Verhaltensoptionen im Terrarium, sofern die Tiere verträglich sind.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Merkblatt Streifenköpfige Bartagame'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_bartagame_de.pdf'
            '1':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Reptilien_A4_web.pdf'
            '2':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_de.pdf'
      birds:
        diamondDove:
          name: 'Diamanttäubchen'
          namePlural: 'Diamanttäubchen'
          minAnimalsErrorMessage: 'Diamanttäubchen sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Diamanttäubchen gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein, und in Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Diamanttäubchen sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung mit mehreren Brutpaaren.'
            indoor: 'Diamanttäubchen können in Innenräumen in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Die tiergerechteste Variante der Haltung von Diamanttäubchen stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicher) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: 'Diamanttäubchen sind generell sehr friedlich und können bei entsprechendem Fachwissen mit anderen friedlichen Vogelarten gemeinsam gehalten werden.'
          links:
            '0':
              text: 'Vögel Tiergerecht halten - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Voegel_A4_web.pdf'
        canary:
          name: 'Kanarienvogel'
          namePlural: 'Kanarienvögel'
          minAnimalsErrorMessage: 'Kanarienvögel sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Kanarien gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Kanarienvögel sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Kanarienvögel können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Die tiergerechteste Variante der Kanarienhaltung stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicherem) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: "Eine gemeinsame Haltung mit einer anderen Vogelart ist nur für Personen mit viel Fachwissen empfehlenswert.\nAchtung: Bei Kanarienvögeln gibt es  Zuchtrichtungen (z.B. einige Positurkanarien), welche zu den Qualzuchten gehören, bitte keine solchen Tiere kaufen."
          links:
            '0':
              text: 'STS Merkblatt Kanarienvogel'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_kanarien_de.pdf'
        zebraFinch:
          name: 'Zebrafink'
          namePlural: 'Zebrafinken'
          minAnimalsErrorMessage: 'Zebrafinken sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Zebrafinken gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Zebrafinken sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Zebrafinken können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Die tiergerechteste Variante der Zebrafinkenhaltung stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (mindestens 12°C)  Innenbereich (Bsp. Gartenhäuschen) bestehen.  Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden. Achtung: Im Winterhalbjahr Innenhaltung nötig!'
            specificNeeds: 'Eine gemeinsame Haltung mit einer anderen Vogelart ist nur für Personen mit viel Fachwissen empfehlenswert.'
          links:
            '0':
              text: 'STS Merkblatt Zebrafinken'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_zebrafinken_de.pdf'
        grayWaxbill:
          name: 'Grauastrild'
          namePlural: 'Grauastrilden'
          minAnimalsErrorMessage: 'Grauastrilden sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Grauastrilden gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Grauastrilden sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Grauastrilden können in Innenräumen in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Nur im Sommerhalbjahr möglich, da Gouldamadinen kälteempfindlich sind. Im Sommerhalbjahr Aussengehege, welche aus einer Voliere und einem angrenzenden, beheizbaren (mindestens 18°C) Innenbereich (Bsp. Gartenhäuschen) bestehen.  Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: 'Eine gemeinsame Haltung mit einer anderen Vogelart ist nur für Personen mit viel Fachwissen empfehlenswert.'
          links:
            '0':
              text: 'Vögel Tiergerecht halten - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Voegel_A4_web.pdf'
        goldenBreasted:
          name: 'Goldbrüstchen'
          namePlural: 'Goldbrüstchen'
          minAnimalsErrorMessage: 'Goldbrüstchen sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Goldbrüstchen gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Goldbrüstchen sollten paarweise gehalten werden, da die Männchen während der Brutzeit auf artgleiche Rivalen aggressiv reagieren können.'
            indoor: 'Goldbrüstchen können in Innenräumen in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: "Nur im Sommerhalbjahr möglich, da Goldbrüstchen kälteempfindlich sind. Im Sommerhalbjahr Aussengehege, welche aus einer Voliere und einem angrenzenden, beheizbaren (mindestens 15°C) Innenbereich (Bsp. Gartenhäuschen) bestehen.  Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.\n"
            specificNeeds: "\"langlebig, sehr aktiver Vogel, braucht geräumige Voliere mit Gras und Schilf. \nEine gemeinsame Haltung mit einer anderen Vogelart ist anspruchsvoll und nur für Personen mit viel Fachwissen empfehlenswert.\""
          links:
            '0':
              text: 'Vögel Tiergerecht halten - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Voegel_A4_web.pdf'
        gouldianMadine:
          name: 'Gouldamadine'
          namePlural: 'Gouldamadinen'
          minAnimalsErrorMessage: 'Gouldamadinen sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Gouldamadinen sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Gouldamadinen können in Innenräumen in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Nur im Sommerhalbjahr möglich, da Gouldamadinen kälteempfindlich sind. Im Sommerhalbjahr Aussengehege, welche aus einer Voliere und einem angrenzenden, beheizbaren (mindestens 18°C) Innenbereich (Bsp. Gartenhäuschen) bestehen.  Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden. Im Winterhalbjahr brauchen Gouldamadinen zwingend Innenhaltung (siehe oben).'
            specificNeeds: "Viel Deckung und genügend Versteckmöglichkeiten sind für die scheuen Vögel elementar. \n\nEine gemeinsame Haltung mit einer anderen Vogelart ist anspruchsvoll und nur für Personen mit viel Fachwissen empfehlenswert.\nAuf den Kauf von Farbzuchtmorphen sollte verzichtet werden."
          links:
            '0':
              text: 'STS Merkblatt Gouldamadinen'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Gouldamadinen_de.pdf'
        budgerigar:
          name: 'Wellensittich'
          namePlural: 'Wellensittiche'
          minAnimalsErrorMessage: 'Wellensittiche sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Wellensittiche sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren (mindestens 2, besser 3) Brutpaaren. Dafür ist eine grosse Voliere nötig, da Männchen während der Brutzeit den Nistplatz verteidigen.'
            indoor: 'Wellensittiche können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: "Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicher) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.\n"
            specificNeeds: "\"Wellensittiche brauchen vielfältige Klettermöglichkeiten in Form von verschiedenen, natürlichen Ästen (Hasel, Weide, Buche, ungespritzte Obstbäume). Diese dienen gleichzeitig als Nagematerial und sollten regelmässig erneuert werden.\n\nEine Vergesellschaftung mit anderen Arten ist möglich, jedoch nur für fachkundige Vogelhalter empfehlenswert. Extreme Zuchtformen (Bsp. Vögel mit Federhauben) sollen nicht erworben werden. \""
          links:
            '0':
              text: 'STS Merkblatt Wellensittiche'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_wellensittiche_de.pdf'
        agapornid:
          name: 'Agapornid (Unzertrennliche)'
          namePlural: 'Agaporniden (Unzertrennliche)'
          minAnimalsErrorMessage: 'Agaporniden sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme. Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Agaporniden sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Agaporniden können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: "Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicherem) Innenbereich (Bsp. Gartenhäuschen) bestehen.  Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.\n\n"
            specificNeeds: "\"Für die Heimtierhaltung kommen in erster Linie Pfirsichköpfchen, Rosenköpfchen, Schwarzköpfchen und Russköpfchen in Frage. Die anderen Agapornidenarten sind anspruchsvoller und daher für Anfänger nicht empfehlenswert.\nEinige Agapornidenarten können ziemlich aggressiv sein und sind nicht für die gemeinsame Haltung mit anderen Arten geeignet.\n\nAgaporniden können ziemlich laut sein. Daher muss auch die Nachbarschaft mit der Haltung einverstanden sein.\""
          links:
            '0':
              text: 'STS Merkblatt Agaporniden'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Agaporniden_de.pdf'
        tarantaParrot:
          name: 'Tarantapapagei (Bergpapagei)'
          namePlural: 'Tarantapapageien (Bergpapageien)'
          minAnimalsErrorMessage: 'Tarantapapageien sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Tarantapapageien sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Tarantapapageien können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: "Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicherem) Innenbereich (Bsp. Gartenhäuschen) bestehen.  Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.\n\n"
            specificNeeds: "Tarantapapageien stellen die grösste Art der Agaporniden (Unzertrennliche) dar. \nDie Haltung von dieser Agaporniden-Art ist anspruchsvoll und nicht für Anfänger geeignet.\nEine Vergesellschaftung mit anderen Arten ist möglich, jedoch nur für fachkundige Vogelhalter empfehlenswert. \nTarantapapageien können zudem ziemlich laut sein. Daher muss auch die Nachbarschaft mit der Haltung einverstanden sein."
          links:
            '0':
              text: 'STS Merkblatt Agaporniden'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Agaporniden_de.pdf'
        cockatiel:
          name: 'Nymphensittich'
          namePlural: 'Nymphensittiche'
          minAnimalsErrorMessage: 'Nymphensittiche sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Nymphensittiche sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Nymphensittiche können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicherem) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: "Nymphensittiche brauchen vielfältige Klettermöglichkeiten in Form von verschiedenen, natürlichen Ästen (Hasel, Weide, Buche, ungespritzte Obstbäume). Diese dienen gleichzeitig als Nagematerial und sollten regelmässig erneuert werden.\n\nEine Vergesellschaftung mit anderen Arten ist möglich, jedoch nur für fachkundige Vogelhalter empfehlenswert."
          links:
            '0':
              text: 'STS Merkblatt Nymphensittiche'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Nymphensittiche_de.pdf'
        beautifulParakeet:
          name: 'Schönsittich'
          namePlural: 'Schönsittiche'
          minAnimalsErrorMessage: 'Schönsittiche sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung eines Brutpaares ist am tiergerechtesten. Es muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, denn Schönsittiche können sowohl auf Artgenossen als auch auf andere Vogelarten ziemlich aggressiv  reagieren.'
          maxAnimalsErrorMessage: 'Achtung! Die Haltung von Schönsittichen ist aspruchsvoll: Schönsittiche sollten als Paar gehalten werden, da sie aggressiv auf Artgenossen und andere Vogelarten reagieren können.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein, und in Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Schönsittiche sind sehr gesellig, können aber sehr aggressiv gegenüber anderen Vögeln, auch Artgenossen, sein. Für Schönsittiche ist daher eine Paarhaltung zu empfehlen.'
            outdoor: 'Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicher) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: "\"Schönsittiche benötigen eine möglichst grosse Freiflugfläche, da ihr Flug geradlinig ist. Sie benötigen zudem ausreichend Beschäftigung und Nagemöglichkeiten in Form von verschiedenen ungiftigen, frischen Ästen.\nVon einer Vergesellschaftung mit anderen Vogelarten ist abzuraten , da sie aggressiv reagieren können. \""
          links:
            '0':
              text: 'Vögel Tiergerecht halten - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Voegel_A4_web.pdf'
        rosellaConure:
          name: 'Rosellasittich'
          namePlural: 'Rosellasittiche'
          minAnimalsErrorMessage: 'Rosellasittiche sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 20 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Rosellasittiche sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren. Bei der Haltung von mehreren Paaren muss die Voliere sehr gross sein, da die Männchen während der der Balz- und Brutzeit sehr aggressiv sind.'
            indoor: 'Rosellasittiche können in grosszügigen Volieren oder in Vogelzimmern gehalten werden. Herkömmliche Vogelkäfige sind viel zu klein für eine artgerechte Haltung. Hohe und schmale Volieren sind ungeeignet, weil Vögel zum Fliegen horizontalen Raum benötigen. Glasvitrinen eignen sich ebenfalls nicht.'
            outdoor: 'Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicheren) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: "\"Rosellasittiche benötigen eine möglichst grosse Freiflugfläche, da sie sehr lebhaft sind. Sie benötigen zudem ausreichend Beschäftigung und Nagemöglichkeiten in Form von verschiedenen ungiftigen, frischen Ästen.\nEine Vergesellschaftung mit anderen Arten ist möglich, jedoch nur für fachkundige Vogelhalter empfehlenswert. \""
          links:
            '0':
              text: 'Vögel Tiergerecht halten - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Voegel_A4_web.pdf'
        cockatoo:
          name: 'Kakadu'
          namePlural: 'Kakadus'
          minAnimalsErrorMessage: 'Kakadus sind soziale Tiere. Die Einzelhaltung ist deshalb verboten! Die Haltung von Kakadus (ausser Nymphensittich) ist äusserst anspruchsvoll und bewilligungspflichtig. Wenn Sie beabsichtigen Kakakdus zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          maxAnimalsWarningMessage: 'Die Haltung von Kakadus (ausser Nymphensittich) ist äusserst anspruchsvoll und bewilligungspflichtig. Wenn Sie beabsichtigen Kakakdus zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          maxAnimalsErrorMessage: 'Die Haltung von Kakadus (ausser Nymphensittich) ist äusserst anspruchsvoll und bewilligungspflichtig. Wenn Sie beabsichtigen Kakakdus zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          laws: { }
          recommendations:
            general: 'Aus Tierschutzgründen rät der Schweizer Tierschutz STS von der privaten Ara-/Kakadu-Haltung ab. Der Platzbedarf für eine tiergerechte Ara-/Kakadu-Haltung ist immens und kann von privaten Haltern meistens nicht erfüllt werden. Die Haltung von Kakadus oder Aras ist äusserst anspruchsvoll und bewilligungspflichtig. Vor dem Kauf eines Kakadus oder Aras muss ein Sachkundekurs (SKN) absolviert werden. Zudem muss ein Gesuch an das kantonale Veterinäramt des Wohnsitzkantons gestellt werden und gezeigt werden, dass alle gesetzlich vorgeschriebenen Anforderungen in Bezug auf Haltung, Pflege und Erwerb erfüllt werden können. Wenn Sie beabsichtigen  Kakadus oder Aras zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          links:
            '0':
              text: 'Fachinformation Tierschutz - Bewilligungspflicht für die Haltung von Grosspapageien - Bundesamt für Lebensmittelsicherheit und Veterinärwesen BLV'
              file: 'https://www.blv.admin.ch/dam/blv/de/dokumente/tiere/heim-und-wildtierhaltung/fi-bew-pflicht-grosspapageien.pdf.download.pdf/D_3.1_Fachinformation_Bewilligungspflicht_f%C3%BCr_Grosspapageien.pdf'
        macaw:
          name: 'Ara'
          namePlural: 'Aras'
          minAnimalsErrorMessage: "Aras sind soziale Tiere. Die Einzelhaltung ist deshalb verboten! \nDie Haltung von Grosspapageien wie Aras ist äusserst anspruchsvoll und bewilligungspflichtig. Wenn Sie beabsichtigen Aras zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons."
          maxAnimalsWarningMessage: 'Die Haltung von Grosspapageien wie Aras ist äusserst anspruchsvoll und bewilligungspflichtig. Wenn Sie beabsichtigen Aras zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          maxAnimalsErrorMessage: 'Die Haltung von Grosspapageien wie Aras ist äusserst anspruchsvoll und bewilligungspflichtig. Wenn Sie beabsichtigen Aras zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          laws: { }
          recommendations:
            general: 'Aus Tierschutzgründen rät der Schweizer Tierschutz STS von der privaten Ara-/Kakadu-Haltung ab. Der Platzbedarf für eine tiergerechte Ara-/Kakadu-Haltung ist immens und kann von privaten Haltern meistens nicht erfüllt werden. Die Haltung von Kakadus oder Aras ist äusserst anspruchsvoll und bewilligungspflichtig. Vor dem Kauf eines Kakadus oder Aras muss ein Sachkundekurs (SKN) absolviert werden. Zudem muss ein Gesuch an das kantonale Veterinäramt des Wohnsitzkantons gestellt werden und gezeigt werden, dass alle gesetzlich vorgeschriebenen Anforderungen in Bezug auf Haltung, Pflege und Erwerb erfüllt werden können. Wenn Sie beabsichtigen  Kakadus oder Aras zu halten, wenden Sie sich für weitere Informationen an das kantonale Veterinäramt Ihres Wohnkantons.'
          links:
            '0':
              text: 'Fachinformation Tierschutz - Bewilligungspflicht für die Haltung von Grosspapageien - Bundesamt für Lebensmittelsicherheit und Veterinärwesen BLV'
              file: 'https://www.blv.admin.ch/dam/blv/de/dokumente/tiere/heim-und-wildtierhaltung/fi-bew-pflicht-grosspapageien.pdf.download.pdf/D_3.1_Fachinformation_Bewilligungspflicht_f%C3%BCr_Grosspapageien.pdf'
        congoGreyParrot:
          name: 'Kongo-Graupapagei'
          namePlural: 'Kongo-Graupapageien'
          minAnimalsErrorMessage: 'Graupapageien sind soziale Tiere. Die Einzelhaltung ist daher verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 6 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Graupapageien sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Eine reine Innenhaltung ist für Graupapageien nicht tiergerecht. '
            outdoor: 'Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostsicher) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: "Eine tiergerechte Haltung von Graupapgageien ist höchst anspruchsvoll. \nDer Handel mit diesen äusserst langlebigen Tieren boomt und es gibt sehr viele Angebote von skrupellosen Anbieteren (z.B. Handaufzuchten, Importe aus unklarer Quelle). Graupapageien können laut sein, daher muss die Nachbarschaft mit der Haltung ebenfalls einverstanden sein. \n\nAus Tierschutzgründen sollten Sie auf den Kauf von Tieren aus Handaufzuchten verzichten."
          links:
            '0':
              text: 'STS Merkblatt Graupapageien'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_graupapageien_de.pdf'
        amazon:
          name: 'Amazone'
          namePlural: 'Amazonen'
          minAnimalsErrorMessage: 'Amazonen sind soziale Tiere. Die Einzelhaltung ist deshalb verboten!'
          maxAnimalsWarningMessage: 'Die Haltung mehrerer Brutpaare gemeinsam ist am tiergerechtesten. Allerdings muss unbedingt auf die individuellen Verträglichkeiten der Tiere geachtet werden, insbesondere während der Balz- und Brutzeit. Für die Haltung von mehr als einem Brutpaar muss  eine grosszügige Voliere mit vielen Rückzugs- und Ausweichmöglichkeiten gewählt werden.'
          maxAnimalsErrorMessage: 'Bitte gib eine Zahl von maximal 6 oder weniger an.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein, und in Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Einzelhaltung verboten'
            lawDetails: 'Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestmassen nur um das absolute Minimum handelt. Vögel in kleineren Käfigen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. Für eine tiergerechte Vogelhaltung braucht es um ein Vielfaches grössere Volieren und eine gut strukturierte Einrichtung (siehe Empfehlungen).'
          recommendations:
            furnishing: 'Nur einem Ende befestigte Äste (=federnde Sitzgelegenheiten), verschiedene Seile, Schaukeln, von der Decke hängende Plattformen, Vogelbad (Badehäuschen oder flache Schale), aufgehängte Kalksteine/Sepiaschalen, Magengrit in flacher Schale, geeignete UV-Lampen bei Haltungen in Innenräumen, als Bodengrund Sand, Kieselsteine oder Buchenholzgranulat. Die Gitterstäbe des Geheges müssen horziontal ausgerichtet sein. Rundkäfige sind tierschutzwidrig und abzulehnen.'
            socialStructure: 'Amazonen sind sehr gesellig. Am artgerechtesten ist eine Gruppenhaltung von mehreren Brutpaaren.'
            indoor: 'Eine reine Innenhaltung ist für Amazonen nicht tiergerecht. '
            outdoor: 'Die tiergerechteste Haltungsform stellen Aussengehege dar, welche aus einer Voliere und einem angrenzenden, beheizbaren (frostfrei) Innenbereich (Bsp. Gartenhäuschen) bestehen. Die Voliere muss oben teilweise gedeckt sein (Witterungsschutz), und soll auf mindestens zwei Seiten mit einem Sichtschutz (Bepflanzung, blickdichtes Windschutznetz o.ä.) versehen werden.'
            specificNeeds: "Amazonen sind laute Vögel, daher muss die Nachbarschaft mit der Haltung ebenfalls einverstanden sein. \n\nEs existieren verschiedene Amazonenarten, und nicht jede ist als Heimtier geeignet. Am häufigsten ist die Blaustirnamazone. Jede Art hat ihre eigenen, artspezifischen Bedürfnisse in Bezug auf Haltung, Klima, Fütterung, und Sozialstruktur. Bitte informieren Sie sich bei ausgewiesenen Fachpersonen oder absolvieren Sie einen Sachkundekurs vor dem Kauf.\nAus Tierschutzgründen sollten Sie auf den Kauf von Tieren aus Handaufzuchten verzichten."
          links:
            '0':
              text: 'Vögel Tiergerecht halten - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_d_Voegel_A4_web.pdf'
      equidae:
        horse:
          name: 'Pferd'
          namePlural: 'Pferde'
          minAnimalsWarningMessage: 'Achtung: Equiden müssen mindestens Sicht-, Hör- und Geruchkontakt zu anderen Equiden haben. Optimalerweise mit einem Artgenossen, also einem anderen Pferd.'
          maxAnimalsWarningMessage: 'Bei fünf und mehr gut verträglichen Equiden kann die Gesamtliegefläche um maximal 20 Prozent verkleinert werden, dies wurde bei den folgenden Resultaten berücksichtigt. Der STS empfiehlt diese Reduktion nicht.'
          recommendations:
            pasturing: 'Wenn immer möglich'
            socialStructure: 'Pferde sollten direkten Kontakt zu anderen Pferden haben. Pferde und Esel sprechen eine andere Sprache und haben unterschiedliche Anforderungen an ihre Haltung und Fütterung.'
            activity: 'Alle Equiden sollten täglich beschäftigt werden.'
          laws:
            runoutTime: 'Genutzte Pferde 2 x 2 h / Woche; Ungenutzte Pferde täglich 2h'
            pasturing: 'nicht vorgeschrieben'
            structuring: 'Werden Equiden in Gruppen gehalten, so müssen Ausweich- und Rückzugsmöglichkeiten vorhanden sein; keine Ausweich- und Rückzugsmöglichkeiten sind erforderlich für abgesetzte Fohlen sowie Jungtiere bis zum Beginn der regelmässigen Nutzung, längstens jedoch bis zum Alter von 30 Monaten. Es dürfen keine Sackgassen vorhanden sein. Equiden dürfen nicht angebunden gehalten werden.'
            exercise: 'Equiden ist täglich ausreichend Bewegung zu gewähren. Zur Bewegung zählen die Nutzung und der Auslauf. Bei extremen Witterungs- und Bodenverhältnissen kann der Auslauf ausnahmsweise auf einer überdachten Fläche gewährt werden.'
            activity: 'Equiden muss zur arttypischen Beschäftigung ausreichend Raufutter wie Futterstroh zur Verfügung stehen, ausgenommen während des Weidegangs.'
            socialStructure: 'Equiden müssen Sicht-, Hör- und Geruchkontakt zu einem anderen Equide haben. Equiden müssen nach dem Absetzen vom Muttertier bis zum Alter von 30 Monaten oder bis zum Beginn der regelmässigen Nutzung in Gruppen gehalten werden.'
          links:
            '0':
              text: 'STS Webseite Pferdehaltung'
              file: 'https://www.tierschutz.com/pferde'
            '1':
              text: 'Tierschutzverordnung'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/de'
        pony:
          name: 'Pony'
          namePlural: 'Ponys'
          minAnimalsWarningMessage: 'Achtung: Equiden müssen mindestens Sicht-, Hör- und Geruchkontakt zu anderen Equiden haben. Optimalerweise mit einem Artgenossen, also mit einem anderen Pony oder Pferd.'
          maxAnimalsWarningMessage: 'Bei fünf und mehr gut verträglichen Equiden kann die Gesamtliegefläche um maximal 20 Prozent verkleinert werden, dies wurde bei den folgenden Resultaten berücksichtigt. Der STS empfiehlt diese Reduktion nicht.'
          recommendations:
            pasturing: 'Wenn immer möglich, jedoch dem Ernährungszustand anpassen!'
            socialStructure: 'Ponys sollten direkten Kontakt zu anderen Ponys oder Pferden haben. '
            activity: 'Alle Equiden sollten täglich beschäftigt werden.'
            specialRequirements: 'Bei jungem, eiweiß- bzw. fruktanreichem Gras besteht insbesondere bei Ponys eine gesteigerte Gefahr an Verfettung, vor allem wenn die Ponys nicht ausreichend bewegt werden.'
          laws:
            runoutTime: 'Genutzte Ponys 2 x 2 h / Woche; Ungenutzte Ponys täglich 2h'
            pasturing: 'nicht vorgeschrieben'
            structuring: 'Werden Equiden in Gruppen gehalten, so müssen Ausweich- und Rückzugsmöglichkeiten vorhanden sein; keine Ausweich- und Rückzugsmöglichkeiten sind erforderlich für abgesetzte Fohlen sowie Jungtiere bis zum Beginn der regelmässigen Nutzung, längstens jedoch bis zum Alter von 30 Monaten. Es dürfen keine Sackgassen vorhanden sein. Equiden dürfen nicht angebunden gehalten werden.'
            exercise: 'Equiden ist täglich ausreichend Bewegung zu gewähren. Zur Bewegung zählen die Nutzung und der Auslauf. Bei extremen Witterungs- und Bodenverhältnissen kann der Auslauf ausnahmsweise auf einer überdachten Fläche gewährt werden.'
            activity: 'Equiden muss zur arttypischen Beschäftigung ausreichend Raufutter wie Futterstroh zur Verfügung stehen, ausgenommen während des Weidegangs.'
            socialStructure: 'Equiden müssen Sicht-, Hör- und Geruchkontakt zu einem anderen Equide haben. Equiden müssen nach dem Absetzen vom Muttertier bis zum Alter von 30 Monaten oder bis zum Beginn der regelmässigen Nutzung in Gruppen gehalten werden.'
          links:
            '0':
              text: 'STS Webseite Pferdehaltung'
              file: 'https://www.tierschutz.com/pferde'
            '1':
              text: 'Tierschutzverordnung'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/de'
        donkey:
          name: 'Esel'
          namePlural: 'Esel'
          minAnimalsWarningMessage: 'Achtung: Equiden müssen mindestens Sicht-, Hör- und Geruchkontakt zu anderen Equiden haben. Optimalerweise mit einem Artgenossen, also in diesem Fall mit einem anderen Esel.  '
          maxAnimalsWarningMessage: 'Bei fünf und mehr gut verträglichen Equiden kann die Gesamtliegefläche um maximal 20 Prozent verkleinert werden, dies wurde bei den folgenden Resultaten berücksichtigt. Der STS empfiehlt diese Reduktion nicht.'
          recommendations:
            pasturing: 'Begrenzter Weidegang für Esel, mehr Bewegung auf grossen Trockenplätzen'
            socialStructure: 'Esel brauchen den direkten Kontakt zu Artgenossen, also anderen Eseln. Sie unterscheiden sich in ihrem Verhalten und ihrer Physiologie von den Pferden. Zusätzliche Futter- und Beschäftigungsangebote mit hohem Faseranteil wie Äste und Stroh sind vorzusehen.'
            activity: 'Alle Equiden sollten täglich beschäftigt werden.'
            specialRequirements: 'Esel sind sehr effiziente Futterverwerter. Sie brauchen energiearme Nahrung mit niedrigem Zucker- und Proteingehalt, aber hohem Rohfaseranteil. Das Fell der Esel ist kaum wasserabweisend. Bei Nässe sind Esel krankheitsanfällig. Sie brauchen stets einen trockenen Unterstand. Esel benötigen trockenen Untergrund, sonst drohen Hufprobleme.'
          laws:
            runoutTime: 'Genutzte Esel 2 x 2 h / Woche; Ungenutzte Esel täglich 2h'
            pasturing: 'nicht vorgeschrieben'
            structuring: 'Werden Equiden in Gruppen gehalten, so müssen Ausweich- und Rückzugsmöglichkeiten vorhanden sein; keine Ausweich- und Rückzugsmöglichkeiten sind erforderlich für abgesetzte Fohlen sowie Jungtiere bis zum Beginn der regelmässigen Nutzung, längstens jedoch bis zum Alter von 30 Monaten. Es dürfen keine Sackgassen vorhanden sein. Equiden dürfen nicht angebunden gehalten werden.'
            exercise: 'Equiden ist täglich ausreichend Bewegung zu gewähren. Zur Bewegung zählen die Nutzung und der Auslauf. Bei extremen Witterungs- und Bodenverhältnissen kann der Auslauf ausnahmsweise auf einer überdachten Fläche gewährt werden.'
            activity: 'Equiden muss zur arttypischen Beschäftigung ausreichend Raufutter wie Futterstroh zur Verfügung stehen, ausgenommen während des Weidegangs.'
            socialStructure: 'Equiden müssen Sicht-, Hör- und Geruchkontakt zu einem anderen Equide haben. Equiden müssen nach dem Absetzen vom Muttertier bis zum Alter von 30 Monaten oder bis zum Beginn der regelmässigen Nutzung in Gruppen gehalten werden.'
          links:
            '0':
              text: 'STS Webseite Esel'
              file: 'https://www.tierschutz.com/esel'
            '1':
              text: 'Tierschutzverordnung'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/de'
        mule:
          name: 'Maultier'
          namePlural: 'Maultiere'
          minAnimalsWarningMessage: 'Achtung: Equiden müssen mindestens Sicht-, Hör- und Geruchkontaktt zu anderen Equiden haben. Optimalerweise mit einem anderen Maultier'
          maxAnimalsWarningMessage: 'Bei fünf und mehr gut verträglichen Equiden kann die Gesamtliegefläche um maximal 20 Prozent verkleinert werden, dies wurde bei den folgenden Resultaten berücksichtigt. Der STS empfiehlt diese Reduktion nicht.'
          recommendations:
            pasturing: 'Begrenzter Weidegang'
            socialStructure: 'Maultiere bevorzugen den Kontakt zu anderen Maultieren. Selbstverständlich können Maultiere mit ihrer Mutterstute gehalten werden. Dabei ist aber ihre Physiologie in Bezug auf die Fütterung und Weidegang zu beachten. '
            activity: 'Alle Equiden sollten täglich beschäftigt werden.'
          laws:
            runoutTime: 'Genutzte Maultiere 2 x 2 h / Woche; Ungenutzte Maultiere täglich 2h'
            pasturing: 'nicht vorgeschrieben'
            structuring: 'Werden Equiden in Gruppen gehalten, so müssen Ausweich- und Rückzugsmöglichkeiten vorhanden sein; keine Ausweich- und Rückzugsmöglichkeiten sind erforderlich für abgesetzte Fohlen sowie Jungtiere bis zum Beginn der regelmässigen Nutzung, längstens jedoch bis zum Alter von 30 Monaten. Es dürfen keine Sackgassen vorhanden sein. Equiden dürfen nicht angebunden gehalten werden.'
            exercise: 'Equiden ist täglich ausreichend Bewegung zu gewähren. Zur Bewegung zählen die Nutzung und der Auslauf. Bei extremen Witterungs- und Bodenverhältnissen kann der Auslauf ausnahmsweise auf einer überdachten Fläche gewährt werden.'
            activity: 'Equiden muss zur arttypischen Beschäftigung ausreichend Raufutter wie Futterstroh zur Verfügung stehen, ausgenommen während des Weidegangs.'
            socialStructure: 'Equiden müssen Sicht-, Hör- und Geruchkontakt zu einem anderen Equide haben. Equiden müssen nach dem Absetzen vom Muttertier bis zum Alter von 30 Monaten oder bis zum Beginn der regelmässigen Nutzung in Gruppen gehalten werden.'
          links:
            '0':
              text: 'STS Webseite Pferdehaltung'
              file: 'https://www.tierschutz.com/pferde'
            '1':
              text: 'STS Webseite Esel'
              file: 'https://www.tierschutz.com/esel'
            '2':
              text: 'Tierschutzverordnung'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/de'
        hinny:
          name: 'Maulesel'
          namePlural: 'Maulesel'
          minAnimalsWarningMessage: 'Achtung: Equiden müssen mindestens Sicht-, Hör- und Geruchkontakt zu anderen Equiden haben. Optimalerweise mit einem anderen Maulesel.'
          maxAnimalsWarningMessage: 'Bei fünf und mehr gut verträglichen Equiden kann die Gesamtliegefläche um maximal 20 Prozent verkleinert werden, dies wurde bei den folgenden Resultaten berücksichtigt. Der STS empfiehlt diese Reduktion nicht.'
          recommendations:
            pasturing: 'Begrenzter Weidegang'
            socialStructure: 'Maultesel bevorzugen den direkten Kontakt zu Mauleseln. Sie können aber auch mit Esel und/oder Pferden gehalten werden. Dabei ist aber ihre Physiologie in Bezug auf die Fütterung und Weidegang zu beachten.'
            activity: 'Alle Equiden sollten täglich beschäftigt werden.'
          laws:
            runoutTime: 'Genutzte Maulesel 2 x 2 h / Woche; Ungenutzte Maulesel täglich 2h'
            pasturing: 'nicht vorgeschrieben'
            structuring: 'Werden Equiden in Gruppen gehalten, so müssen Ausweich- und Rückzugsmöglichkeiten vorhanden sein; keine Ausweich- und Rückzugsmöglichkeiten sind erforderlich für abgesetzte Fohlen sowie Jungtiere bis zum Beginn der regelmässigen Nutzung, längstens jedoch bis zum Alter von 30 Monaten. Es dürfen keine Sackgassen vorhanden sein. Equiden dürfen nicht angebunden gehalten werden.'
            exercise: 'Equiden ist täglich ausreichend Bewegung zu gewähren. Zur Bewegung zählen die Nutzung und der Auslauf. Bei extremen Witterungs- und Bodenverhältnissen kann der Auslauf ausnahmsweise auf einer überdachten Fläche gewährt werden.'
            activity: 'Equiden muss zur arttypischen Beschäftigung ausreichend Raufutter wie Futterstroh zur Verfügung stehen, ausgenommen während des Weidegangs.'
            socialStructure: 'Equiden müssen Sicht-, Hör- und Geruchkontakt zu einem anderen Equide haben. Equiden müssen nach dem Absetzen vom Muttertier bis zum Alter von 30 Monaten oder bis zum Beginn der regelmässigen Nutzung in Gruppen gehalten werden.'
          links:
            '0':
              text: 'STS Webseite Pferdehaltung'
              file: 'https://www.tierschutz.com/pferde'
            '1':
              text: 'STS Webseite Esel'
              file: 'https://www.tierschutz.com/esel'
            '2':
              text: 'Tierschutzverordnung'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/de'
    screenCategory:
      title: 'Kategorie auswählen'
    screenAnimal:
      title: 'Welches Tier möchtest du halten?'
      multipleBirdsButton: 'Mehrere Arten zusammen halten'
      equidaeInfo: "Die berechneten Werte gelten nur für Equiden über 30 Monaten."
    screenMultipleBirds:
      warning: 'Um zwei oder mehr Vogelarten zusammen zu halten, muss die Gehegegrösse für alle Arten separat berechnet werden, aber mit der Gesamtzahl Vögel. Das grösste berechnete Gehege entspricht den gesetzlichen Mindestmassen.'
      infoText: 'Werden in einem Gehege zwei oder mehr Vogelarten gemeinsam gehalten, so entspricht die gesetzliche Gehegemindestgrösse immer den Platz-Anforderungen an die grösste Vogelart. Werden zum Beispiel zwei Wellensittiche und zwei Nymphensittiche in einem Gehege zusammen gehalten, entspricht die minimale Gehegegrösse den Anforderungen für vier Nymphensittiche, da dies die grösste Vogelart im Gehege ist. Die gemeinsame Haltung von mehreren Vogelarten ist nur für erfahrene Vogelhalter geeignet. Informieren Sie sich stets sorgfältig, welche Vogelarten gemeinsam gehalten werden können.'
    screenNumber:
      title: 'Wie viele {animals} möchtest du halten?'
    screenWeight:
      title: 'Wie schwer sind die {animals}?'
    screenLength:
      title: 'Wie lange ist {article} grösste {animal}?'
      error: 'Bitte eine Zahl zwischen {min} und {max} eingeben.'
    screenWithersHeight:
      title: 'Wie hoch ist die Widerrist-Höhe der einzelnen Tiere?'
      error: 'Bitte eine Zahl zwischen {min} und {max} eingeben.'
      animal: 'Tier'
    screenResults:
      title: 'So solltest du {number} {animals} halten'
      example: 'Beispiel'
      exampleEquidae: 'Beispiel für Boxengrösse'
      stsRecommendation: 'Tierschutz-Empfehlung'
      law: 'Gesetz'
      stsAreaRecommendation: 'Mindestfläche (Innenmasse)'
      stsLengthRecommendation:
        upto2reptiles: 'Mindestlänge (Innenmasse)'
        morethan2reptiles: 'Zusätzlich mindestens in der Länge'
      stsWidthRecommendation:
        upto2reptiles: 'Mindestbreite (Innenmasse)'
        morethan2reptiles: 'Zusätzlich mindestens in der Breite'
      stsHeightRecommendation: 'Mindesthöhe Gehege (Innenmasse)'
      stsBeddingRecommendation: 'Mindesteinstreutiefe'
      stsWaterBasinRecommendation: 'Wasserbecken'
      tolerance: 'Toleranzwert'
      toleranceInfo: 'Am 1. September 2008 bestehende Stallungen, die die Toleranzwerte erfüllen, müssen nicht angepasst werden. Muss ein Stall wegen Unterschreiten eines Toleranzwertes angepasst werden, so bleibt der Anspruch auf den anderen Toleranzwert erhalten.'
      noTolerance: 'kein Toleranzwert'
      stsLayingAreaRecommendation: 'Liegefläche'
      stsClearWidthRecommendation: 'Lichte Breite'
      stsClearWidthRecommendationInfo: '2 x Widerrist des grössten Tieres'
      stsClearHeightRecommendation: 'Lichte Höhe'
      stsRunoutTimeRecommendation: 'Auslaufzeit'
      stsRunoutTimeRecommendationText: 'Am besten 24 h freier Zugang zum Allwetterauslauf aber mindestens 4 Stunden täglich'
      stsRunoutAreaRecommendation: 'Auslauffläche'
      stsPasturingRecommendation: 'Weidegang'
      stsLatticeSpacing: 'Maximaler Gitterabstand'
      lawMinimalArea: 'Mindestfläche (Innenmasse)'
      lawMinimalLength: 'Mindestlänge (Innenmasse)'
      lawMinimalWidth: 'Mindestbreite (Innenmasse)'
      lawMinimalHeight: 'Mindesthöhe Gehege (Innenmasse)'
      lawMinimalBedding: 'Mindesteinstreutiefe'
      lawWaterBasin: 'Wasserbecken'
      lawReptilesInfo:
        text: 'Bei der Haltung der eingegebenen Anzahl Tiere muss sichergestellt werden, dass für die jeweilige Tierart sämtliche gesetzlichen Anforderungen {link} erfüllt sind.'
        linkText: 'gemäss Anhang 2, Tabelle 5 TSchV'
        linkURL: 'https://www.fedlex.admin.ch/eli/cc/2008/416/de'
      lawVolume: 'Volumen'
      lawLayingArea: 'Liegefläche Einraumlaufstall'
      layingAreaInfo: 'Liegeflächen räumlich nicht vom Fressbereich getrennt, weitere Bedingungen siehe Tierschutzverordnung Tabelle 7.'
      lawLayingAreaMultiroom: 'Liegefläche Mehrraumlaufstall'
      layingAreaMultiroomInfo: 'Liegefläche sind räumlich, z. B. durch eine Wand oder andere Raumteiler, vom Fress- und Bewegungsbereich getrennt. Weitere Bedingungen siehe Tierschutzverordnung Tabelle 7.'
      lawClearWidth: 'Lichte Breite'
      lawClearWidthInfo: '1.5 x Widerrist des grössten Tieres'
      lawClearHeight: 'Lichte Höhe'
      lawRunoutTime: 'Auslaufzeit'
      lawRunoutAreaAdjacent: 'Auslauffläche angrenzend'
      lawRunoutAreaNonadjacent: 'Auslauffläche nicht angrenzend'
      lawPasturing: 'Weidegang'
      additionalRecommendations:
        title: 'Weitere Empfehlungen'
        furnishing: 'Einrichtung'
        socialStructure: 'Sozialstruktur'
        socialSturctureAndSpecificNeeds: 'Sozialstruktur und artspezifische Bedürfnisse'
        indoorOutdoor: 'Aussen- oder Innenhaltung'
        indoor: 'Innenhaltung'
        outdoor: 'Aussenhaltung'
        specificNeeds: 'Besondere artspezifische Bedürfnisse'
        specialRequirements: 'Spezielle Anforderungen'
        more: 'Weiteres'
        activity: 'Bewegung'
      additionalLaws:
        title: 'Weitere gesetzliche Vorschriften'
        furnishing: 'Einrichtung'
        raisedAreas: 'Erhöhte Flächen'
        socialStructure: 'Sozialstruktur'
        structuring: 'Strukturierung'
        activity: 'Bewegung'
        exercise: 'Beschäftigung'
      lawDetails: "Bitte beachten Sie, dass es sich bei den gesetzlichen Mindestvorschriften nur um das absolute Minimum handelt. Tiere in kleineren Gehegen zu halten ist in der Schweiz verboten und aus Sicht des STS Tierquälerei. \nFür eine tiergerechte Haltung braucht es sehr viel grössere Flächen und eine gut strukturierte Einrichtung (siehe Empfehlungen)."
      furnishing: 'Gesetzlich geforderte Einrichtung'
      additionalInfo: 'Für mehr Informationen'
fr_CH:
  txt:
    title: 'Calculateur enclos animaux'
    buttonNext: 'Suivant'
    buttonPrevious: 'Retour'
    buttonClose: 'Fermer'
    buttonAcceptWarning: 'Compris'
    domain: 'calculateur-enclos-animaux.ch'
    link: 'https://www.calculateur-enclos-animaux.ch/'
    trButton:
      cta: 'Pour en savoir plus sur le bien-être des animaux :'
      buttonText: 'Ouvrir le calculateur des enclos pour animaux'
    categories:
      rodents:
        name: 'Rongeurs & lapins'
      reptiles:
        name: 'Reptiles'
      birds:
        name: 'Oiseaux'
      equidae:
        name: 'Équidés'
    animals:
      rodents:
        degu:
          name: 'Dègue'
          namePlural: 'Dègues'
          minAnimalsErrorMessage: 'Les dègues sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'Taille maximale du groupe dépassée. Les dègues doivent être détenus en groupes de 2 à 5 animaux.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe! Les dègues doivent être détenus en groupes de 2 à 5 animaux.'
          recommendations:
            socialStructure: "Taille de groupe: 2 à 5 animaux\nComposition du groupe: groupe uniquement de femelles, groupe uniquement de mâles, un mâle castré avec plusieurs femelles\n"
          laws:
            furnishing: "Litière adaptée pour creuser, possibilités de retrait pour tous les animaux, fourrage grossier (foin ou paille), objets à ronger (branches fraîches...), bain de sable\n"
            raisedAreas: "Les plateformes surélevées ne doivent pas être comptées dans la surface minimale.\n"
            socialStructure: "Les dègues sont des animaux sociaux et ne doivent pas être détenus sans congénères.\n"
          links:
            '0':
              text: 'Feulle d’information PSA: Dègue du Chili'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_degu_fr.pdf'
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        dwarfHamster:
          name: 'Hamster nain'
          namePlural: 'Hamsters nains'
          minAnimalsWarningMessage: 'Attention, la détention de hamsters nains est très délicate et réservée à des détenteurs de rongeurs expérimentés.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe!'
          recommendations:
            socialStructure: "La détention de hamsters nains est délicate et demande beaucoup d’expérience. Avant d’acheter, renseignez-vous auprès des professionnels pour savoir quelles espèces peuvent être détenues dans quelles tailles et compositions de groupes\n"
          laws:
            furnishing: "Possibilités de grimper, litière adaptée pour creuser, possibilités de retrait pour tous les animaux, matériel de nidification approprié, fourrage grossier (foin, paille, mélanges de graines), objets à ronger (branches fraîches...)\n"
            raisedAreas: "Les plateformes surélevées ne doivent pas être comptées dans la surface minimale.\n"
            socialStructure: "La détention individuelle et en groupe de hamsters nains est autorisée.\n"
          links:
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        fancyMouse:
          name: 'Souris domestique'
          namePlural: 'Souris domestiques'
          minAnimalsErrorMessage: 'Les souris domestiques sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'Taille maximale du groupe dépassée. Les souris domestiques doivent être détenues en groupes de 2 à 6 animaux.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe! Les souris domestiques doivent être détenues en groupes de 2 à 6 animaux.'
          recommendations:
            socialStructure: "Taille de groupe recommandée: 2 à 6 animaux\nComposition du groupe: groupe uniquement de femelles, un mâle castré avec plusieurs femelles\n"
          laws:
            furnishing: "Possibilités de grimper, litière adaptée, possibilités de retrait pour tous les animaux, matériel de nidification approprié, fourrage grossier (foin, paille, mélanges de graines), objets à ronger (branches fraîches...)\n"
            raisedAreas: "Les plateformes surélevées ne doivent pas être comptées dans la surface minimale.\n"
            socialStructure: "Les souris domestiques sont des animaux sociaux et ne doivent pas être détenues sans congénères.\n"
          links:
            '0':
              text: 'Feulle d’information PSA: Souris domestique'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_farbm%C3%A4use_fr.pdf'
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        gerbil:
          name: 'Gerbille'
          namePlural: 'Gerbilles'
          minAnimalsErrorMessage: 'Les gerbilles sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'Taille maximale du groupe dépassée. Les gerbilles doivent être détenues en groupes de 2 animaux.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe! Les gerbilles doivent être détenues en groupes de 2 animaux.'
          recommendations:
            socialStructure: "Taille de groupe recommandée: 2 \nComposition du groupe: groupe uniquement de femelles, groupe uniquement de mâles, un mâle castré avec une femelle\n"
          laws:
            furnishing: "Litière adaptée pour creuser, possibilités de retrait pour tous les animaux, matériel adapté pour faire un nid, fourrage grossier (foin ou paille), objets à ronger (branches fraîches...), bain de sable\n"
            raisedAreas: "Les plateformes surélevées ne doivent pas être comptées dans la surface minimale.\n"
            socialStructure: "Les gerbilles sont des animaux sociaux et ne doivent pas être détenues sans congénères.\n"
          links:
            '0':
              text: 'Feulle d’information PSA: Gerbilles de Mongolie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_rennmaus_fr.pdf'
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        goldenHamster:
          name: 'Hamster doré'
          namePlural: 'Hamsters dorés'
          maxAnimalsErrorMessage: 'Les hamsters dorés sont des animaux solitaires et doivent être détenus seuls.'
          recommendations:
            socialStructure: "Taille de groupe recommandée: 1\n"
          laws:
            furnishing: "Possibilités de grimper, litière adaptée pour creuser, possibilités de retrait pour tous les animaux, matériel de nidification approprié, fourrage grossier (foin, paille, mélanges de graines), objets à ronger (branches fraîches...)\n"
            raisedAreas: "Les plateformes surélevées ne doivent pas être comptées dans la surface minimale.\n"
            socialStructure: "Les hamsters dorés sont des animaux solitaires et doivent être détenus seuls.\n"
            links:
              '0':
                text: 'Feulle d’information PSA: Hamster doré de Syrie'
                file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_goldhamster_fr.pdf'
              '1':
                text: 'Détenir des rongeurs et des Lapins'
                file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
              '2':
                text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
                file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
              '3':
                text: 'Assortiment des animaleries et boutiques en ligne'
                file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
              '4':
                text: 'Check-list animaleries'
                file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        guineaPig:
          name: 'Cochon d’Inde'
          namePlural: 'Cochons d’Inde'
          minAnimalsErrorMessage: 'Les cochons d’Inde sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'Taille maximale du groupe dépassée. Les cochons d’Inde doivent être détenus en groupes de 2 à 6 animaux.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe! Les cochons d’Inde doivent être détenus en groupes de 2 à 6 animaux.'
          recommendations:
            socialStructure: "Taille de groupe recommandée: 2 à 6 animaux\nComposition du groupe: groupe uniquement de femelles ou un mâle castré avec plusieurs femelles, parfois aussi en groupes uniquement de mâles\n"
          laws:
            furnishing: "Litière adaptée, possibilités de retrait pour tous les animaux, objets à ronger (branches fraîches...), fourrage grossier (foin ou paille), aliments contenant de la vitamine C\n"
            raisedAreas: "Les plateformes surélevées praticables peuvent être comptées jusqu’à un tiers de la surface minimale requise, à condition que la surface inférieure soit aussi utilisable par les animaux.\n"
            socialStructure: "Les cochons d’Inde sont des animaux sociaux et ne doivent pas être détenus sans congénères.\n"
          links:
            '0':
              text: 'Feulle d’information PSA: Cochons d’Inde'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_meerschweinchen_fr.pdf'
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        rabbit:
          name: 'Lapin'
          namePlural: 'Lapins'
          minAnimalsErrorMessage: 'Les lapins sont des animaux sociaux. La détention individuelle n’est autorisée qu’en cas de contact auditif ou olfactif avec d’autres congénères!'
          maxAnimalsWarningMessage: 'Actuellement, à partir d''une taille de groupe de trois animaux, les recommandations concernent uniquement la surface minimale. Les recommandations actuelles en matière de surface sont très réduites et en cours de révision.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe! Les lapins doivent être détenus en groupes de 2 à 6 animaux.'
          recommendations:
            socialStructure: "Taille de groupe recommandée: 2 à 6 animaux\nComposition du groupe: un mâle castré avec 1 ou 2 femelles, 2 mâles castrés avec 2 à 4 femelles, des groupes uniquement de femelles sont parfois aussi possibles\n"
          laws:
            furnishing: "Possibilités de retrait pour tous les animaux, objets à ronger (branches fraîches...), fourrage grossier (foin ou paille)\n"
            raisedAreas: "Les plateformes surélevées praticables peuvent être comptées dans la surface minimale requise, à condition que la surface inférieure soit aussi utilisable par les animaux.\n"
            socialStructure: "Les lapins sont des animaux sociaux. La détention individuelle n’est autorisée qu’en cas de contact auditif et olfactif avec d’autres congénères.\n"
          links:
            '0':
              text: 'Feulle d’information PSA: Lapin'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_kaninchen_fr.pdf'
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
        rat:
          name: 'Rat'
          namePlural: 'Rats'
          minAnimalsErrorMessage: 'Les rats sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'Taille maximale du groupe dépassée. Les rats doivent être détenus en groupes de 2 à 6 animaux.'
          maxAnimalsErrorMessage: 'Une détention respectueuse des animaux dans un enclos n’est plus possible avec cette taille de groupe! Les rats doivent être détenus en groupes de 2 à 6 animaux.'
          recommendations:
            socialStructure: "Taille de groupe recommandée: 2 à 6 animaux\nComposition du groupe: groupes uniquement de mâles, groupes uniquement de femelles, groupe mixte avec mâles castrés\n"
          laws:
            furnishing: "Litière adaptée, possibilités de retrait pour tous les animaux, matériel de nidification approprié, fourrage grossier (foin, paille, mélanges de graines), objets à ronger (branches fraîches...)\n"
            raisedAreas: "Les plateformes surélevées ne doivent pas être comptées dans la surface minimale.\n"
            socialStructure: "Les rats sont des animaux sociaux et ne doivent pas être détenus sans congénères.\n"
          links:
            '0':
              text: 'Feulle d’information PSA: Le rat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_ratten_fr.pdf'
            '1':
              text: 'Détenir des rongeurs et des Lapins'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Nager_A4_web.pdf'
            '2':
              text: 'Checkliste à l’attention des futurs détenteurs d‘animaux'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/checkliste_fr.pdf'
            '3':
              text: 'Assortiment des animaleries et boutiques en ligne'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_assortiment_animaleries.pdf'
            '4':
              text: 'Check-list animaleries'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_fr.pdf'
      reptiles:
        royalPython:
          name: 'Python royal'
          namePlural: 'Pythons royaux'
          textLength: 'Pour les serpents, on mesure toute la longueur du corps.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des pythons royaux. Les pythons royaux cohabitent bien avec leurs congénères.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des pythons royaux. Un nombre aussi élevé d’animaux n’est pas recommandé.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des pythons royaux. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilité de baignade, possibilités de se cacher, température et conditions climatiques adaptées à l’espèce, possibilités de grimper\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: 'Oui'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, l’éclairage doit être installé de manière à ce que les animaux ne puissent pas s’y brûler, possibilités de grimper, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. tubes de liège, racines, branches et plateformes surélevées, Wetbox, bassin, eau et nourriture adaptée à l’espèce (proies)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 26-32 °C, spot chauffant à 36 °C, baisser la température la nuit, les pythons royaux s’entendent très bien entre eux et peuvent être détenus par deux ou par trois si le terrarium est assez grand.\n"
            waterBasin: 'Bassins'
          links:
            '0':
              text: 'Feuille d''information Python royal'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_k%C3%B6nigspython_fr.pdf'
            '1':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '2':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        cornSnake:
          name: 'Serpent des blés'
          namePlural: 'Serpents des blés'
          textLength: 'Pour les serpents, on mesure toute la longueur du corps.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des serpents des blés. Les serpents des blés cohabitent bien avec leurs congénères.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des serpents des blés. Un nombre aussi élevé d’animaux n’est pas recommandé.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des serpents des blés. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Température et conditions climatiques adaptées à l’espèce, possibilités de se cacher, possibilités de grimper\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, l’éclairage doit être installé de manière à ce que les animaux ne puissent pas s’y brûler, bassin, possibilités de grimper, substrat meuble, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. tubes de liège, racines, branches, Wetbox, eau et nourriture adaptée à l’espèce (proies)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 24-28 °C, spot chauffant à 35 °C, baisser la température la nuit; selon le climat d’origine, hibernation dans un endroit frais recommandée.\n"
            waterBasin: 'Bassins'
          links:
            '0':
              text: 'Feuille d''information Serpent des blés'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_kornnatter_fr.pdf'
            '1':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '2':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        greekTortoise:
          name: 'Tortue d’Hermann'
          namePlural: 'Tortues d’Hermann'
          textLength: 'Pour les tortues, on mesure la longueur de la carapace.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher et sol pour creuser, place au soleil ainsi que des lampes émettand des UV, accès à la lumière naturelle du soleil, sorties à l’extérieur, possibilité d’hiberner\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Enclos extérieur avec clôture anti-fugue et possibilités de séparation, abri clos, p. ex. serre, couche à semis, substrat varié en partie creusable (pierres, sable, gravier, calcaire du Jura…), points chauds, possibilité de retrait, p. ex. végétation, racines, pierres etc., eau et nourriture adaptée à l’espèce (foin, herbes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’hibernation, d’aménagement de l’enclos et d’alimentation. La possibilité d’hiberner en toute sécurité est importante.\n"
            more: "Les tortues terrestres ont une espérance de vie très longue (40-80 ans)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Informations de base sur la détention des Tortues terrestres européennes,'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_fr.pdf'
            '1':
              text: 'Détention respectueuse des tortues dans la pratique'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_fr.pdff'
            '2':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '3':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        moorishTortoise:
          name: 'Tortue mauresque'
          namePlural: 'Tortues mauresques'
          textLength: 'Pour les tortues, on mesure la longueur de la carapace.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher et sol pour creuser, place au soleil ainsi que des lampes émettand des UV, accès à la lumière naturelle du soleil, sorties à l’extérieur, possibilité d’hiberner\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Enclos extérieur avec clôture anti-fugue et possibilités de séparation, abri clos, p. ex. serre, couche à semis, substrat varié en partie creusable (pierres, sable, gravier, calcaire du Jura…), points chauds, possibilité de retrait, p. ex. végétation, racines, pierres etc., eau et nourriture adaptée à l’espèce (foin, herbes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’hibernation, d’aménagement de l’enclos et d’alimentation. La possibilité d’hiberner en toute sécurité est importante.\n"
            more: "Les tortues terrestres ont une espérance de vie très longue (40-80 ans)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Informations de base sur la détention des Tortues terrestres européennes,'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_fr.pdf'
            '1':
              text: 'Détention respectueuse des tortues dans la pratique'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_fr.pdff'
            '2':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '3':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        wideShelledTortoise:
          name: 'Tortue bordée'
          namePlural: 'Tortues bordées'
          textLength: 'Pour les tortues, on mesure la longueur de la carapace.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des tortues terrestres. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher et sol pour creuser, place au soleil ainsi que des lampes émettand des UV, accès à la lumière naturelle du soleil, sorties à l’extérieur, possibilité d’hiberner\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Enclos extérieur avec clôture anti-fugue et possibilités de séparation, abri clos, p. ex. serre, couche à semis, substrat varié en partie creusable (pierres, sable, gravier, calcaire du Jura…), points chauds, possibilité de retrait, p. ex. végétation, racines, pierres etc., eau et nourriture adaptée à l’espèce (foin, herbes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’hibernation, d’aménagement de l’enclos et d’alimentation. La possibilité d’hiberner en toute sécurité est importante.\n"
            more: "Les tortues terrestres ont une espérance de vie très longue (40-80 ans)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Informations de base sur la détention des Tortues terrestres européennes,'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_fr.pdf'
            '1':
              text: 'Détention respectueuse des tortues dans la pratique'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_fr.pdff'
            '2':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '3':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        crownedGecko:
          name: 'Gecko à crête'
          namePlural: 'Geckos à crête'
          textLength: 'Pour les lézards, on mesure la longueur du corps sans la queue.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos à crête. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos à crête. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des geckos à crête. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher, température et conditions climatiques adaptées à l’espèce, possibilités de grimper\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux (p. ex. tubes de liège, racines, plantes), possibilités de grimper (p. ex. branches finement ramifiées, plantes), substrat pour creuser, eau et nourriture adaptée à l’espèce (insectes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 25-30 °C, baisser la température la nuit. Températures hivernales inférieures de 5-6 °C. Les mâles ne s’entendent pas entre eux, ne pas compter sur leur socialisation.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '1':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        boaConstrictor:
          name: 'Boa constricteur'
          namePlural: 'Boas constricteurs'
          textLength: 'Pour les serpents, on mesure toute la longueur du corps.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des boas constricteurs. Les boas constricteurs s’entendent très bien entre eux et peuvent être détenus par deux ou par trois si le terrarium est assez grand.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des boas constricteurs. Un nombre aussi élevé d’animaux n’est pas recommandé.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des boas constricteurs. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilité de baignade, possibilités de se cacher, température et conditions climatiques adaptées à l’espèce, possibilités de grimper\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, l’éclairage doit être installé de manière à ce que les animaux ne puissent pas s’y brûler, possibilités de grimper, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. tubes de liège, racines, branches et plateformes surélevées, Wetbox, bassin, eau et nourriture adaptée à l’espèce (proies)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 22-30 °C, spot chauffant à 35 °C, baisser la température la nuit, les boas constricteurs s’entendent très bien entre eux et peuvent être détenus par deux ou par trois si le terrarium est assez grand.\n"
            more: "Les boas constricteurs peuvent atteindre plus de 4 m de long. Les données indiquées ici concernent des animaux mesurant jusqu’à 250 cm de longueur. Les animaux plus grands ont besoin de plus grands enclos.\n"
            waterBasin: 'Bassin 0.5 m²'
          links:
            '0':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '1':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        muskTurtle:
          name: 'Sternotherus odoratus'
          namePlural: 'Sternotherus odoratus'
          textLength: 'Pour les tortues, on mesure la longueur de la carapace.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des sternotherus odoratus. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des sternotherus odoratus. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des sternotherus odoratus. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Température et conditions climatiques adaptées à l’espèce, place au soleil ainsi que des lampes émettand des UV, possibilités de se cacher\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: 'en plus de la surface'
          recommendations:
            furnishing: "Détention en extérieur recommandée en été, lampes émettant des UV et éclairage de base, l’éclairage doit être installé de manière à ce que les animaux ne puissent pas s’y brûler, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. racines, branches, pierres, feuillage, partie terrestre facilement accessible, nourriture adaptée à l’espèce (proies)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 25-28 °C, spot chauffant à 35 °C, baisser la température la nuit, hibernation dans un endroit frais recommandée.\n"
            waterBasin: 'de la surface totale'
          links:
            '0':
              text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
              file: 'https://www.tierschutz.com/publikationen/heimtiere/infothek/gehege/broschuere_gehege_reptilien.pdf'
            '1':
              text: 'Merkblatt Reptilien als Heimtiere'
              file: 'http://www.tierschutz.com/publikationen/heimtiere/infothek/reptilien/reptilien_heimtiere.pdf'
        kingSnake:
          name: 'Serpent roi'
          namePlural: 'Serpents rois'
          textLength: 'Pour les serpents, on mesure toute la longueur du corps.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des serpents rois.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des serpents rois. Un nombre aussi élevé d’animaux n’est pas recommandé.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des serpents rois. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Température et conditions climatiques adaptées à l’espèce, possibilités de se cacher\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, l’éclairage doit être installé de manière à ce que les animaux ne puissent pas s’y brûler, bassin, possibilités de grimper, substrat meuble, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. tubes de liège, racines, branches, Wetbox, eau et nourriture adaptée à l’espèce (proies)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 25-30 °C, spot chauffant à 35 °C, baisser la température la nuit; selon le climat d’origine, hibernation dans un endroit frais recommandée.\n"
            more: "Les serpents rois se nourrissent également d’autres serpents et ne doivent donc pas être détenus avec d’autres serpents.\n"
            waterBasin: 'Bassins'
          links:
            '0':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '1':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        madagascarDayGecko:
          name: 'Gecko diurne de Madagascar'
          namePlural: 'Geckos diurnes de Madagascar'
          textLength: 'Pour les lézards, on mesure la longueur du corps sans la queue.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos diurnes. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos diurnes. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des geckos diurnes. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de grimper à l’horizontale et à la verticale, possibilités de se cacher, conditions climatiques adaptées à l’espèce et place au soleil ainsi que des lampes émettand des UV\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, l’éclairage UV doit être installé de manière à ce que tous les animaux aient accès à la lumière (placement approprié et nombre suffisant de points chauds), mais ne puissent pas se brûler aux lampes, système de production de l’humidité de l’air nécessaire, p. ex. nébuliseur, système d’aspersion, vaporisateur, végétation, cascade, etc., système de mesure de la température et de l’humidité de l’air, possibilités de grimper variées et adaptées à l’espèce, au moins de l’épaisseur du corps (paroi arrière, branches, bâtons de bambou, plantes à feuilles larges, etc.), possibilités de retrait adaptées à l’espèce (végétation, racines, pierres, tiges de bambou coupées, etc.), substrat adapté, eau et nourriture adaptée à l’espèce (insectes, nectar, pulpe de fruit)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 25-30 °C, spot chauffant à 40°C, baisser la température la nuit. Les mâles ne s’entendent pas entre eux, ne pas compter sur leur socialisation.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Feuille d''information Grand gecko diurne de Madagascar'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_taggecko_it.pdf'
            '1':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '2':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        goldDustDayGecko:
          name: 'Gecko diurne à poussière d’or'
          namePlural: 'Geckos diurnes à poussière d’or'
          textLength: 'Pour les lézards, on mesure la longueur du corps sans la queue.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos diurnes. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos diurnes. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des geckos diurnes. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de grimper à l’horizontale et à la verticale, possibilités de se cacher, conditions climatiques adaptées à l’espèce et place au soleil ainsi que des lampes émettand des UV\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, l’éclairage UV doit être installé de manière à ce que tous les animaux aient accès à la lumière (placement approprié et nombre suffisant de points chauds), mais ne puissent pas se brûler aux lampes, système de production de l’humidité de l’air nécessaire, p. ex. nébuliseur, système d’aspersion, vaporisateur, végétation, cascade, etc., système de mesure de la température et de l’humidité de l’air, possibilités de grimper variées et adaptées à l’espèce, au moins de l’épaisseur du corps (paroi arrière, branches, bâtons de bambou, plantes à feuilles larges, etc.), possibilités de retrait adaptées à l’espèce (végétation, racines, pierres, tiges de bambou coupées, etc.), substrat adapté, eau et nourriture adaptée à l’espèce (insectes, nectar, pulpe de fruit)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, d’aménagement du terrarium et d’alimentation. Température de base 25-30 °C, spot chauffant à 40°C, baisser la température la nuit. Les animaux mâles ne s’entendent pas entre eux, ne pas compter sur leur socialisation.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '1':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        leopardGecko:
          name: 'Gecko léopard'
          namePlural: 'Gecko léopards'
          textLength: 'Pour les lézards, on mesure la longueur du corps sans la queue.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos léopards. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des geckos léopards. Un nombre aussi élevé d’animaux n’est pas recommandé. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des geckos léopards. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher, possibilités de creuser, conditions climatiques adaptées à l’espèce et éclairage\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampes émettant des UV et éclairage de base, système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux (p. ex. tubes de liège, racines, au moins une cachette légèrement humide), possibilités de grimper (p. ex. structures rocheuses, paroi arrière, racines), substrat pour creuser, eau et nourriture adaptée à l’espèce (insectes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, de repos hivernal, d’aménagement du terrarium et d’alimentation. Température de base 22-28 °C, spot chauffant à 35°C, baisser la température la nuit. Les mâles sont territoriaux et la socialisation n’est pas recommandée.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Feuille d''information Gecko léopard'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_leopardgecko_fr.pdf'
            '1':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '2':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        dwarfBeardedDragon:
          name: 'Agame barbu nain'
          namePlural: 'Agames barbus nains'
          textLength: 'Pour les lézards, on mesure la longueur du corps sans la queue.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des agames barbus. Les mâles ne s’entendent généralement pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des agames barbus. Les mâles ne s’entendent pas entre eux. Un nombre aussi élevé d’animaux n’est pas recommandé.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des agames barbus. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher et de grimper, conditions climatiques adaptées à l’espèce et Possibilités de grimper à l’horizontale et à la verticale, possibilités de se cacher, conditions climatiques adaptées à l’espèce et place au soleil ainsi que des lampes émettand des UV\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Plateformes surélevées, lampes émettant des UV et éclairage de base, l’éclairage UV doit être installé de manière à ce que tous les animaux y aient accès (placement approprié et nombre suffisant de points chauds), mais ne puissent pas s’y brûler (pas de contact direct possible avec la lampe), système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. tubes de liège, racines, possibilité de baignade, possibilités de grimper et rochers, sable pour creuser, eau et nourriture adaptée à l’espèce (nourriture fraîche, insectes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, de repos hivernal, d’aménagement du terrarium et d’alimentation. Température de base 25-30 °C, spot chauffant à 45 °C, baisser la température la nuit; selon le climat d’origine, hibernation dans un endroit frais recommandée. Même si les agames barbus sont des solitaires en dehors de la période d’accouplement, la compagnie de congénères est un enrichissement important, à condition que les animaux s’entendent.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Feuille d''information Agame barbu'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_bartagame_fr.pdf'
            '1':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '2':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
        centralBeardedDragon:
          name: 'Pogona vitticeps'
          namePlural: 'Pogona vitticeps'
          textLength: 'Pour les lézards, on mesure la longueur du corps sans la queue.'
          minAnimalsWarningMessage: 'Informez-vous sur la structure sociale des agames barbus. Les mâles ne s’entendent pas entre eux.'
          maxAnimalsWarningMessage: 'Informez-vous sur la structure sociale des agames barbus. Les mâles ne s’entendent pas entre eux. Un nombre aussi élevé d’animaux n’est pas recommandé.'
          maxAnimalsErrorMessage: 'Informez-vous sur la structure sociale des agames barbus. Un nombre aussi élevé d’animaux n’est pas acceptable.'
          laws:
            furnishing: "Possibilités de se cacher et de grimper, conditions climatiques adaptées à l’espèce et Possibilités de grimper à l’horizontale et à la verticale, possibilités de se cacher, conditions climatiques adaptées à l’espèce et place au soleil ainsi que des lampes émettand des UV\n"
            socialStructure: "Respecter la structure sociale. Détention isolée des animaux éventuellement possible.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Plateformes surélevées, lampes émettant des UV et éclairage de base, l’éclairage UV doit être installé de manière à ce que tous les animaux y aient accès (placement approprié et nombre suffisant de points chauds), mais ne puissent pas s’y brûler (pas de contact direct possible avec la lampe), système de mesure pour le contrôle de la température et de l’humidité, au moins autant de possibilités de retrait que d’animaux, p. ex. tubes de liège, racines, possibilité de baignade, possibilités de grimper et rochers, sable pour creuser, eau et nourriture adaptée à l’espèce (nourriture fraîche, insectes)\n"
            socialStructure: "Prendre en compte les besoins spécifiques de l’espèce en matière d’habitat, de comportement social, de climat, de repos hivernal, d’aménagement du terrarium et d’alimentation. Température de base 25-30 °C, spot chauffant à 45 °C, baisser la température la nuit; selon le climat d’origine, hibernation dans un endroit frais recommandée. Même si les agames barbus sont des solitaires en dehors de la période d’accouplement, la compagnie de congénères est un enrichissement important, à condition que les animaux s’entendent.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Feuille d''information Agame barbu'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_bartagame_de.pdf'
            '1':
              text: 'Reptiles: Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Reptilien_A4_web.pdf'
            '2':
              text: 'Feuille d''information Des reptiles pour animaux de compagnie'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_fr.pdf'
      birds:
        diamondDove:
          name: 'Colombe (géopélie) diamant'
          namePlural: 'Colombes (géopélies) diamants'
          minAnimalsErrorMessage: 'Les colombes diamants sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des colombes diamants est en groupe. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'Possibilités de baignade, perchoirs souples de différentes épaisseurs et orientations ainsi qu’une mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les colombes diamants sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe avec plusieurs couples nicheurs.'
            indoor: 'Les colombes diamants peuvent être détenues à l’intérieur dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La variante la plus respectueuse des animaux pour détenir des colombes diamants est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les colombes diamants sont généralement très paisibles et peuvent être détenues avec d’autres espèces d’oiseaux paisibles à condition d’avoir les compétences nécessaires. \nLes colombes diamants aiment passer beaucoup de temps au sol pendant la journée. Il est donc essentiel qu’elles disposent d’une surface au sol spacieuse. Elles sont très agiles en vol, elles doivent donc disposer aussi d’un espace de vol suffisant."
          links:
            '0':
              text: 'Détenir des oiseaux en respectant leurs besoins - Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Voegel_A4_web.pdf'
        canary:
          name: 'Canari'
          namePlural: 'Canaris'
          minAnimalsErrorMessage: 'Les canaris sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des canaris est en groupe. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les canaris sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'Les canaris peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La variante la plus respectueuse des animaux pour détenir des canaris est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: 'Détenir des canaris avec une autre espèce d’oiseau n’est recommandé que pour des personnes ayant de solides connaissances. Attention: il existe des variétés d’élevage de canaris (p. ex. certains canaris de posture) qui font partie des pratiques d’élevage associées à de grandes souffrances qu’il est fortement déconseillé d’acheter.'
          links:
            '0':
              text: 'STS Merkblatt Kanarienvogel'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_kanarien_fr.pdf'
        zebraFinch:
          name: 'Diamant mandarin'
          namePlural: 'Diamants mandarins'
          minAnimalsErrorMessage: 'Les diamants mandarins sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des diamants mandarins est en groupe. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les diamants mandarins sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'Les diamants mandarins peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La variante la plus respectueuse des animaux pour détenir des diamants mandarins est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (au moins 12 °C). Une partie du toit de la volière doit êtrecouverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.). Attention: il faut les détenir en intérieur en automne et en hiver!'
            specificNeeds: 'Détenir des diamants mandarins avec une autre espèce d’oiseau n’est recommandé que pour des personnes ayant de solides connaissances.'
          links:
            '0':
              text: 'STS Merkblatt Zebrafinken'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_zebrafinken_fr.pdf'
        grayWaxbill:
          name: 'Astrild cendré'
          namePlural: 'Astrilds cendrés'
          minAnimalsErrorMessage: 'Les astrilds cendrés sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des astrilds cendrés est en groupe. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les astrilds cendrés sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'Les astrilds cendrés peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'Possible uniquement au printemps et en été, car les astrilds cendrés sont sensibles au froid. Au printemps et en été, en enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin), chauffable (au moins 18 °C).  Une partie du toit de la volière doit être couvert (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: 'Détenir des astrilds cendrés avec une autre espèce d’oiseau n’est recommandé que pour des personnes ayant de solides connaissances.'
          links:
            '0':
              text: 'Détenir des oiseaux en respectant leurs besoins - Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Voegel_A4_web.pdf'
        goldenBreasted:
          name: 'Bengali zébré'
          namePlural: 'Bengalis zébrés'
          minAnimalsErrorMessage: 'Les bengalis zébrés sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des bengalis zébrés est en groupe. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les bengalis zébrés doivent être détenus en couple car les mâles peuvent être agressifs envers des rivaux de la même espèce pendant la période de nidification.'
            indoor: 'Les bengalis zébrés peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'Possible uniquement au printemps et en été, car les bengalis zébrés sont sensibles au froid. Au printemps et en été, en enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin), chauffable (au moins 15 °C).  Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Cet oiseau d’une grande longévité, très actif, a besoin d’une volière spacieuse avec de l’herbe et des roseaux.\nDétenir des bengalis zébrés avec une autre espèce d’oiseau n’est recommandé que pour des personnes ayant de solides connaissances."
          links:
            '0':
              text: 'Détenir des oiseaux en respectant leurs besoins - Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Voegel_A4_web.pdf'
        gouldianMadine:
          name: 'Diamant de Gould'
          namePlural: 'Diamants de Gould'
          minAnimalsErrorMessage: 'Les diamants de Gould sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des diamants de Gould est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les diamants de Gould sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe avec plusieurs couples nicheurs.'
            indoor: 'Les diamants de Gould peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'Possible uniquement au printemps et en été, car les diamants de Gould sont sensibles au froid. Au printemps et en été, en enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin), chauffable (au moins 18 °C).  Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.). Les diamants de Gould doivent être détenus à l’intérieur en automne et en hiver (voir ci-dessus).'
            specificNeeds: "\"Beaucoup de couvert et suffisamment de possibilités de se cacher sont essentiels pour ces oiseaux craintifs. \n\nDétenir des diamants de Gould avec une autre espèce d’oiseau n’est recommandé que pour des personnes ayant de solides connaissances.\nIl faut s’abstenir d’acheter des mutations de couleurs obtenues par élevage. \""
          links:
            '0':
              text: 'STS Merkblatt Gouldamadinen'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Gouldamadinen_fr.pdf'
        budgerigar:
          name: 'Perruche ondulée'
          namePlural: 'Perruches ondulées'
          minAnimalsErrorMessage: 'Les perruches ondulées sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des perruches ondulées est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Tous les psittaciformes doivent avoir suffisamment de branches naturelles à ronger et pour grimper.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les perruches ondulées sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs (au moins 2, de préférence 3). Cela nécessite une grande volière, car les mâles défendent le nid en période de nidification.'
            indoor: 'Les perruches ondulées peuvent être détenues dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La forme de détention la plus respectueuse des perruches ondulées est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les perruches ondulées ont besoin de possibilités de grimper variées sous forme de branches naturelles p. ex. de noisetier, saule, hêtre, arbres fruitiers non traités. Elles servent également de matériel à ronger et doivent être renouvelées régulièrement.\n\nLa socialisation avec d’autres espèces est possible, mais uniquement recommandée à des détenteurs d’oiseaux expérimentés. Il faut s’abstenir d’acheter des variétés d’élevage extrêmes (p. ex. des oiseaux avec des huppes)."
          links:
            '0':
              text: 'STS Merkblatt Wellensittiche'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_wellensittiche_fr.pdf'
        agapornid:
          name: 'Inséparable'
          namePlural: 'Inséparables'
          minAnimalsErrorMessage: 'Les inséparables sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des inséparables est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les inséparables sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'Les inséparables peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La forme de détention la plus respectueuse des inséparables est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les inséparables de Fischer, les inséparables rosegorges, les inséparables masqués et les inséparables à joues noires sont les principales espèces à considérer comme animaux de compagnie. Les autres espèces d’inséparables sont plus exigeantes et donc déconseillées aux débutants.\nCertaines espèces d’inséparables peuvent être assez agressives et ne conviennent pas pour être détenues avec d’autres espèces.\n\nLes inséparables peuvent être assez bruyants. Par conséquent, les voisins devraient êtres avertis."
          links:
            '0':
              text: 'Feuille d''information SPA: Inséparables'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Agaporniden_fr.pdf'
        tarantaParrot:
          name: 'Inséparable d’Abyssinie'
          namePlural: 'Inséparables d’Abyssinie'
          minAnimalsErrorMessage: 'Les inséparables d’Abyssinie sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des inséparables d’Abyssinie est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'Gesetzlich vorgeschrieben sind eine Badegelegenheit, federnde Sitzstangen von unterschiedlicher Dicke und Ausrichtung sowie die Gabe von Vogelsand zur freien Aufnahme.Ein Drittel des Volumens muss frei von Strukturen sein. In Gehegen, welche kleiner als 2 m2 sind, darf das Verhältnis von Länge zu Breite, bezogen auf die Mindestfläche, maximal 2:1 betragen. Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les inséparables d’Abyssinie sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'Les inséparables d’Abyssinie peuvent être détenus dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La forme de détention la plus respectueuse des inséparables est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel).  Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les inséparables d’Abyssinie sont la plus grande espèce d’inséparables. \nLa détention de cette espèce d’inséparables est exigeante et ne convient pas aux débutants.\nLa socialisation avec d’autres espèces est possible, mais uniquement recommandée à des détenteurs d’oiseaux expérimentés. \nLes inséparables d’Abyssinie peuvent aussi être assez bruyants. Par conséquent, les voisins devraient êtres avertis."
          links:
            '0':
              text: 'Feuille d''information SPA: Inséparables'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Agaporniden_fr.pdf'
        cockatiel:
          name: 'Calopsitte élégante'
          namePlural: 'Calopsittes élégantes'
          minAnimalsErrorMessage: 'Les calopsittes élégantes sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des calopsittes élégantes est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Tous les psittaciformes doivent avoir suffisamment de branches naturelles à ronger et pour grimper.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les calopsittes élégantes sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'Les calopsittes élégantes peuvent être détenues dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La forme de détention la plus respectueuse des inséparables est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les calopsittes élégantes ont besoin de possibilités de grimper variées sous forme de branches naturelles p. ex. de noisetier, saule, hêtre, arbres fruitiers non traités. Elles servent également de matériel à ronger et doivent être renouvelées régulièrement.\n\nLa socialisation avec d’autres espèces est possible, mais uniquement recommandée à des détenteurs d’oiseaux expérimentés."
          links:
            '0':
              text: 'Feuille d''information SPA: Calopsitte élégante'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Nymphensittiche_fr.pdf'
        beautifulParakeet:
          name: 'Perruche turquoisine'
          namePlural: 'Perruches turquoisines'
          minAnimalsErrorMessage: 'Les perruches turquoisines sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des perruches turquoisines est en couple nicheur. Il est essentiel d’être attentif aux compatibilités individuelles des animaux, car les perruches turquoisines peuvent réagir de manière assez agressive envers leurs congénères ainsi qu’envers d’autres espèces d’oiseaux.'
          maxAnimalsErrorMessage: 'Attention! La détention de perruches turquoisines est exigeante. Les perruches turquoisines doivent être détenues en couple car elles peuvent être agressives envers leurs congénères et d’autres espèces d’oiseaux.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures, et dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Tous les psittaciformes doivent avoir suffisamment de branches naturelles à ronger et pour grimper.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les perruches turquoisines sont très sociables mais peuvent être très agressives envers les autres oiseaux, y compris ceux de leur propre espèce. Il est donc recommandé de détenir les perruches turquoisines en couple.'
            outdoor: 'La forme de détention la plus respectueuse des perruches turquoisines est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les perruches turquoisines ont besoin d’une surface de vol libre aussi grande que possible, car leur vol est rectiligne. Elles ont également besoin de beaucoup d’occupation et de possibilités de ronger sous forme de branches fraîches variées non toxiques.\nIl n’est pas conseillé de les socialiser avec d’autres espèces d’oiseaux, car elles peuvent réagir de manière agressive."
          links:
            '0':
              text: 'Détenir des oiseaux en respectant leurs besoins - Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Voegel_A4_web.pdf'
        rosellaConure:
          name: 'Perruche omnicolore'
          namePlural: 'Perruches omnicolores'
          minAnimalsErrorMessage: 'Les perruches omnicolores sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des perruches omnicolores est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 20.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Tous les psittaciformes doivent avoir suffisamment de branches naturelles à ronger et pour grimper.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les perruches omnicolores sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs. Il faut une très grande volière en cas de détention de plusieurs couples, étant donné que les mâles sont très agressifs pendant la période de pariade et de nidification.'
            indoor: 'Les perruches omnicolores peuvent être détenues dans des volières spacieuses ou dans des pièces spécialement aménagées pour les oiseaux. Les cages à oiseaux classiques sont beaucoup trop petites pour les détenir conformément aux besoins de leur espèce. Les volières hautes et étroites ne sont pas adaptées parce que les oiseaux ont besoin d’espace horizontal pour voler. Les vitrines ne se prêtent pas non plus à la détention des oiseaux.'
            outdoor: 'La forme de détention la plus respectueuse des perruches omnicolores est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les perruches omnicolores ont besoin d’une surface de vol libre aussi grande que possible, car elles sont très actives. Elles ont également besoin de beaucoup d’occupation et de possibilités de ronger sous forme de branches fraîches variées non toxiques.\nLa socialisation avec d’autres espèces est possible, mais uniquement recommandée à des détenteurs d’oiseaux expérimentés."
          links:
            '0':
              text: 'Détenir des oiseaux en respectant leurs besoins - Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Voegel_A4_web.pdf'
        cockatoo:
          name: 'Cacatoès'
          namePlural: 'Cacatoès'
          minAnimalsErrorMessage: 'Les cacatoès sont des animaux sociaux. La détention individuelle est donc interdite! La détention de cacatoès (à l’exception des calopsittes élégantes) est extrêmement exigeante et soumise à autorisation. Si vous avez l’intention de détenir des cacatoès, adressez-vous à l’office vétérinaire cantonal de votre canton de résidence pour en savoir plus.'
          maxAnimalsWarningMessage: 'La détention de cacatoès (à l’exception des calopsittes élégantes) est extrêmement exigeante et soumise à autorisation. Si vous avez l’intention de détenir des cacatoès, adressez-vous à l’office vétérinaire cantonal de votre canton de résidence pour en savoir plus.'
          maxAnimalsErrorMessage: 'La détention de cacatoès (à l’exception des calopsittes élégantes) est extrêmement exigeante et soumise à autorisation. Si vous avez l’intention de détenir des cacatoès, adressez-vous à l’office vétérinaire cantonal de votre canton de résidence pour en savoir plus.'
          laws: { }
          recommendations:
            general: "Pour des raisons de protection des animaux, la Protection Suisse des Animaux PSA déconseille la détention privée d'ara/cacatoès. . L'espace nécessaire à une détention d'ara/cacatoès conforme aux besoins des animaux est immense et ne peut généralement pas être rempli par des détenteurs privés. La détention de cacatoès ou d'aras est extrêmement exigeante et soumise à autorisation. Toute personne intéressée par l’achat de cacatoès doit suivre un cours pour l’obtention de l’attestation de compétences avant l’achat.\nEn outre, il faut déposer une demande auprès de l’office vétérinaire cantonal du canton de domicile et prouver que toutes les exigences légales en matière de détention, de soins et d’acquisition peuvent être remplies. Si vous avez l'intention de détenir des cacatoès ou des aras, veuillez vous adresser à l'office vétérinaire cantonal de votre canton de résidence pour de plus amples informations."
          links:
            '0':
              text: 'Fachinformation Tierschutz - Bewilligungspflicht für die Haltung von Grosspapageien - Bundesamt für Lebensmittelsicherheit und Veterinärwesen BLV'
              file: 'https://www.blv.admin.ch/dam/blv/de/dokumente/tiere/heim-und-wildtierhaltung/fi-bew-pflicht-grosspapageien.pdf.download.pdf/D_3.1_Fachinformation_Bewilligungspflicht_f%C3%BCr_Grosspapageien.pdf'
        macaw:
          name: 'Ara'
          namePlural: 'Aras'
          minAnimalsErrorMessage: "Les aras sont des animaux sociaux. La détention individuelle est donc interdite! \nLa détention de grands perroquets tels que les aras est extrêmement exigeante et soumise à autorisation. Si vous avez l’intention de détenir des aras, adressez-vous à l’office vétérinaire cantonal de votre canton de résidence pour en savoir plus."
          maxAnimalsWarningMessage: 'La détention de grands perroquets tels que les aras est extrêmement exigeante et soumise à autorisation. Si vous avez l’intention de détenir des aras, adressez-vous à l’office vétérinaire cantonal de votre canton de résidence pour en savoir plus.'
          maxAnimalsErrorMessage: 'La détention de grands perroquets tels que les aras est extrêmement exigeante et soumise à autorisation. Si vous avez l’intention de détenir des aras, adressez-vous à l’office vétérinaire cantonal de votre canton de domicile pour en savoir plus.'
          laws: { }
          recommendations:
            general: "Pour des raisons de protection des animaux, la Protection Suisse des Animaux PSA déconseille la détention privée d'ara/cacatoès. . L'espace nécessaire à une détention d'ara/cacatoès conforme aux besoins des animaux est immense et ne peut généralement pas être rempli par des détenteurs privés. La détention de cacatoès ou d'aras est extrêmement exigeante et soumise à autorisation. Toute personne intéressée par l’achat de cacatoès doit suivre un cours pour l’obtention de l’attestation de compétences avant l’achat.\nEn outre, il faut déposer une demande auprès de l’office vétérinaire cantonal du canton de domicile et prouver que toutes les exigences légales en matière de détention, de soins et d’acquisition peuvent être remplies. Si vous avez l'intention de détenir des cacatoès ou des aras, veuillez vous adresser à l'office vétérinaire cantonal de votre canton de résidence pour de plus amples informations."
          links:
            '0':
              text: 'Fachinformation Tierschutz - Bewilligungspflicht für die Haltung von Grosspapageien - Bundesamt für Lebensmittelsicherheit und Veterinärwesen BLV'
              file: 'https://www.blv.admin.ch/dam/blv/de/dokumente/tiere/heim-und-wildtierhaltung/fi-bew-pflicht-grosspapageien.pdf.download.pdf/D_3.1_Fachinformation_Bewilligungspflicht_f%C3%BCr_Grosspapageien.pdf'
        congoGreyParrot:
          name: 'Perroquet jaco/Gris du Gabon'
          namePlural: 'Perroquets jaco'
          minAnimalsErrorMessage: 'Les perroquets jaco sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des perroquets jaco est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 6.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures. Dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Tous les psittaciformes doivent avoir suffisamment de branches naturelles à ronger et pour grimper.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les perroquets jaco sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de plusieurs couples nicheurs.'
            indoor: 'La détention exclusivement à l’intérieur ne convient pas aux perroquets jaco.'
            outdoor: 'La forme de détention la plus respectueuse des perroquets jaco est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "La détention de perroquets jaco respectueuse des animaux est extrêmement exigeante. \nLe commerce de ces animaux d’une très grande longévité est en plein essor et les offres de fournisseurs peu scrupuleux sont très nombreuses (p. ex. élevage à la main, importations de sources peu claires). Les perroquets jaco peuvent être bruyants. Par conséquent, les voisins devraient êtres avertis.\n\nPour des raisons de protection des animaux, il faut s’abstenir d’acheter des animaux élevés à la main."
          links:
            '0':
              text: 'FEUILLE D’INFORMATION PSA - Perroquet jaco'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_graupapageien_fr.pdf'
        amazon:
          name: 'Amazone'
          namePlural: 'Amazone'
          minAnimalsErrorMessage: 'Les amazones sont des animaux sociaux. La détention individuelle est donc interdite!'
          maxAnimalsWarningMessage: 'La forme de détention la plus respectueuse des amazones est en groupe de couples nicheurs. Cependant, il faut être attentif aux compatibilités individuelles des animaux, notamment pendant la période de pariade et de nidification. Pour détenir plus d’un couple nicheur, il faut opter pour une volière spacieuse avec de nombreuses possibilités d’évitement et de retrait.'
          maxAnimalsErrorMessage: 'Veuillez indiquer un nombre inférieur ou égal à 6.'
          laws:
            furnishing: 'La loi exige une possibilité de baignade, des perchoirs souples d’épaisseurs et d’orientations différentes ainsi que la mise à disposition de sable convenant à son absorption. Un tiers du volume doit être libre de structures, et dans les enclos inférieurs à 2 m², le rapport longueur/largeur, rapporté à la surface minimale, ne doit pas dépasser 2:1. Tous les psittaciformes doivent avoir suffisamment de branches naturelles à ronger et pour grimper.'
            socialStructure: 'Détention individuelle interdite'
            lawDetails: 'N’oubliez pas que les dimensions minimales légales ne correspondent qu’au strict minimum. La détention des oiseaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. La détention respectueuse des oiseaux nécessite des volières nettement plus grandes et un aménagement bien structuré (voir recommandations).'
          recommendations:
            furnishing: 'Branches fixées à une seule extrémité (= perchoirs souples), cordes variées, balançoires, plateformes suspendues au plafond, bain (petite baignoire ou récipient plat), pierres calcaires / os de sèche suspendus, grit dans une gamelle plate, lampes UV appropriées pour détention à l’intérieur, comme substrat: sable, gravier ou pellets de hêtre. Les barreaux de l’enclos doivent être horizontaux. Les cages rondes ne sont pas conformes à la protection des animaux et sont à proscrire.'
            socialStructure: 'Les amazones sont très sociables. La forme de détention la plus respectueuse de l’espèce est en groupe de couples nicheurs.'
            indoor: 'La détention exclusivement à l’intérieur ne convient pas aux amazones.'
            outdoor: 'a forme de détention la plus respectueuse des amazones est un enclos extérieur, composé d’une volière et d’un espace intérieur adjacent (p. ex. un abri de jardin) chauffable (à l’abri du gel). Une partie du toit de la volière doit être couverte (protection contre les intempéries) et disposer d’écrans visuels sur au moins deux côtés (végétation, filet brise-vent opaque, etc.).'
            specificNeeds: "Les amazones sont des oiseaux bruyants. Par conséquent, les voisins devraient êtres avertis.\n\nIl existe différentes espèces d’amazones, et toutes ne conviennent pas comme animaux de compagnie. La plus commune est l’amazone à front bleu. Chaque espèce a ses propres besoins spécifiques en termes de détention, de climat, d’alimentation et de structure sociale. Vous pouvez obtenir des informations auprès de spécialistes chevronnés ou suivre un cours spécialisé avant l’achat.\nPour des raisons de protection des animaux, il faut s’abstenir d’acheter des animaux élevés à la main."
          links:
            '0':
              text: 'Détenir des oiseaux en respectant leurs besoins - Enclos adaptés et aménagement adéquat'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_f_Voegel_A4_web.pdf'
      equidae:
        horse:
          name: 'Cheval'
          namePlural: 'Chevaux'
          minAnimalsWarningMessage: "Attention : les équidés doivent au moins avoir un contact visuel, auditif et olfactif avec d'autres équidés. Idéalement, avec un congénère de la même espèce, donc dans ce cas avec un autre cheval."
          maxAnimalsWarningMessage: "Pour cinq équidés ou plus qui s’entendent bien, la surface totale peut être réduite de 20 % au maximum, ce qui a été pris en compte dans les résultats. La PSA ne recommande pas cette réduction."
          recommendations:
            pasturing: 'Autant que possible'
            socialStructure: "Les chevaux doivent avoir un contact direct avec d'autres chevaux. Les chevaux et les ânes parlent un langage différent et ont des besoins différents en matière de détention et d'alimentation."
            activity: 'Tous les équidés devraient être occupés quotidiennement.'
          laws:
            runoutTime: 'Chevaux utilisés : 2 x 2 h / semaine ; Chevaux non utilisés : 2h par jour'
            pasturing: 'Aucune prescription'
            structuring: "Des aménagements permettant aux équidés de s’éviter ou de se retirer doivent être à disposition; de tels aménagements ne sont pas exigés pour les poulains sevrés et les jeunes équidés jusqu’au début de leur utilisation régulière, au plus tard cependant jusqu’à l’âge de 30 mois. Il ne doit pas y avoir de cul-de-sacs. Les équidés ne doivent pas être détenus à l'attache."
            exercise: "Les équidés doivent bénéficier d'un exercice quotidien suffisant. L'exercice comprend l'utilisation et la sortie. Lorsque les conditions météorologiques ou l’état du sol sont extrêmement défavorables, une surface couverte peut être exceptionnellement utilisée pour la sortie des équidés."
            activity: "Les équidés doivent disposer de suffisamment de fourrage grossier, tel que de la paille fourragère, pour l'occupation propre à leur espèce, sauf pendant le pâturage."
            socialStructure: "Les équidés doivent avoir un contact visuel, auditif et olfactif avec un autre équidé. Les équidés doivent être détenus en groupe après le sevrage jusqu'à l'âge de 30 mois ou jusqu'au début de leur utilisation régulière."
          links:
            '0':
              text: 'Site web de la PSA sur la détention des chevaux'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-de-chevaux/'
            '1':
              text: 'Ordonnance sur la protection des animaux'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/fr'
        pony:
          name: 'Poney'
          namePlural: 'Poneys'
          minAnimalsWarningMessage: "Attention : les équidés doivent au moins avoir un contact visuel, auditif et olfactif avec d'autres équidés. Idéalement, avec un congénère de la même espèce, donc dans ce cas avec un autre poney ou un autre cheval."
          maxAnimalsWarningMessage: '"Pour cinq équidés ou plus qui s’entendent bien, la surface totale peut être réduite de 20 % au maximum, ce qui a été pris en compte dans les résultats. La PSA ne recommande pas cette réduction.'
          recommendations:
            pasturing: "Autant que possible, adapter toutefois l'alimentation à l'état d’embonpoint !"
            socialStructure: "Les poneys doivent avoir un contact direct avec d'autres poneys ou d’autres chevaux."
            activity: 'Tous les équidés devraient être occupés quotidiennement.'
            specialRequirements: "La jeune herbe, riche en protéines ou en fructanes, représente un risque accru de surpoids, en particulier chez les poneys, et surtout s'ils ne font pas suffisamment d'exercice."
          laws:
            runoutTime: 'Poneys utilisés 2 x 2 h / semaine ; Poneys non utilisés 2h par jour'
            pasturing: 'Aucune prescription'
            structuring: "Des aménagements permettant aux équidés de s’éviter ou de se retirer doivent être à disposition de tels aménagements ne sont pas exigés pour les poulains sevrés et les jeunes équidés jusqu’au début de leur utilisation régulière, au plus tard cependant jusqu’à l’âge de 30 mois. Il ne doit pas y avoir de cul-de-sacs. Les équidés ne doivent pas être détenus à l'attache."
            exercise: "Les équidés doivent bénéficier d'un exercice quotidien suffisant. L'exercice comprend l'utilisation et la sortie. Lorsque les conditions météorologiques ou l’état du sol sont extrêmement défavorables, une surface couverte peut être exceptionnellement utilisée pour la sortie des équidés."
            activity: "Les équidés doivent disposer de suffisamment de fourrage grossier, tel que de la paille fourragère, pour l'occupation propre à leur espèce, sauf pendant le pâturage."
            socialStructure: "Les équidés doivent avoir un contact visuel, auditif et olfactif avec un autre équidé. Les équidés doivent être détenus en groupe après le sevrage jusqu'à l'âge de 30 mois ou jusqu'au début de leur utilisation régulière."
          links:
            '0':
              text: 'Site web de la PSA sur la détention des chevaux'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-de-chevaux/'
            '1':
              text: 'Ordonnance sur la protection des animaux'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/fr'
        donkey:
          name: 'Âne'
          namePlural: 'Ânes'
          minAnimalsWarningMessage: "Attention : les équidés doivent au moins avoir un contact visuel, auditif et olfactif avec d'autres équidés. Idéalement, avec un congénère de la même espèce, donc dans ce cas avec un autre âne."
          maxAnimalsWarningMessage: 'Pour cinq équidés ou plus qui s’entendent bien, la surface totale peut être réduite de 20 % au maximum, ce qui a été pris en compte dans les résultats. La PSA ne recommande pas cette réduction.'
          recommendations:
            pasturing: 'Pâturage limité pour les ânes, plus de mouvement sur de grandes places sèches'
            socialStructure: "Les ânes ont besoin d'un contact direct avec des congénères de la même espèce, c'est-à-dire avec d'autres ânes. Leur comportement et leur physiologie diffèrent de ceux des chevaux. Des aliments riches en fibres, comme des branches et de la paille, ainsi que des activités supplémentaires sont à prévoir."
            activity: 'Tous les équidés devraient être occupés quotidiennement.'
            specialRequirements: "Les ânes sont des transformateurs de fourrage très efficaces. Ils ont besoin d'une alimentation pauvre en énergie, à faible teneur en sucre et en protéines, mais riche en fibres. Le pelage des ânes n'est guère imperméable. Les ânes sont sensibles aux maladies lorsqu'ils sont mouillés. Ils ont besoin d'un abri sec accessible en permanence. Les ânes ont besoin d'un sol sec, sinon ils risquent des problèmes de sabots."
          laws:
            runoutTime: 'Ânes utilisés : 2 x 2 h / semaine ; Ânes non utilisés : 2h par jour'
            pasturing: 'Aucune prescription'
            structuring: "Des aménagements permettant aux équidés de s’éviter ou de se retirer doivent être à disposition de tels aménagements ne sont pas exigés pour les poulains sevrés et les jeunes équidés jusqu’au début de leur utilisation régulière, au plus tard cependant jusqu’à l’âge de 30 mois. Il ne doit pas y avoir de cul-de-sacs. Les équidés ne doivent pas être détenus à l'attache."
            exercise: "Les équidés doivent bénéficier d'un exercice quotidien suffisant. L'exercice comprend l'utilisation et la sortie. Lorsque les conditions météorologiques ou l’état du sol sont extrêmement défavorables, une surface couverte peut être exceptionnellement utilisée pour la sortie des équidés."
            activity: "Les équidés doivent disposer de suffisamment de fourrage grossier, tel que de la paille fourragère, pour l'occupation propre à leur espèce, sauf pendant le pâturage."
            socialStructure: "Les équidés doivent avoir un contact visuel, auditif et olfactif avec un autre équidé. Les équidés doivent être détenus en groupe après le sevrage jusqu'à l'âge de 30 mois ou jusqu'au début de leur utilisation régulière."
          links:
            '0':
              text: 'Site web de la PSA sur la détention des ânes'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-danes/'
            '1':
              text: 'Ordonnance sur la protection des animaux'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/fr'
        mule:
          name: 'Mulet'
          namePlural: 'Mulets'
          minAnimalsWarningMessage: "Attention : les équidés doivent au moins avoir un contact visuel, auditif et olfactif avec d'autres équidés. Idéalement, avec un autre mulet."
          maxAnimalsWarningMessage: 'Pour cinq équidés ou plus qui s’entendent bien, la surface totale peut être réduite de 20 % au maximum, ce qui a été pris en compte dans les résultats. La PSA ne recommande pas cette réduction.'
          recommendations:
            pasturing: 'Pâturage limité'
            socialStructure: "Les mulets ont besoin de contact avec d'autres mulets. Ils peuvent bien entendu être détenues avec des chevaux. Il faut toutefois tenir compte de leur physiologie en ce qui concerne l'alimentation et l’accès au pâturage."
            activity: 'Tous les équidés devraient être occupés quotidiennement.'
          laws:
            runoutTime: 'Mulets utilisés 2 x 2 h / semaine ; Mulets non utilisés 2h par jour'
            pasturing: 'Aucune prescription'
            structuring: "Des aménagements permettant aux équidés de s’éviter ou de se retirer doivent être à disposition de tels aménagements ne sont pas exigés pour les poulains sevrés et les jeunes équidés jusqu’au début de leur utilisation régulière, au plus tard cependant jusqu’à l’âge de 30 mois. Il ne doit pas y avoir de cul-de-sacs. Les équidés ne doivent pas être détenus à l'attache."
            exercise: "Les équidés doivent bénéficier d'un exercice quotidien suffisant. L'exercice comprend l'utilisation et la sortie. Lorsque les conditions météorologiques ou l’état du sol sont extrêmement défavorables, une surface couverte peut être exceptionnellement utilisée pour la sortie des équidés."
            activity: "Les équidés doivent disposer de suffisamment de fourrage grossier, tel que de la paille fourragère, pour l'occupation propre à leur espèce, sauf pendant le pâturage."
            socialStructure: "Les équidés doivent avoir un contact visuel, auditif et olfactif avec un autre équidé. Les équidés doivent être détenus en groupe après le sevrage jusqu'à l'âge de 30 mois ou jusqu'au début de leur utilisation régulière."
          links:
            '0':
              text: 'Site web de la PSA sur la détention des chevaux'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-de-chevaux/'
            '1':
              text: 'Site web de la PSA sur la détention des ânes'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-danes/'
            '2':
              text: 'Ordonnance sur la protection des animaux'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/fr'
        hinny:
          name: 'Bardot'
          namePlural: 'Bardots'
          minAnimalsWarningMessage: "Attention : les équidés doivent au moins avoir un contact visuel, auditif et olfactif avec d'autres équidés. Idéalement avec un autre bardot."
          maxAnimalsWarningMessage: 'Pour cinq équidés ou plus qui s’entendent bien, la surface totale peut être réduite de 20 % au maximum, ce qui a été pris en compte dans les résultats. La PSA ne recommande pas cette réduction.'
          recommendations:
            pasturing: 'Pâturage limité'
            socialStructure: "Les bardots ont besoin de contact direct avec d’autres bardots. Ils peuvent aussi être détenus avec des ânes. Il faut toutefois tenir compte de leur physiologie en ce qui concerne l'alimentation et l’accès au pâturage."
            activity: 'Tous les équidés devraient être occupés quotidiennement.'
          laws:
            runoutTime: 'Bardots utilisés 2 x 2 h / semaine ; Bardots non utilisés 2h par jour'
            pasturing: 'Aucune prescription'
            structuring: "Des aménagements permettant aux équidés de s’éviter ou de se retirer doivent être à disposition de tels aménagements ne sont pas exigés pour les poulains sevrés et les jeunes équidés jusqu’au début de leur utilisation régulière, au plus tard cependant jusqu’à l’âge de 30 mois. Il ne doit pas y avoir de cul-de-sacs. Les équidés ne doivent pas être détenus à l'attache."
            exercise: "Les équidés doivent bénéficier d'un exercice quotidien suffisant. L'exercice comprend l'utilisation et la sortie. Lorsque les conditions météorologiques ou l’état du sol sont extrêmement défavorables, une surface couverte peut être exceptionnellement utilisée pour la sortie des équidés."
            activity: "Les équidés doivent disposer de suffisamment de fourrage grossier, tel que de la paille fourragère, pour l'occupation propre à leur espèce, sauf pendant le pâturage."
            socialStructure: "Les équidés doivent avoir un contact visuel, auditif et olfactif avec un autre équidé. Les équidés doivent être détenus en groupe après le sevrage jusqu'à l'âge de 30 mois ou jusqu'au début de leur utilisation régulière."
          links:
            '0':
              text: 'Site web de la PSA sur la détention des chevaux'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-de-chevaux/'
            '1':
              text: 'Site web de la PSA sur la détention des ânes'
              file: 'https://tierschutz.com/fr/detention-des-animaux/detention-danimaux-de-compagnie/detention-danes/'
            '2':
              text: 'Ordonnance sur la protection des animaux'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/fr'
    screenCategory:
      title: 'Sélectionner la catégorie'
    screenAnimal:
      title: 'Quel animal voudrais-tu détenir?'
      multipleBirdsButton: 'Garder plusieurs espèces ensemble'
      equidaeInfo: "Les valeurs calculées ne sont valables que pour les équidés de plus de 30 mois."
    screenMultipleBirds:
      warning: 'Pour détenir deux espèces d''oiseaux ou plus ensemble, la taille de l''enclos doit être calculée séparément pour chaque espèce, mais avec le nombre total d''oiseaux. Le plus grand enclos calculé correspond aux dimensions minimales légales.'
      infoText: 'Si deux espèces d''oiseaux ou plus sont détenues ensemble dans un enclos, la taille minimale légale de l''enclos correspond toujours aux exigences en matière de place pour la plus grande espèce d''oiseaux. Par exemple, si deux perruches ondulées et deux perruches nymphes sont détenues ensemble dans un enclos, la taille minimale de l''enclos correspond aux exigences pour quatre perruches nymphes, car il s''agit de la plus grande espèce d''oiseaux dans l''enclos. La détention commune de plusieurs espèces d''oiseaux ne convient qu''aux détenteurs d''oiseaux expérimentés. Informez-vous toujours soigneusement sur les espèces d''oiseaux qui peuvent être détenues ensemble.'
    screenNumber:
      title: 'Combien de {animals} aimerais-tu détenir?'
    screenWeight:
      title: 'Quel est le poids des {animals}?'
    screenLength:
      title: 'Quelle est la longueur du / de la / de l’{animal} le/la plus grand/e?'
      error: 'Entrer un nombre entre {min} et {max}.'
    screenWithersHeight:
      title: 'Quelle est la hauteur au garrot de chaque animal ?'
      error: 'Entrer un nombre entre {min} et {max}.'
      animal: 'Animal'
    screenResults:
      title: 'Tu devrais détenir {number} {animals} ainsi'
      example: 'Par exemple'
      exampleEquidae: 'Exemple de taille de box'
      stsRecommendation: 'Recommandation Protection des animaux'
      law: 'Loi (Ordonnance suisse sur la protection des animaux)'
      stsAreaRecommendation: 'Surface minimale (dimensions intérieures)'
      stsLengthRecommendation:
        upto2reptiles: 'Longueur minimale (dimensions intérieures)'
        morethan2reptiles: 'En plus, au moins en longueur'
      stsWidthRecommendation:
        upto2reptiles: 'Largeur minimale (dimensions intérieures)'
        morethan2reptiles: 'En plus, au moins en largeur'
      stsHeightRecommendation: 'Hauteur minimale de l’enclos (dimensions intérieures)'
      stsBeddingRecommendation: 'Profondeur minimale de la litière'
      stsWaterBasinRecommendation: 'Bassin d''eau'
      tolerance: 'Valeur de tolérance'
      toleranceInfo: 'Les écuries existant le 1er septembre 2008 ne doivent pas être adaptées si leurs dimensions correspondent aux valeurs de tolérance. Si une écurie doit être adaptée parce que l’une des valeurs de tolérance n’est pas respectée, l’autre valeur de tolérance reste valable.'
      noTolerance: 'Pas de valeur de tolérance'
      stsLayingAreaRecommendation: 'Surface de repos'
      stsClearWidthRecommendation: 'Largeur intérieure'
      stsClearWidthRecommendationInfo: '2 x taille au garrot de l’animal le plus grand'
      stsClearHeightRecommendation: 'Hauteur intérieure'
      stsRunoutTimeRecommendation: 'Temps de sortie'
      stsRunoutTimeRecommendationText: "De préférence libre accès 24h/24 à l'aire de sortie tout temps mais au moins 4 heures par jour"
      stsRunoutAreaRecommendation: "Surface de l'aire de sortie"
      stsPasturingRecommendation: 'Pâturage'
      stsLatticeSpacing: 'Distance maximale entre les barreaux'
      lawMinimalArea: 'Surface minimale (dimensions intérieures)'
      lawMinimalLength: 'Longueur minimale (dimensions intérieures)'
      lawMinimalWidth: 'Largeur minimale (dimensions intérieures)'
      lawMinimalHeight: 'Hauteur minimale de l’enclos (dimensions intérieures)'
      lawMinimalBedding: 'Profondeur minimale de la litière'
      lawWaterBasin: 'Bassin d''eau'
      lawReptilesInfo:
        text: 'Lors de la détention du nombre d''animaux entré, il faut s''assurer que toutes les exigences légales {link} sont remplies pour l''espèce animale concernée.'
        linkText: 'selon l''annexe 2, tableau 5 OPAn'
        linkURL: 'https://www.fedlex.admin.ch/eli/cc/2008/416/fr'
      lawVolume: 'Volume'
      lawLayingArea: 'Surface de repos en stabulation libre à un compartiment'
      layingAreaInfo: "Aires de repos non séparées de l'aire d'alimentation ; pour les autres conditions, voir l'ordonnance sur la protection des animaux, tableau 7."
      lawLayingAreaMultiroom: 'Surface de repos en stabulation libre à plusieurs compartiments'
      layingAreaMultiroomInfo: "Aires de repos séparées de l'aire d'alimentation ; pour les autres conditions, voir l'ordonnance sur la protection des animaux, tableau 7."
      lawClearWidth: 'Largeur intérieure '
      lawClearWidthInfo: '1,5 x taille au garrot de l’animal le plus grand '
      lawClearHeight: 'Hauteur intérieure'
      lawRunoutTime: 'Temps de sortie'
      lawRunoutAreaAdjacent: "Aire de sortie attenante à l'écurie"
      lawRunoutAreaNonadjacent: "Aire de sortie non attenante à l'écurie"
      lawPasturing: 'Pâturage'
      additionalRecommendations:
        title: 'Autres recommandations'
        furnishing: 'Aménagement'
        socialStructure: 'Structure sociale et besoins spécifiques à l’espèce'
        indoor: 'Détention à l''intérieur'
        outdoor: 'Détention à l''extérieur'
        specificNeeds: 'Besoins spécifiques à l''espèce'
        specialRequirements: 'Exigences'
        activity: 'Mouvement'
        more: 'Autres'
      additionalLaws:
        title: 'Autres dispositions légales'
        furnishing: 'Aménagement'
        raisedAreas: 'Plateformes surélevées'
        socialStructure: 'Structure sociale'
        structuring: "Structuration de l'espace"
        activity: 'Mouvement'
        exercise: 'Occupation'
      lawDetails: "Veuillez noter que les prescriptions légales minimales ne correspondent qu’au strict minimum. La détention des animaux dans des cages plus petites est interdite en Suisse et constitue, du point de vue de la PSA, de la maltraitance animale. \nPour détenir les animaux dans des conditions conformes à leurs besoins, il faut des surfaces nettement plus spacieuses et des aménagements bien structurés (voir recommandations).\n"
      additionalInfo: 'Pour plus d’informations'
it_CH:
  txt:
    title: 'Calcolatore per la detenzione di animali'
    buttonNext: 'Continua'
    buttonPrevious: 'Indietro'
    buttonClose: 'Chiudere'
    buttonAcceptWarning: 'Compreso'
    domain: 'calcolatore-detenzione-animali.ch'
    link: 'https://www.calcolatore-detenzione-animali.ch'
    trButton:
      cta: 'Per saperne di più sulla detezione adeguata alla specie:'
      buttonText: 'Aprire il calcolatore per la detenzione di animali'
    categories:
      rodents:
        name: 'Roditori e conigli'
      reptiles:
        name: 'Rettili'
      birds:
        name: 'Uccelli'
      equidae:
        name: 'Equidi'
    animals:
      rodents:
        degu:
          name: 'Degu'
          namePlural: 'Degu'
          minAnimalsErrorMessage: 'I degu sono animali sociali. La detenzione individuale è quindi vietata!'
          maxAnimalsWarningMessage: 'La dimensione massima del gruppo è stata superata. I degu dovrebbero essere detenuti in gruppi di 2-5 animali.'
          maxAnimalsErrorMessage: 'Con questa dimensione dei gruppi non è più possibile una detenzione rispettosa degli animali in un recinto! I degu dovrebbero essere detenuti in gruppi di 2-5 animali.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 2 - 5 animali\nComposizione del gruppo: gruppo di sole femmine, gruppo di soli maschi, un maschio castrato con più femmine\n"
          laws:
            furnishing: "Lettiera adatta per scavare, possibilità di rifugiarsi per tutti gli animali, foraggio grezzo (fieno o paglia), oggetti da rosicchiare come rami freschi, bagno di sabbia\n"
            raisedAreas: "Le superfici rialzate non possono essere conteggiate per la superficie minima.\n"
            socialStructure: "I degu sono animali sociali e non possono essere detenuti senza loro simili.\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Degu comune'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_degu_it.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        dwarfHamster:
          name: 'Criceto nano'
          namePlural: 'Criceti nani'
          minAnimalsWarningMessage: 'Attenzione, la detenzione dei criceti nani è molto impegnativa e possibile solamente per detentori di roditori esperti.'
          maxAnimalsErrorMessage: 'Con questa dimensione dei gruppi non è più possibile una detenzione rispettosa degli animali in un recinto!'
          recommendations:
            socialStructure: "La detenzione di criceti nani è impegnativa e richiede molta esperienza. Prima di acquistare, informarsi presso degli esperti in merito a quali specie possono essere detenute in quali dimensioni del gruppo e con quale composizioni\n"
          laws:
            furnishing: "Possibilità di arrampicarsi, lettiera adatta per scavare, possibilità di rifugiarsi per tutti gli animali, materiale adatto alla preparazione del nido, foraggio grezzo (fieno, paglia, miscele di semi), oggetti da rosicchiare come rami freschi\n"
            raisedAreas: "Le superfici rialzate non possono essere conteggiate per la superficie minima.\n"
            socialStructure: "Per i criceti nani è consentita sia la detenzione individuale sia in gruppo.\n"
          links:
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        fancyMouse:
          name: 'Topo domestico'
          namePlural: 'Topi domestici'
          minAnimalsErrorMessage: 'I topi domestici sono animali sociali. La detenzione individuale è quindi vietata!'
          maxAnimalsWarningMessage: 'La dimensione massima del gruppo è stata superata. I topi domestici dovrebbero essere detenuti in gruppi di 2-6 animali.'
          maxAnimalsErrorMessage: 'Eine tiergerechte Haltung in einem Gehege ist mit dieser Gruppengrösse nicht mehr möglich! Farbmäuse sollten in Gruppen von 2-6 Tieren gehalten werden.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 2 - 6 animali\nComposizione del gruppo: gruppo di sole femmine, un maschio castrato con più femmine\n"
          laws:
            furnishing: "Possibilità di arrampicarsi, lettiera adatta, possibilità di rifugiarsi per tutti gli animali, materiale adatto alla preparazione del nido, foraggio grezzo (fieno, paglia, miscele di semi), oggetti da rosicchiare come rami freschi\n"
            raisedAreas: "Le superfici rialzate non possono essere conteggiate per la superficie minima.\n"
            socialStructure: "I topi domestici sono animali sociali e non possono essere detenuti senza loro simili.\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Topo domestico'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_farbm%C3%A4use_it.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        gerbil:
          name: 'Gerbillino'
          namePlural: 'Gerbillini'
          minAnimalsErrorMessage: 'I gerbillini sono animali sociali. La detenzione individuale è quindi vietata!'
          maxAnimalsWarningMessage: 'La dimensione massima del gruppo è stata superata. I gerbillini dovrebbero essere detenuti in gruppi di 2 animali.'
          maxAnimalsErrorMessage: 'Con questa dimensione dei gruppi non è più possibile una detenzione rispettosa degli animali in un recinto! I gerbillini dovrebbero essere detenuti in gruppi di 2 animali.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 2 animali,\nComposizione del gruppo: gruppo di sole femmine, gruppo di soli maschi, un maschio castrato con una femmina\n"
          laws:
            furnishing: "Lettiera adatta per scavare, possibilità di rifugiarsi per tutti gli animali, materiale per il nido adeguato, foraggio grezzo (fieno o paglia), bagno di sabbia, oggetti da rosicchiare (rami freschi)\n"
            raisedAreas: "Le superfici rialzate non possono essere conteggiate per la superficie minima.\n"
            socialStructure: "I gerbillini sono animali sociali e non possono essere detenuti senza loro simili.\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Mongolische Rennmäuse'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_rennmaus_de.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        goldenHamster:
          name: 'Criceto dorato'
          namePlural: 'Criceti dorati'
          maxAnimalsErrorMessage: 'I criceti dorati sono animali solitari e devono essere detenuti da soli.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 1 animale\n"
          laws:
            furnishing: "Possibilità di arrampicarsi, lettiera adatta per scavare, possibilità di rifugiarsi per tutti gli animali, materiale adatto alla preparazione del nido, foraggio grezzo (fieno, paglia, miscele di semi), oggetti da rosicchiare come rami freschi\n"
            raisedAreas: "Le superfici rialzate non possono essere conteggiate per la superficie minima.\n"
            socialStructure: "I criceti dorati sono animali solitari e devono essere detenuti da soli.\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Il criceto dorato siriano'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_kaninchen_it.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        guineaPig:
          name: 'Porcellino d''India'
          namePlural: 'porcellini d''India'
          minAnimalsErrorMessage: 'I porcellini d''India sono animali sociali. La detenzione individuale è quindi vietata!'
          maxAnimalsWarningMessage: 'La dimensione massima del gruppo è stata superata. I porcellini d''India dovrebbero essere tenuti in gruppi di 2-6 animali.'
          maxAnimalsErrorMessage: 'Con questa dimensione dei gruppi non è più possibile una detenzione rispettosa degli animali in un recinto! I porcellini d''India dovrebbero essere tenuti in gruppi di 2-6 animali.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 2 - 6 animali\nComposizione del gruppo: gruppo di sole femmine oppure un maschio castrato con più femmine, in parte possibili anche gruppi di soli maschi\n"
          laws:
            furnishing: "Lettiera adatta, possibilità di rifugiarsi per tutti gli animali, oggetti da rosicchiare come rami freschi, foraggio grezzo (fieno o paglia), alimenti contenenti vitamina C\n"
            raisedAreas: "Le superfici rialzate calpestabili possono essere conteggiate fino a un terzo per la superficie minima richiesta, a condizione che l'area sotto il livello rialzato possa essere utilizzata anche dagli animali.\n"
            socialStructure: "I porcellini d'India sono animali sociali e non possono essere tenuti senza loro simili.\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Porcellini d’India – Cavia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_meerschweinchen_it.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        rabbit:
          name: 'Coniglio'
          namePlural: 'Conigli'
          minAnimalsErrorMessage: 'I conigli sono animali sociali. La detenzione individuale è consentita solo se gli animali hanno un contatto uditivo o olfattivo con i loro simili!'
          maxAnimalsWarningMessage: 'A partire da un gruppo di 3 animali, attualmente esistono solo delle raccomandazioni per quanto concerne la superficie minima. Le superfici ad oggi raccomandate sono piuttosto piccole e attualmente sono in fase di revisione.'
          maxAnimalsErrorMessage: 'Con questa dimensione dei gruppi non è più possibile una detenzione rispettosa degli animali in un recinto! I conigli dovrebbero essere detenuti in gruppi di 2-6 animali.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 2 - 6 animali\nComposizione del gruppo: un maschio castrato con 1 o 2 femmine, 2 maschi castrati con 2 - 4 femmine, in parte possibili anche gruppi di sole femmine\n"
          laws:
            furnishing: "Possibilità di rifugiarsi per tutti gli animali, oggetti da rosicchiare come rami freschi, foraggio grezzo (fieno o paglia)\n"
            raisedAreas: "Le superfici rialzate calpestabili possono essere conteggiate fino a un terzo per la superficie minima richiesta, a condizione che l'area sotto il livello rialzato possa essere utilizzata anche dagli animali.\n"
            socialStructure: "I conigli sono animali sociali. La detenzione individuale è consentita solo se gli animali hanno un contatto uditivo o olfattivo con i loro simili!\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Coniglio'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_kaninchen_it.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
        rat:
          name: 'Ratto'
          namePlural: 'Ratti'
          minAnimalsErrorMessage: 'I ratti sono animali sociali. La detenzione individuale è quindi vietata!'
          maxAnimalsWarningMessage: 'La dimensione massima del gruppo è stata superata. I ratti dovrebbero essere detenuti in gruppi di 2-6 animali.'
          maxAnimalsErrorMessage: 'Con questa dimensione dei gruppi non è più possibile una detenzione rispettosa degli animali in un recinto! I ratti dovrebbero essere detenuti in gruppi di 2-6 animali.'
          recommendations:
            socialStructure: "Dimensione del gruppo: 2 - 6 animali\nComposizione del gruppo: gruppo di soli maschi, gruppo di sole femmine, gruppo misto con maschi castrati\n"
          laws:
            furnishing: "Lettiera adatta, possibilità di rifugiarsi per tutti gli animali, materiale adatto alla preparazione del nido, foraggio grezzo (fieno o paglia), oggetti da rosicchiare come rami freschi\n"
            raisedAreas: "Le superfici rialzate non possono essere conteggiate per la superficie minima.\n"
            socialStructure: "I ratti sono animali sociali e non possono essere detenuti senza loro simili.\n"
          links:
            '0':
              text: 'Foglio informativo PSA: Ratto'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/nager_kaninchen/mb_ratten_it.pdf'
            '1':
              text: 'Roditori e Conigli - Detenzione Adeguata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Nager_A4_web.pdf'
            '3':
              text: 'Foglio Informativo PSA Sortiment Zoofachhandlungen und Onlineshops'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/mb_sortiment_zoofachhandel.pdf'
            '4':
              text: 'Check-list Negozi zoologici'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/flyer_Zoofachhandel_it.pdf'
      reptiles:
        royalPython:
          name: 'Pitone reale'
          namePlural: 'Pitoni reali'
          textLength: 'Per i serpenti, si misura la lunghezza totale del corpo dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei pitoni reali. I pitoni reali sono adatti a socializzare con i conspecifici.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei pitoni reali. Un numero così elevato di animali non è raccomandabile.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei pitoni reali. Un numero così elevato di animali deve essere respinto.'
          laws:
            furnishing: "Possibilità di fare il bagno, nascondigli, condizioni climatiche e di temperatura adatte alla specie, possibilità di arrampicarsi\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: 'sì'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, l'illuminazione deve essere installata in modo tale che gli animali non possano scottarsi, possibilità di arrampicarsi, sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritiro quanti sono gli animali, ad esempio tubi di sughero, radici, rami e aree rialzate, wetbox, vasca d'acqua, acqua e cibo adatto alla specie (animali da mangiare)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 26-32°C, spot di calore locale 36°C, raffreddamento notturno, i pitoni reali sono abbastanza bene compatibili tra loro e possono essere tenuti anche in coppia o a tre se il terrario è abbastanza grande.\n"
            waterBasin: 'Vasca d''acqua'
          links:
            '0':
              text: 'Foglio informativo Pitone reale'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_k%C3%B6nigspython_it.pdf'
            '1':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '2':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        cornSnake:
          name: 'Serpente del grano'
          namePlural: 'Serpenti del grano'
          textLength: 'Per i serpenti, si misura la lunghezza totale del corpo dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei serpenti del grano. I serpenti del grano sono adatti a socializzare con i conspecifici.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei serpenti del grano. Un numero così elevato di animali non è raccomandabile.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei serpenti del grano. Un numero così elevato di animali deve essere respinto.'
          laws:
            furnishing: "Condizioni climatiche e di temperatura adatte alla specie, nascondigli, possibilità di arrampicarsi\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, l'illuminazione deve essere installata in modo che gli animali non possano bruciarsi, vasche d'acqua, possibilità di arrampicarsi, substrato di fondo sciolto, sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritiro quanti sono gli animali, ad esempio tubi di sughero, radici, rami, wetbox, acqua e cibo adatto alla specie (animali da mangiare)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 24-28°C, spot di calore locale 35°C, raffreddamento notturno, a seconda del clima di origine si consiglia uno svernamento al fresco.\n"
            waterBasin: 'Vasca d''acqua'
          links:
            '0':
              text: 'Foglio informativo Serpe del grano'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_kornnatter_it.pdf'
            '1':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '2':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        greekTortoise:
          name: 'Testuggine greca'
          namePlural: 'Testuggini greche'
          textLength: 'Per le testuggini o tartarughe, si misura la lunghezza del carapace dell''animale più grande.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle testuggini. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle testuggini. Un numero così elevato di animali non è raccomandabile. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale delle testuggini. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Nascondigli e terreno su cui scavare, punt di sole e lampada a emissione UV, accesso alla luce naturale del sole, ulteriore uscita all'aperto, possibilità di svernare\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Recinto esterno con recinzione a prova di fuga e possibilità di separazione, casa riparo, ad esempio serra, letto caldo, substrato del terreno vario e parzialmente scavabile (sassi, sabbia, ghiaia, calcare giurassico, ecc.), aree per prendere il sole, possibilità di ritirarsi, ad esempio con piante, radici, pietre, ecc., acqua e mangime adatto alla specie (fieno, erbe)\n"
            socialStructure: "È necessario tenere conto dei requisiti specifici della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, lo svernamento, la progettazione dello recinto e della mangiatoia. È importante disporre di una struttura di svernamento sicura.\n"
            more: "Le testuggini hanno un'aspettativa di vita molto elevata (40-80 anni)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Informazioni di base sulla detenzione di testuggini europee'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Landschildkro%CC%88ten_it.pdf'
            '1':
              text: 'Detenzione di tartarughe rispettosa degli animali nella pratica'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_it.pdf'
            '2':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '3':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        moorishTortoise:
          name: 'Testuggine moresca'
          namePlural: 'Testuggini moresche'
          textLength: 'Per le testuggini o tartarughe, si misura la lunghezza del carapace dell''animale più grande.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle testuggini. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle testuggini. Un numero così elevato di animali non è raccomandabile. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale delle testuggini. Un numero così elevato di animali deve essere respinto.'
          laws:
            furnishing: "Nascondigli e terreno su cui scavare, punt di sole e lampada a emissione UV, accesso alla luce naturale del sole, ulteriore uscita all'aperto, possibilità di svernare\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Recinto esterno con recinzione a prova di fuga e possibilità di separazione, casa riparo, ad esempio serra, letto caldo, substrato del terreno vario e parzialmente scavabile (sassi, sabbia, ghiaia, calcare giurassico, ecc.), aree per prendere il sole, possibilità di ritirarsi, ad esempio con piante, radici, pietre, ecc., acqua e mangime adatto alla specie (fieno, erbe)\n"
            socialStructure: "È necessario tenere conto dei requisiti specifici della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, lo svernamento, la progettazione dello recinto e della mangiatoia. È importante disporre di una struttura di svernamento sicura.\n"
            more: "Le testuggini hanno un'aspettativa di vita molto elevata (40-80 anni)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Informazioni di base sulla detenzione di testuggini europee'
              file: 'http://www.protezione-animali.com/pubblicazioni/animali_da_compagnia/infothek/reptilien/mb_landschildkroeten.pdf'
            '1':
              text: 'Detenzione di tartarughe rispettosa degli animali nella pratica'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_it.pdf'
            '2':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '3':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        wideShelledTortoise:
          name: 'Testuggine marginata'
          namePlural: 'Testuggini marginate'
          textLength: 'Per le testuggini o tartarughe, si misura la lunghezza del carapace dell''animale più grande.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle testuggini. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle testuggini. Un numero così elevato di animali non è raccomandabile. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale delle testuggini. Un numero così elevato di animali deve essere respinto.'
          laws:
            furnishing: "Nascondigli e terreno su cui scavare, punt di sole e lampada a emissione UV, accesso alla luce naturale del sole, ulteriore uscita all'aperto, possibilità di svernare\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Recinto esterno con recinzione a prova di fuga e possibilità di separazione, casa riparo, ad esempio serra, letto caldo, substrato del terreno vario e parzialmente scavabile (sassi, sabbia, ghiaia, calcare giurassico, ecc.), aree per prendere il sole, possibilità di ritirarsi, ad esempio con piante, radici, pietre, ecc., acqua e mangime adatto alla specie (fieno, erbe)\n"
            socialStructure: "È necessario tenere conto dei requisiti specifici della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, lo svernamento, la progettazione dello recinto e della mangiatoia. È importante disporre di una struttura di svernamento sicura.\n"
            more: "Le testuggini hanno un'aspettativa di vita molto elevata (40-80 anni)\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Informazioni di base sulla detenzione di testuggini europee'
              file: 'http://www.protezione-animali.com/pubblicazioni/animali_da_compagnia/infothek/reptilien/mb_landschildkroeten.pdf'
            '1':
              text: 'Detenzione di tartarughe rispettosa degli animali nella pratica'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_schildkro%CC%88tenhaltung_praxis_it.pdf'
            '2':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '3':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        crownedGecko:
          name: 'Geco coronato'
          namePlural: 'Gechi coronati'
          textLength: 'Per le lucertole, si misura la lunghezza del corpo (senza coda) dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi coronati. I maschi sono incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi coronati. Un numero così elevato di animali non è raccomandabile. I maschi sono incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei gechi coronati. Un numero così elevato di animali deve essere respinto.'
          laws:
            furnishing: "Nascondigli, condizioni climatiche e di temperatura adatte alla specie, possibilità di arrampicarsi\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritirarsi quanti sono gli animali (ad esempio tubi di sughero, radici, piante), possibilità di arrampicarsi (ad esempio rami finemente ramificati, piante), substrato del terreno per scavare, acqua e cibo adatto alla specie (insetti)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 25-30°C, raffreddamento notturno. Temperature invernali inferiori di 5°-6°C. I maschi sono incompatibili tra loro, la loro socializzazione non deve essere prevista.\n"
            waterBasin: '-'
        links:
          '0':
            text: 'Reptilien - Passende Gehege und die richtige Einrichtung'
            file: 'https://www.tierschutz.com/publikationen/heimtiere/infothek/gehege/broschuere_gehege_reptilien.pdf'
          '1':
            text: 'Merkblatt Reptilien als Heimtiere'
            file: 'http://www.tierschutz.com/publikationen/heimtiere/infothek/reptilien/reptilien_heimtiere.pdf'
        boaConstrictor:
          name: 'Boa constrictor'
          namePlural: 'Boa constrictor'
          textLength: 'Per i serpenti, si misura la lunghezza totale del corpo dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei boa constrictor. I boa constrictor sono abbastanza compatibili tra loro e possono essere tenuti in coppia o a tre se il terrario è abbastanza grande.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei boa constrictor. Un numero così elevato di animali non è raccomandabile.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei boa constrictor. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Possibilità di fare il bagno, nascondigli, condizioni climatiche e di temperatura adatte alla specie, possibilità di arrampicarsi\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, l'illuminazione deve essere installata in modo tale che gli animali non possano scottarsi, possibilità di arrampicarsi, sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritiro quanti sono gli animali, ad esempio tubi di sughero, radici, rami e aree rialzate, wetbox, vasca d'acqua, acqua e cibo adatto alla specie (animali da mangiare)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 22-30°C, spot di calore locale 35°C, raffreddamento notturno, i boa constrictor sono abbastanza compatibili tra loro e possono essere tenuti anche in coppia o a tre se il terrario è abbastanza grande.\n"
            more: "I boa constrictor possono superare i 4 metri di lunghezza. I valori qui indicati si riferiscono ad animali di lunghezza fino a 250 cm. Gli animali più grandi hanno bisogno di recinti più grandi.\n"
            waterBasin: 'Vasca d''acqua 0.5 m²'
          links:
            '0':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '1':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        muskTurtle:
          name: 'Tartaruga muschiata comune'
          namePlural: 'Tartarughe muschiate comuni'
          textLength: 'Per le testuggini o tartarughe, si misura la lunghezza del carapace dell''animale più grande.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle tartarughe muschiate. I maschi sono incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale delle tartarughe muschiate. Un numero così elevato di animali non è raccomandabile. I maschi sono incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale delle tartarughe muschiate. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Condizioni climatiche e di temperatura, di illuminazione adatte alla specie, punt di sole e lampada a emissione UV, nascondigli\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: 'in aggiunta all’area'
          recommendations:
            furnishing: "Si raccomanda una sistemazione all'aperto in estate, lampade a emissione UV e illuminazione di base, l'illuminazione deve essere installata in modo che gli animali non possano scottarsi, sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di rifugiarsi quanti sono gli animali, ad esempio radici, rami, pietre, fogliame, parte di terreno facilmente accessibile, cibo adeguato alla specie (animali da pasto)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 25-28°C, spot di calore locale 35°C, raffreddamento notturno, si consiglia uno svernamento al fresco.\n"
            waterBasin: 'dell’area complessiva'
          links:
            '0':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '1':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        kingSnake:
          name: 'Serpente reale'
          namePlural: 'Serpenti reali'
          textLength: 'Per i serpenti, si misura la lunghezza totale del corpo dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei serpenti reali.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei serpenti reali. Un numero così elevato di animali non è raccomandabile.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei serpenti reali. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Condizioni climatiche e di temperatura adeguate alla specie, nascondigli\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, l'illuminazione deve essere installata in modo che gli animali non possano bruciarsi, vasche d'acqua, possibilità di arrampicarsi, substrato di fondo sciolto, sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritiro quanti sono gli animali, ad esempio tubi di sughero, radici, rami, wetbox, acqua e cibo adatto alla specie (animali da mangiare)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 25-30°C, spot di calore locale 35°C, raffreddamento notturno, a seconda del clima di origine si consiglia uno svernamento al fresco.\n"
            more: "I serpenti reali si nutrono anche di altri serpenti e non devono quindi essere socializzati con altre serpenti.\n"
            waterBasin: 'Vasca d''acqua'
          links:
            '0':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '1':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        madagascarDayGecko:
          name: 'Geco diurno gigante del Madagascar'
          namePlural: 'Gechi diurni giganti del Madagascar'
          textLength: 'Per le lucertole, si misura la lunghezza del corpo (senza coda) dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi diurni. I maschi sono incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi diurni. Un numero così elevato di animali non è raccomandabile. I maschi sono incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei gechi diurni. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Possibilità di arrampicarsi orizzontalmente e verticalmente, nascondigli, condizioni climatiche e di illuminazione adatte alla specie, punt di sole e lampada a emissione UV\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, l'illuminazione UV deve essere installata in modo che tutti gli animali abbiano accesso alla luce (collocazione adeguata e numero sufficiente di punti soleggiati), ma non possano scottarsi sulle lampade, sistema per produrre l'umidità necessaria alle rispettive specie, ad esempio nebulizzatore, impianto di irrigazione, bottiglia a spruzzo, piantumazione, cascata, ecc. Sistema di misurazione della temperatura e dell'umidità dell’aria, possibilità di arrampicata diversificate e adatte alla specie, spesse almeno quanto il corpo (parete di fondo, rami, bastoni di bambù, piante a foglia larga, ecc.), possibilità di ritiro adatte alla specie attraverso piante, radici, pietre, canne di bambù tagliate, ecc., substrato adatto alla specie, acqua e cibo adatto alla specie (insetti, nettare, polpa di frutta).\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 25-30°C, spot di calore locale 40°C, raffreddamento notturno. I maschi sono incompatibili tra loro, la loro socializzazione non deve essere prevista.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Foglio informativo Geco gigante del Madagascar'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_taggecko_de.pdf'
            '1':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '2':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        goldDustDayGecko:
          name: 'Geco diurno polvere d''oro'
          namePlural: 'Gechi diurni polvere d''oro'
          textLength: 'Per le lucertole, si misura la lunghezza del corpo (senza coda) dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi diurni. I maschi sono incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi diurni. Un numero così elevato di animali non è raccomandabile. I maschi sono incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei gechi diurni. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Possibilità di arrampicarsi orizzontalmente e verticalmente, nascondigli, condizioni climatiche e di illuminazione adatte alla specie, punt di sole e lampada a emissione UV\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base, l'illuminazione UV deve essere installata in modo che tutti gli animali abbiano accesso alla luce (collocazione adeguata e numero sufficiente di punti soleggiati), ma non possano bruciarsi sulle lampade, sistema per produrre l'umidità necessaria alle rispettive specie, ad esempio nebulizzatore, impianto di irrigazione, bottiglia a spruzzo, piantumazione, cascata ecc. Sistema di misurazione della temperatura e dell'umidità dell’aria, possibilità di arrampicata diversificate e adatte alla specie, spesse almeno quanto il corpo (parete di fondo, rami, bastoni di bambù, piante a foglia larga, ecc.), possibilità di ritiro adatte alla specie attraverso piante, radici, pietre, canne di bambù tagliate, ecc., substrato adatto alla specie, acqua e cibo adatto alla specie (insetti, nettare, polpa di frutta).\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie per quanto riguarda l'habitat, il comportamento sociale, il clima, l'allestimento del terrario e l'alimentazione. Temperatura di base 25-30°C, spot di calore locale 40°C, raffreddamento notturno. I maschi sono incompatibili tra loro, la loro socializzazione non deve essere prevista.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '1':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        leopardGecko:
          name: 'Geco leopardo'
          namePlural: 'Gechi leopardi'
          textLength: 'Per le lucertole, si misura la lunghezza del corpo (senza coda) dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi leopardo. I maschi sono incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei gechi leopardo. Un numero così elevato di animali non è raccomandabile. I maschi sono incompatibili tra loro.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei gechi leopardo. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Nascondigli, possibilità di scavare, condizioni climatiche e di illuminazione adatte alla specie.\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Lampade a emissione UV e illuminazione di base; sistema di misurazione per il controllo della temperatura e dell'umidità, almeno altrettante possibilità di rifugio per gli animali (ad esempio tubi di sughero, radici, almeno un nascondiglio leggermente umido), possibilità di arrampicarsi (ad esempio strutture in pietra, parete posteriore, radici), substrato per scavare, acqua e cibo adatto alla specie (insetti)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie in termini di habitat, comportamento sociale, clima, svernamento, allestimento del terrario e alimentazione. Temperatura di base 22-28°C, spot di calore locale 35°C, raffreddamento notturno. I maschi sono territoriali, la loro socializzazione è sconsigliata.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Foglio informativo Geco leopardo'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_leopardgecko_it.pdf'
            '1':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '2':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        dwarfBeardedDragon:
          name: 'Drago barbuto nano'
          namePlural: 'Draghi barbuti nani'
          textLength: 'Per le lucertole, si misura la lunghezza del corpo (senza coda) dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei draghi barbuti. I maschi sono di solito incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei draghi barbuti. I maschi sono incompatibili tra loro. Un numero così elevato di animali non è raccomandabile.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei draghi barbuti. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Possibilità di arrampicarsi e nascondersi, condizioni climatiche e di illuminazione adatte alla specie, Possibilità di arrampicarsi orizzontalmente e verticalmente, nascondigli, condizioni climatiche e di illuminazione adatte alla specie, punt di sole e lampada a emissione UV\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Aree di riposo rialzate, lampade a emissione UV e illuminazione di base, l'illuminazione UV deve essere installata in modo tale che tutti gli animali possano accedervi (collocazione adeguata e numero sufficiente di punti soleggiati), ma non possano scottarsi sulla lampada (il contatto diretto con la fonte luminosa non deve essere possibile), sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritiro quanti sono gli animali, ad esempio tubi di sughero, radici, possibilità di fare il bagno, possibilità di arrampicarsi e rocce, sabbia per scavare, acqua e cibo adatto alla specie (cibo fresco, insetti)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie in termini di habitat, comportamento sociale, clima, svernamento, allestimento del terrario e alimentazione. Temperatura di base 25-30°C, spot di calore locale 45°C, raffreddamento notturno, a seconda del clima di origine si consiglia uno svernamento al fresco. Anche se i draghi barbuti sono animali solitari al di fuori della stagione degli amori, la compagnia di conspecifici è un importante arricchimento delle opzioni comportamentali nel terrario, purché gli animali siano compatibili.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Foglio informativo Drago barbuto dalla testa striata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_bartagame_it.pdf'
            '1':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '2':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
        centralBeardedDragon:
          name: 'Drago barbuto dalla testa striata'
          namePlural: 'Draghi barbuti dalla testa striata'
          textLength: 'Per le lucertole, si misura la lunghezza del corpo (senza coda) dell''animale più lungo.'
          minAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei draghi barbuti. I maschi sono incompatibili tra loro.'
          maxAnimalsWarningMessage: 'Informatevi sulla struttura sociale dei draghi barbuti. I maschi sono incompatibili tra loro. Un numero così elevato di animali non è raccomandabile.'
          maxAnimalsErrorMessage: 'Informatevi sulla struttura sociale dei draghi barbuti. Un numero così elevato di animali deve essere respinto'
          laws:
            furnishing: "Possibilità di arrampicarsi e nascondersi, condizioni climatiche e di illuminazione adatte alla specie, Possibilità di arrampicarsi orizzontalmente e verticalmente, nascondigli, condizioni climatiche e di illuminazione adatte alla specie, punt di sole e lampada a emissione UV\n"
            socialStructure: "Rispettare la struttura sociale. In determinate circostanze gli animali devono essere detenuti singolarmente.\n"
            waterBasin: '-'
          recommendations:
            furnishing: "Aree di riposo rialzate, lampade a emissione UV e illuminazione di base, l'illuminazione UV deve essere installata in modo tale che tutti gli animali possano accedervi (collocazione adeguata e numero sufficiente di punti soleggiati), ma non possano scottarsi sulla lampada (il contatto diretto con la fonte luminosa non deve essere possibile), sistema di misurazione per il controllo della temperatura e dell'umidità, almeno tante possibilità di ritiro quanti sono gli animali, ad esempio tubi di sughero, radici, possibilità di fare il bagno, possibilità di arrampicarsi e rocce, sabbia per scavare, acqua e cibo adatto alla specie (cibo fresco, insetti)\n"
            socialStructure: "Occorre tenere conto delle esigenze specifiche della specie in termini di habitat, comportamento sociale, clima, svernamento, allestimento del terrario e alimentazione. Temperatura di base 25-30°C, spot di calore locale 45°C, raffreddamento notturno, a seconda del clima di origine si consiglia uno svernamento al fresco. Anche se i draghi barbuti sono animali solitari al di fuori della stagione degli amori, la compagnia di conspecifici è un importante arricchimento delle opzioni comportamentali nel terrario, purché gli animali siano compatibili.\n"
            waterBasin: '-'
          links:
            '0':
              text: 'Foglio informativo Drago barbuto dalla testa striata'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_bartagame_de.pdf'
            '1':
              text: 'Rettili: Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Reptilien_A4_web.pdf'
            '2':
              text: 'Foglio informativo Rettili come animali da compagnia'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/reptilien/mb_Reptilien_als_Heimtiere_it.pdf'
      birds:
        diamondDove:
          name: 'Tortora diamantina'
          namePlural: 'Tortore diamantine'
          minAnimalsErrorMessage: 'Le tortore diamantine sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più tortore diamantine insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'Le tortore diamantine sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppo con più coppie riproduttive.'
            indoor: 'Le tortore diamantine possono essere detenute in voliere di grandi dimensioni o in stanze per uccelli. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La variante di detenzione più rispettosa per le tortore diamantine è un parco esterno, costituito da una voliera e da un locale interna adiacente che può essere riscaldato (protetta dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "\"Le tortore diamantine sono generalmente molto pacifiche e si possono detenere con altre specie di uccelli pacifici se si dispone di un’esperienza adeguata. \nAlle tortore diamantine piace trascorrere molto tempo a terra durante il giorno, quindi è necessario prestare particolare attenzione a garantire che la voliera abbia un’ampia superficie sul fondo. Sono agili volatrici, quindi è importante assicurare anche che ci sia spazio sufficiente per il volo.\""
          links:
            '0':
              text: 'Uccelli - Detenzione adeguata - Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Voegel_A4_web.pdf'
        canary:
          name: 'Canarino'
          namePlural: 'Canarini'
          minAnimalsErrorMessage: 'I canarini sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più canarini insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I canarini sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'I canarini possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La variante di detenzione più rispettosa per i canarini è un parco esterno, costituito da una voliera e da un locale interno adiacente che può essere riscaldato (protetta dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "La detenzione comune insieme ad altre specie di uccelli è consigliata solo alle persone con molte conoscenze specialistiche.\nAttenzione: Per i canarini esistono delle razze d’allevamento (ad es. alcuni canarini di forma e posizione) che appartengono alle razze da tortura, quind si prega di non comprare questi animali"
          links:
            '0':
              text: 'Foglio Informativo PSA - Canarino'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_kanarien_it.pdf'
        zebraFinch:
          name: 'Diamante mandarino'
          namePlural: 'Diamanti mandarini'
          minAnimalsErrorMessage: 'I diamanti mandarini sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più diamanti mandarini insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I diamanti mandarini sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'I diamanti mandarini possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La variante di detenzione più rispettosa per i diamanti mandarini è un parco esterno, costituito da una voliera e da un locale interna adiacente che può essere riscaldato (minimo 12°C) (ad es. una casetta da giardino).  La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.). Attenzione: Nella stagione invernale è necessaria la detenzione all’interno!'
            specificNeeds: 'La detenzione comune insieme ad altre specie di uccelli è consigliata solo alle persone con molte conoscenze specialistiche.'
          links:
            '0':
              text: 'Foglio Informativo PSA - Diamante mandarino'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_zebrafinken_it.pdf'
        grayWaxbill:
          name: 'Becco di corallo'
          namePlural: 'Becchi di corallo'
          minAnimalsErrorMessage: 'I becchi di corallo sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più becchi di corallo insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I becchi di corallo sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'I becchi di corallo possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'Possibile solo nei mesi estivi, poiché i becchi di corallo sono sensibili al freddo. Nei mesi estivi, parco esterno costituito da una voliera e da un locale interna adiacente che può essere riscaldato (minimo 18°C) (ad es. una casetta da giardino).  La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: 'La detenzione comune insieme ad altre specie di uccelli è consigliata solo alle persone con molte conoscenze specialistiche.'
          links:
            '0':
              text: 'Uccelli - Detenzione adeguata - Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Voegel_A4_web.pdf'
        goldenBreasted:
          name: 'Bengalino ventre arancio'
          namePlural: 'Bengalini ventre arancio'
          minAnimalsErrorMessage: 'I bengalini ventre arancio sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più bengalini ventre arancio insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I bengalini ventre arancio dovrebbero essere detenuti in coppia poiché i maschi possono essere aggressivi nei confronti dei rivali della stessa specie durante la stagione riproduttiva.'
            indoor: 'I bengalini ventre arancio possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'Possibile solo nei mesi estivi, poiché i bengalini ventre arancio sono sensibili al freddo. Nei mesi estivi, parco esterno costituito da una voliera e da un locale interna adiacente che può essere riscaldato (minimo 15°C) (ad es. una casetta da giardino).  La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Uccello longevo, molto attivo, necessita di una voliera spaziosa con erba e canne. \nLa detenzione comune insieme ad altre specie di uccelli è impegnativa e consigliata solo alle persone con molte conoscenze specialistiche."
          links:
            '0':
              text: 'Uccelli - Detenzione adeguata - Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Voegel_A4_web.pdf'
        gouldianMadine:
          name: 'Diamante di Gould'
          namePlural: 'Diamanti di Gould'
          minAnimalsErrorMessage: 'I diamante di Gould sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I diamanti di Gould sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'I diamanti di Gould possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'Possibile solo nei mesi estivi, poiché i diamanti di Gould sono sensibili al freddo. Nei mesi estivi, parco esterno costituito da una voliera e da un locale interno adiacente che può essere riscaldato (minimo 18°C) (ad es. una casetta da giardino).  La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.). Nella stagione invernale, i diamanti di Gould devono assolutamente essere detenuti all’interno (vedi sopra).'
            specificNeeds: "Numerose possibilità di riparo e abbastanza nascondigli sono essenziali per gli uccelli timidi. \nLa detenzione comune insieme ad altre specie di uccelli è impegnativa e consigliata solo alle persone con molte conoscenze specialistiche.\nEvitare l’acquisto di specie morfo-cromatico d’allevamento."
          links:
            '0':
              text: 'Foglio Informativo PSA - Diamanti di Gould'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Gouldamadinen_it.pdf'
        budgerigar:
          name: 'Parrocchetto ondulato'
          namePlural: 'Parrocchetti ondulati'
          minAnimalsErrorMessage: 'I parrocchetti ondulati sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I parrocchetti ondulati sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive (almeno 2, meglio 3). Ciò richiede una grande voliera, poiché i maschi difendono il sito di nidificazione durante la stagione riproduttiva.'
            indoor: 'I parrocchetti ondulati possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interno adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "I parrocchetti ondulati hanno bisogno di svariate opportunità di arrampicarsi sotto forma di vari rami naturali (nocciolo, salice, faggio, alberi da frutto non trattati). Questi servono anche come materiale da rosicchiare e devono essere rinnovati regolarmente.\n\nLa socializzazione con altre specie è possibile, ma consigliata solo per detentori esperti di uccelli. Evitare di acquistare forme di allevamento estreme (ad esempio uccelli con creste)."
          links:
            '0':
              text: 'Foglio Informativo PSA - Parrocchetto ondulato'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_wellensittiche_it.pdf'
        agapornid:
          name: 'Inseparabile'
          namePlural: 'Inseparabili'
          minAnimalsErrorMessage: 'Gli inseparabili sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1.  Allen Papageienartigen müssen reichlich Naturäste als Nage- und Klettermöglichkeit zur Verfügung stehen.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'Gli inseparabili sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'Gli inseparabili possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: ' La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interno adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino).  La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Per la detenzione domestica, considerare prima di tutto specie come inseparabili di Fischer, facciarosa, mascherato e guancenere. Le altre specie di agapornidi sono più impegnative e sono quindi sconsigliate per i principianti.\nAlcune specie di agapornidi possono essere piuttosto aggressive e non sono adatte per essere detenute con altre specie.\n\nGli agapornidi possono essere piuttosto rumorosi. Pertanto, anche il vicinato deve essere d'accordo con la loro detenzione."
          links:
            '0':
              text: 'Foglio Informativo PSA Inseparabili'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Agaporniden_it.pdf'
        tarantaParrot:
          name: 'Inseparabile alinere'
          namePlural: 'Inseparabili alinere'
          minAnimalsErrorMessage: 'Gli inseparabili alinere sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'Gli inseparabili alinere sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'Gli inseparabili alinere possono essere detenuti in voliere di grandi dimensioni o in stanze per uccelli. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interna adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino).  La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Gli inseparabili alinere rappresentano la più grande specie di agapornidi (inseparabili). \nDetenere questa specie di agapornidi è impegnativo e non è quindi adatta ai principianti.\nLa socializzazione con altre specie è possibile, ma consigliata solo per detentori esperti di uccelli. \nGli inseparabili alinere possono essere piuttosto rumorosi. Pertanto, anche il vicinato deve essere d'accordo con la loro detenzione."
          links:
            '0':
              text: 'Foglio Informativo PSA Inseparabili'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Agaporniden_it.pdf'
        cockatiel:
          name: 'Calopsitta'
          namePlural: 'Calopsitte'
          minAnimalsErrorMessage: 'Le calopsitte sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'Le calopsitte sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'Le calopsitte possono essere detenute in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interno adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Le calopsitte hanno bisogno di svariate opportunità di arrampicarsi sotto forma di vari rami naturali (nocciolo, salice, faggio, alberi da frutto non trattati). Questi servono anche come materiale da rosicchiare e devono essere rinnovati regolarmente.\n\nLa socializzazione con altre specie è possibile, ma consigliata solo per detentori esperti di uccelli."
          links:
            '0':
              text: 'Foglio Informativo PSA Calopsitta'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_Nymphensittiche_it.pdf'
        beautifulParakeet:
          name: 'Pappagallo turchese'
          namePlural: 'Pappagalli turchesi'
          minAnimalsErrorMessage: 'I pappagalli turchesi sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione comune di una coppia di riproduzione è la più rispettosa degli animali. È fondamentale prestare attenzione alla tolleranza individuale degli animali, perché i pappagalli turchesi possono reagire in modo piuttosto aggressivo ai conspecifici così come ad altre specie di uccelli.'
          maxAnimalsErrorMessage: 'Attenzione! La detenzione dei pappagalli turchesi è  impegnativa: i pappagalli turchesi devono essere detenuti in coppia poiché possono reagire in modo aggressivo ai conspecifici e ad altre specie di uccelli.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I pappagalli turchesi sono molto socievoli, ma possono essere piuttosto aggressivi verso altri uccelli, anche conspecifici. Per i pappagalli turchesi si raccomanda quindi la detenzione in coppia.'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interna adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "I pappagalli turchesi hanno bisogno di un'area di volo libera più ampia possibile perché il loro volo è rettilineo. Hanno anche bisogno di essere occupati e di avere opportunità di rosicchiare sotto forma di una varietà di rami freschi e non tossici.\nEvitare la socializzazione con altre specie di uccelli poiché possono reagire in modo aggressivo."
          links:
            '0':
              text: 'Uccelli - Detenzione adeguata - Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Voegel_A4_web.pdf'
        rosellaConure:
          name: 'Rosella orientale'
          namePlural: 'Roselle orientali'
          minAnimalsErrorMessage: 'Le roselle orientali sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 20 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'Le roselle orientali sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive. In caso di detenzione di più coppie, la voliera deve essere molto grande perché i maschi sono molto aggressivi durante la stagione del corteggiamento e il periodo della cova.'
            indoor: 'Le roselle orientali possono essere detenute in voliere di grandi dimensioni o in stanze per uccelli. Le tradizionali gabbie per uccelli sono troppo piccole per una detenzione conforme alla specie. Le voliere alte e strette non sono idonee, poiché gli uccelli necessitano di spazio orizzontale per volare. Allo stesso modo, anche le vetrine non sono idonee.'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interno adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Le roselle orientali hanno bisogno di un'area di volo libera più ampia possibile perché sono molto vivaci. Hanno anche bisogno di essere occupati e di avere opportunità di rosicchiare sotto forma di una varietà di rami freschi e non tossici.\nLa socializzazione con altre specie è possibile, ma consigliata solo per detentori esperti di uccelli."
          links:
            '0':
              text: 'Uccelli - Detenzione adeguata - Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Voegel_A4_web.pdf'
        cockatoo:
          name: 'Cacatua'
          namePlural: 'Cacatua'
          minAnimalsErrorMessage: 'I cacatua sono animali sociali. La detenzione di esemplari singoli è quindi vietata! La detenzione dei cacatua (ad eccezione delle calopsitte) è estremamente impegnativa e necessita di un permesso. Se avete intenzione di detenere dei cacatua, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per maggiori informazioni.'
          maxAnimalsWarningMessage: 'La detenzione dei cacatua (ad eccezione delle calopsitte) è estremamente impegnativa e necessita di un permesso. Se avete intenzione di detenere dei cacatua, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per maggiori informazioni.'
          maxAnimalsErrorMessage: 'La detenzione dei cacatua (ad eccezione delle calopsitte) è estremamente impegnativa e necessita di un permesso. Se avete intenzione di detenere dei cacatua, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per maggiori informazioni.'
          laws: { }
          recommendations:
            general: 'Per motivi di benessere degli animali, la Protezione Svizzera degli Animali PSA sconsiglia l''allevamento privato di ara/cacatua. I requisiti di spazio per tenere gli ara/cacatua in modo rispettoso degli animali sono immensi e di solito non possono essere soddisfatti dai detentori privati. L''allevamento di cacatua o ara è estremamente impegnativo e richiede un''autorizzazione. Prima di acquistare un cacatua o un ara, è necessario completare un corso per esperti (SKN). Inoltre, è necessario presentare una domanda all''ufficio veterinario cantonale del cantone di residenza e dimostrare di poter soddisfare tutti i requisiti prescritti dalla legge in materia di detenzione, cura e acquisto. Se intendete allevare cacatua o ara, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per ulteriori informazioni.'
          links:
            '0':
              text: 'Informazioni tecniche − Protezione degli animali. Obbligo di autorizzazione per la detenzione di pappagalli di grossataglia'
              file: 'https://www.blv.admin.ch/dam/blv/it/dokumente/tiere/heim-und-wildtierhaltung/fi-bew-pflicht-grosspapageien.pdf.download.pdf/IT_3.1_Fachinformation_Bewilligungspflicht_fu%CC%88r_Grosspapageien_IT.pdf'
        macaw:
          name: 'Ara'
          namePlural: 'Ara'
          minAnimalsErrorMessage: "Gli ara sono animali sociali. La detenzione di esemplari singoli è quindi vietata! \nLa detenzione dei pappagalli di grandi dimensioni come gli ara è estremamente impegnativa e necessita di un permesso. Se avete intenzione di detenere degi ara, contattate l'ufficio veterinario cantonale del vostro cantone di residenza per maggiori informazioni."
          maxAnimalsWarningMessage: 'La detenzione dei pappagalli di grandi dimensioni come gli ara è estremamente impegnativa e necessita di un permesso. Se avete intenzione di detenere degi ara, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per maggiori informazioni.'
          maxAnimalsErrorMessage: 'La detenzione dei pappagalli di grandi dimensioni come gli ara è estremamente impegnativa e necessita di un permesso. Se avete intenzione di detenere degi ara, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per maggiori informazioni.'
          laws: { }
          recommendations:
            general: 'Per motivi di benessere degli animali, la Protezione Svizzera degli Animali PSA sconsiglia l''allevamento privato di ara/cacatua. I requisiti di spazio per tenere gli ara/cacatua in modo rispettoso degli animali sono immensi e di solito non possono essere soddisfatti dai detentori privati. L''allevamento di cacatua o ara è estremamente impegnativo e richiede un''autorizzazione. Prima di acquistare un cacatua o un ara, è necessario completare un corso per esperti (SKN). Inoltre, è necessario presentare una domanda all''ufficio veterinario cantonale del cantone di residenza e dimostrare di poter soddisfare tutti i requisiti prescritti dalla legge in materia di detenzione, cura e acquisto. Se intendete allevare cacatua o ara, contattate l''ufficio veterinario cantonale del vostro cantone di residenza per ulteriori informazioni.'
          links:
            '0':
              text: 'Informazioni tecniche − Protezione degli animali. Obbligo di autorizzazione per la detenzione di pappagalli di grossataglia'
              file: 'https://www.blv.admin.ch/dam/blv/it/dokumente/tiere/heim-und-wildtierhaltung/fi-bew-pflicht-grosspapageien.pdf.download.pdf/IT_3.1_Fachinformation_Bewilligungspflicht_fu%CC%88r_Grosspapageien_IT.pdf'
        congoGreyParrot:
          name: 'Pappagallo cenerino'
          namePlural: 'Pappagalli cenerini'
          minAnimalsErrorMessage: 'I pappagalli cenerini sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 6 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'I pappagalli cenerini sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'La sola detenzione all’interno non è rispettosa degli animali per i pappagalli cenerini.'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interno adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Una detenzione rispettosa dei pappagalli cenerini è estremamente impegnativa. \nIl commercio di questi animali estremamente longevi è in forte espansione e ci sono molte offerte da parte di fornitori senza scrupoli (ad es. allevamento a mano, importazioni da fonti poco chiare). I pappagalli cenerini possono essere piuttosto rumorosi,pertanto anche il vicinato deve essere d'accordo con la loro detenzione. \n\nPer motivi di protezione degli animali,evitare di acquistare animali provenienti da allevamenti a mano."
          links:
            '0':
              text: 'Foglio Informativo PSA Pappagallo cenerino'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/voegel/mb_graupapageien_it.pdf'
        amazon:
          name: 'Amazzone'
          namePlural: 'Amazzoni'
          minAnimalsErrorMessage: 'Le amazzoni sono animali sociali. La detenzione di esemplari singoli è quindi vietata!'
          maxAnimalsWarningMessage: 'La detenzione di più coppie riproduttive insieme è la più rispettosa degli animali. Tuttavia, è fondamentale prestare attenzione alla tolleranza individuale degli animali, soprattutto durante la stagione del corteggiamento e il periodo della cova. Per detenere più di una coppia riproduttiva, è necessario scegliere una voliera spaziosa con molte possibilità di rifugio e opzioni alternative.'
          maxAnimalsErrorMessage: 'Inserire un numero di 6 o meno.'
          laws:
            furnishing: 'Per legge sono obbligatori una possibilità di bagnarsi, posatoi flessibili di diverso spessore e orientamento nonché la fornitura di sabbia per uccelli a cui poter attingere in autonomia. Un terzo del volume deve essere privo di strutture. Nei parchi con una superficie inferiore a 2 m2, il rapporto fra lunghezza e larghezza, riferito alla superficie minima, deve essere al massimo di 2:1. Tutti i pappagalli devono avere a disposizione molti rami naturali da rosicchiare e per arrampicarsi.'
            socialStructure: 'La detenzione singola è vietata'
            lawDetails: 'Si prega di notare che le dimensioni minime previste per legge rappresentano solo dei requisiti minimi assoluti. In Svizzera è vietato detenere animali in gabbie più piccole e costituisce un maltrattamento degli animali. Per una detenzione degli uccelli rispettosa degli animali sono necessarie delle voliere molto più ampie e attrezzature ben strutturate (vedi raccomandazioni).'
          recommendations:
            furnishing: 'Rami fissati a una sola estremità (= posatoi flessibili), corde varie, altalene, piatteforme appese al soffitto, vaschetta da bagno per uccelli (bacinella per il bagno o vasca bassa), pietre calcaree/ossi di seppia appesi, graniglia per la digestione in una ciotola piatta, lampade UV appropriate in caso di detenzione in locali chiusi, come fondo del suolo sabbia, ghiaia o trucioli di faggio. Le sbarre della recinzione devono essere disposte in orizzontale. Le gabbie rotonde sono contrarie alla protezione degli animali e vanno quindi evitate.'
            socialStructure: 'Le amazzoni sono molto socievoli. Per rispettare il più possibile le esigenze di questa specie, occorre detenere gli animali in gruppi di più coppie riproduttive.'
            indoor: 'La sola detenzione all’interno non è rispettosa degli animali per le amazzoni'
            outdoor: 'La forma di detenzione più rispettosa degli animali è un parco esterno, costituito da una voliera e da un locale interna adiacente che può essere riscaldato (protetto dal gelo) (ad es. una casetta da giardino). La voliera deve essere parzialmente coperta nella parte superiore (protezione dalle intemperie) e va schermata su almeno due lati (piante o arbusti, rete frangivento coprente, ecc.).'
            specificNeeds: "Le amazzoni possono essere piuttosto rumorose,pertanto anche il vicinato deve essere d'accordo con la loro detenzione. \n\nEsistono diverse specie di amazzoni e non tutte sono adatte per la detenzione domestica. La più diffusa è l’amazzone fronteblu. Ogni specie ha le proprie esigenze specifiche in termini di detenzione, clima, alimentazione e struttura sociale. Si prega di richiedere informazioni a degli esperti riconosciuti o di seguire un corso specialistico prima dell'acquisto.\nPer motivi di protezione degli animali,evitare di acquistare animali provenienti da allevamenti a mano."
          links:
            '0':
              text: 'Uccelli - Detenzione adeguata - Parchi e attrezzature idonei'
              file: 'https://www.tierhaltungsrechner.ch/files/merkblaetter/Brosch_Gehege_i_Voegel_A4_web.pdf'
      equidae:
        horse:
          name: 'Cavallo'
          namePlural: 'Cavalli'
          minAnimalsWarningMessage: "Attenzione: gli equidi devono avere almeno un contatto visivo, acustico e olfattivo con altri equidi. Preferibilmente con un conspecifico, in questo caso con un altro cavallo."
          maxAnimalsWarningMessage: "In caso di cinque o più equidi molto compatibili fra loro, la superficie globale può essere ridotta al massimo del 20 per cento; nei seguenti risultati è stato tenuto conto di questo. La PSA non raccomanda questa riduzione."
          recommendations:
            pasturing: 'Quando possibile'
            socialStructure: "I cavalli devono avere un contatto diretto con altri cavalli. I cavalli e gli asini parlano una lingua diversa e hanno esigenze differenti per quanto concerne la detenzione e l’alimentazione. "
            activity: 'Tutti gli equidi devono essere occupati quotidianamente.'
          laws:
            runoutTime: 'Cavalli utilizzati 2 x 2 h / settimana; cavalli non utilizzati 2h al giorno'
            pasturing: 'non prescritto'
            structuring: "Se gli equidi sono detenuti in gruppo, devono essere previste strutture che permettono loro di evitarsi e di ritirarsi; per i puledri svezzati e gli animali giovani non sono necessarie strutture per evitarsi e ritirarsi fino all'inizio del loro utilizzo regolare, tuttavia al più tardi fino al raggiungimento dei 30 mesi di età. Non devono essere presenti vicoli ciechi. Gli equidi non devono essere tenuti legati."
            exercise: "Agli equidi deve essere concesso sufficiente movimento ogni giorno. Il movimento comprende l’utilizzazione e l’uscita all’aperto. In presenza di condizioni del terreno o meteorologiche estreme è possibile, in via eccezionale, far uscire gli equidi su una superficie coperta."
            activity: "Gli equidi devono disporre di sufficiente foraggio grezzo per le esigenze di occupazione tipiche della specie, ad esempio paglia da foraggio, eccetto durante il pascolo."
            socialStructure: "Gli equidi devono avere un contatto visivo, acustico e olfattivo con altri equidi. Dopo lo svezzamento da parte della madre, gli equidi devono essere detenuti in gruppo fino al raggiungimento dei 30 mesi di età o fino all’inizio del loro utilizzo regolare."
          links:
            '0':
              text: 'Sito web dell''PSA sull''allevamento dei cavalli'
              file: 'https://www.tierschutz.com/it/pferde'
            '1':
              text: 'Ordinanza sulla protezione degli animali'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/it'
        pony:
          name: 'Pony'
          namePlural: 'Pony'
          minAnimalsWarningMessage: "Attenzione: gli equidi devono avere almeno un contatto visivo, acustico e olfattivo con altri equidi. Preferibilmente con un conspecifico, in questo caso con un altro pony o cavallo."
          maxAnimalsWarningMessage: 'In caso di cinque o più equidi molto compatibili fra loro, la superficie globale può essere ridotta al massimo del 20 per cento; nei seguenti risultati è stato tenuto conto di questo. La PSA non raccomanda questa riduzione.'
          recommendations:
            pasturing: "Quando possibile, adeguare tuttavia lo stato dell’alimentazione!"
            socialStructure: "I pony devono avere un contatto diretto con altri pony o cavalli. "
            activity: 'Tutti gli equidi devono essere occupati quotidianamente.'
            specialRequirements: "Con l'erba giovane, ricca di proteine​e fruttani, sussiste un aumento del rischio di obesità, soprattutto nei pony, specie se questi ultimi non si muovono a sufficienza."
          laws:
            runoutTime: 'Pony utilizzati 2 x 2 h / settimana; pony non utilizzati 2h al giorno'
            pasturing: 'non prescritto'
            structuring: "Se gli equidi sono detenuti in gruppo, devono essere previste strutture che permettono loro di evitarsi e di ritirarsi; per i puledri svezzati e gli animali giovani non sono necessarie strutture per evitarsi e ritirarsi fino all'inizio del loro utilizzo regolare, tuttavia al più tardi fino al raggiungimento dei 30 mesi di età. Non devono essere presenti vicoli ciechi. Gli equidi non devono essere tenuti legati."
            exercise: "Agli equidi deve essere concesso sufficiente movimento ogni giorno. Il movimento comprende l’utilizzazione e l’uscita all’aperto. In presenza di condizioni del terreno o meteorologiche estreme è possibile, in via eccezionale, far uscire gli equidi su una superficie coperta."
            activity: "Gli equidi devono disporre di sufficiente foraggio grezzo per le esigenze di occupazione tipiche della specie, ad esempio paglia da foraggio, eccetto durante il pascolo."
            socialStructure: "Gli equidi devono avere un contatto visivo, acustico e olfattivo con altri equidi. Dopo lo svezzamento da parte della madre, gli equidi devono essere detenuti in gruppo fino al raggiungimento dei 30 mesi di età o fino all’inizio del loro utilizzo regolare."
          links:
            '0':
              text: 'Sito web dell''PSA sull''allevamento dei cavalli'
              file: 'https://www.tierschutz.com/it/pferde'
            '1':
              text: 'Ordinanza sulla protezione degli animali'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/it'
        donkey:
          name: 'Asino'
          namePlural: 'Asini'
          minAnimalsWarningMessage: "Attenzione: gli equidi devono avere almeno un contatto visivo, acustico e olfattivo con altri equidi. Preferibilmente con un conspecifico, in questo caso con un altro asino.  "
          maxAnimalsWarningMessage: 'In caso di cinque o più equidi molto compatibili fra loro, la superficie globale può essere ridotta al massimo del 20 per cento; nei seguenti risultati è stato tenuto conto di questo. La PSA non raccomanda questa riduzione.'
          recommendations:
            pasturing: 'Pascolo limitato per gli asini, più movimento su ampie aree asciutte.'
            socialStructure: "Gli asini hanno bisogno del contatto diretto con i conspecifici, quindi con altri asini. Si differenziano dai cavalli per il comportamento e la fisiologia. Bisogna fornire loro cibo e occupazioni aggiuntivi con un alto contenuto di fibre come rami e paglia."
            activity: 'Tutti gli equidi devono essere occupati quotidianamente.'
            specialRequirements: "Gli asini sono animali che assimilano il cibo in modo molto efficiente. Hanno bisogno di alimenti ipocalorici a basso contenuto di zuccheri e proteine,​ma ricchi di fibre. Il manto degli asini è difficilmente idrorepellente, quindi quando sono bagnati, gli asini sono soggetti a malattie. Hanno sempre bisogno di un riparo asciutto. Gli asini hanno bisogno di terreno asciutto, altrimenti c'è il rischio che insorgano problemi agli zoccoli."
          laws:
            runoutTime: 'Muli utilizzati 2 x 2 h / settimana; muli non utilizzati 2h al giorno'
            pasturing: 'non prescritto'
            structuring: "Se gli equidi sono detenuti in gruppo, devono essere previste strutture che permettono loro di evitarsi e di ritirarsi; per i puledri svezzati e gli animali giovani non sono necessarie strutture per evitarsi e ritirarsi fino all'inizio del loro utilizzo regolare, tuttavia al più tardi fino al raggiungimento dei 30 mesi di età. Non devono essere presenti vicoli ciechi. Gli equidi non devono essere tenuti legati."
            exercise: "Agli equidi deve essere concesso sufficiente movimento ogni giorno. Il movimento comprende l’utilizzazione e l’uscita all’aperto. In presenza di condizioni del terreno o meteorologiche estreme è possibile, in via eccezionale, far uscire gli equidi su una superficie coperta."
            activity: "Gli equidi devono disporre di sufficiente foraggio grezzo per le esigenze di occupazione tipiche della specie, ad esempio paglia da foraggio, eccetto durante il pascolo."
            socialStructure: "Gli equidi devono avere un contatto visivo, acustico e olfattivo con altri equidi. Dopo lo svezzamento da parte della madre, gli equidi devono essere detenuti in gruppo fino al raggiungimento dei 30 mesi di età o fino all’inizio del loro utilizzo regolare."
          links:
            '0':
              text: 'Sito web dell''PSA sull''allevamento dei cavalli'
              file: 'https://www.tierschutz.com/it/pferde'
            '1':
              text: 'Ordinanza sulla protezione degli animali'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/it'
        mule:
          name: 'Mulo'
          namePlural: 'Muli'
          minAnimalsWarningMessage: "Attenzione: gli equidi devono avere almeno un contatto visivo, acustico e olfattivo con altri equidi. Preferibilmente con un conspecifico, in questo caso con un altro mulo."
          maxAnimalsWarningMessage: 'In caso di cinque o più equidi molto compatibili fra loro, la superficie globale può essere ridotta al massimo del 20 per cento; nei seguenti risultati è stato tenuto conto di questo. La PSA non raccomanda questa riduzione.'
          recommendations:
            pasturing: 'Pascolo limitato'
            socialStructure: "I muli preferiscono il contatto con altri muli. Naturalmente i muli possono essere tenuti con la madre cavalla. Tuttavia, bisogna tenere conto della loro fisiologia per quanto concerne alimentazione e pascolo.  "
            activity: 'Tutti gli equidi devono essere occupati quotidianamente.'
          laws:
            runoutTime: 'Muli utilizzati 2 x 2 h / settimana; muli non utilizzati 2h al giorno'
            pasturing: 'non prescritto'
            structuring: "Se gli equidi sono detenuti in gruppo, devono essere previste strutture che permettono loro di evitarsi e di ritirarsi; per i puledri svezzati e gli animali giovani non sono necessarie strutture per evitarsi e ritirarsi fino all'inizio del loro utilizzo regolare, tuttavia al più tardi fino al raggiungimento dei 30 mesi di età. Non devono essere presenti vicoli ciechi. Gli equidi non devono essere tenuti legati."
            exercise: "Agli equidi deve essere concesso sufficiente movimento ogni giorno. Il movimento comprende l’utilizzazione e l’uscita all’aperto. In presenza di condizioni del terreno o meteorologiche estreme è possibile, in via eccezionale, far uscire gli equidi su una superficie coperta."
            activity: "Gli equidi devono disporre di sufficiente foraggio grezzo per le esigenze di occupazione tipiche della specie, ad esempio paglia da foraggio, eccetto durante il pascolo."
            socialStructure: "Gli equidi devono avere un contatto visivo, acustico e olfattivo con altri equidi. Dopo lo svezzamento da parte della madre, gli equidi devono essere detenuti in gruppo fino al raggiungimento dei 30 mesi di età o fino all’inizio del loro utilizzo regolare."
          links:
            '0':
              text: 'Sito web dell''PSA sull''allevamento dei cavalli'
              file: 'https://www.tierschutz.com/it/pferde'
            '1':
              text: 'Ordinanza sulla protezione degli animali'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/it'
        hinny:
          name: 'Bardotto'
          namePlural: 'Bardotti'
          minAnimalsWarningMessage: "Attenzione: gli equidi devono avere almeno un contatto visivo, acustico e olfattivo con altri equidi. Preferibilmente con un conspecifico, in questo caso con un altro bardotto. "
          maxAnimalsWarningMessage: 'In caso di cinque o più equidi molto compatibili fra loro, la superficie globale può essere ridotta al massimo del 20 per cento; nei seguenti risultati è stato tenuto conto di questo. La PSA non raccomanda questa riduzione.'
          recommendations:
            pasturing: 'Pascolo limitato'
            socialStructure: "I bardotti prediligono il contatto diretto con altri bardotti. Tuttavia possono anche essere detenuti con asini e/o cavalli. Tuttavia, bisogna tenere conto della loro fisiologia per quanto concerne alimentazione e pascolo.  "
            activity: 'Tutti gli equidi devono essere occupati quotidianamente.'
          laws:
            runoutTime: 'Bardotti utilizzati 2 x 2 h / settimana; bardotti non utilizzati 2h al giorno'
            pasturing: 'non prescritto'
            structuring: "Se gli equidi sono detenuti in gruppo, devono essere previste strutture che permettono loro di evitarsi e di ritirarsi; per i puledri svezzati e gli animali giovani non sono necessarie strutture per evitarsi e ritirarsi fino all'inizio del loro utilizzo regolare, tuttavia al più tardi fino al raggiungimento dei 30 mesi di età. Non devono essere presenti vicoli ciechi. Gli equidi non devono essere tenuti legati."
            exercise: "Agli equidi deve essere concesso sufficiente movimento ogni giorno. Il movimento comprende l’utilizzazione e l’uscita all’aperto. In presenza di condizioni del terreno o meteorologiche estreme è possibile, in via eccezionale, far uscire gli equidi su una superficie coperta."
            activity: "Gli equidi devono disporre di sufficiente foraggio grezzo per le esigenze di occupazione tipiche della specie, ad esempio paglia da foraggio, eccetto durante il pascolo."
            socialStructure: "Gli equidi devono avere un contatto visivo, acustico e olfattivo con altri equidi. Dopo lo svezzamento da parte della madre, gli equidi devono essere detenuti in gruppo fino al raggiungimento dei 30 mesi di età o fino all’inizio del loro utilizzo regolare."
          links:
            '0':
              text: 'Sito web dell''PSA sull''allevamento dei cavalli'
              file: 'https://www.tierschutz.com/it/pferde'
            '1':
              text: 'Ordinanza sulla protezione degli animali'
              file: 'https://www.fedlex.admin.ch/eli/cc/2008/416/it'
    screenCategory:
      title: 'Selezionare la categoria'
    screenAnimal:
      title: 'Quale animale vuoi detenere?'
      multipleBirdsButton: 'Tenere insieme più specie'
      equidaeInfo: "I valori calcolati si applicano solo a equidi di età superiore a 30 mesi."
    screenMultipleBirds:
      warning: 'Per tenere insieme due o più specie di uccelli, le dimensioni dello stabulario devono essere calcolate separatamente per tutte le specie, ma con il numero totale di uccelli. Lo stabulario più grande calcolato rispetta le dimensioni minime previste dalla legge.'
      infoText: 'Se due o più specie di uccelli sono tenute insieme in uno stabulario, le dimensioni minime legali dello stabulario corrispondono sempre allo spazio necessario per la specie più grande. Ad esempio, se si tengono insieme due pappagallini e due cacatua in uno stesso recinto, le dimensioni minime dello stabulario saranno quelle richieste per quattro cacatua, in quanto si tratta della specie più grande presente nello stabulario. L''allevamento di più specie di uccelli insieme è adatto solo agli allevatori esperti. Informatevi sempre bene sulle specie di uccelli che possono essere tenute insieme.'
    screenNumber:
      title: 'Quanti {animals} vuoi detenere?'
    screenWeight:
      title: 'Quanto pesano gli {animals}?'
    screenLength:
      title: 'Quanto è lungo {animal} più grande?'
      error: 'Inserire un numero compreso tra {min} e {max}.'
    screenWithersHeight:
      title: "Qual è l'altezza al garrese dei singoli animali?"
      error: 'Inserire un numero compreso tra {min} e {max}.'
      animal: 'Livello'
    screenResults:
      title: 'Ecco come dovresti detenere {number} {animals}'
      example: 'Per esempio'
      exampleEquidae: 'Esempio per la dimensione del box'
      stsRecommendation: 'Raccomandazione della protezione degli animali'
      law: 'Legge (Ordinanza federale sulla protezione degli animali)'
      stsAreaRecommendation: 'Area minima (dimensioni interne)'
      stsLengthRecommendation:
        upto2reptiles: 'Lunghezza minima (dimensioni interne)'
        morethan2reptiles: 'In più, come minimo in lunghezza'
      stsWidthRecommendation:
        upto2reptiles: 'Larghezza minima (dimensioni interne)'
        morethan2reptiles: 'In più, come minimo in larghezza'
      stsHeightRecommendation: 'Altezza minima del recinto (dimensioni interne)'
      stsBeddingRecommendation: 'Profondità minima della lettiera'
      stsWaterBasinRecommendation: 'Bacino d''acqua'
      tolerance: 'Valore di tolleranza'
      toleranceInfo: 'Le stalle esistenti al 1° settembre 2008 che soddisfano i valori di tolleranza non necessitano di adeguamento. Se una stalla deve essere modificata perché scende al di sotto di un valore di tolleranza, rimane invariato il diritto all''altro valore di tolleranza.'
      noTolerance: 'Nessun valore di tolleranza'
      stsLayingAreaRecommendation: 'Superficie di riposo'
      stsClearWidthRecommendation: 'Larghezza netta'
      stsClearWidthRecommendationInfo: '2 x garrese dell’animale più grande'
      stsClearHeightRecommendation: 'Altezza netta'
      stsRunoutTimeRecommendation: 'Tempo di uscita all’aperto'
      stsRunoutTimeRecommendationText: "Preferibilmente accesso libero 24 ore dall’uscita all’aperto in qualsiasi stagione, ma almeno 4 ore al giorno"
      stsRunoutAreaRecommendation: "Superficie di uscita all’aperto"
      stsPasturingRecommendation: 'Pascolo'
      stsLatticeSpacing: 'Distanza massima fra le sbarre'
      lawMinimalArea: 'Area minima (dimensioni interne)'
      lawMinimalLength: 'Lunghezza minima  (dimensioni interne)'
      lawMinimalWidth: 'Larghezza minima (dimensioni interne)'
      lawMinimalHeight: 'Altezza minima del recinto (dimensioni interne)'
      lawMinimalBedding: 'Profondità minima della lettiera'
      lawWaterBasin: 'Bacino d''acqua'
      lawReptilesInfo:
        text: 'Quando si detiene il numero di animali indicato, è necessario garantire che tutti i requisiti previsti dalla {link} siano soddisfatti per le rispettive specie animali.'
        linkText: 'legge secondo l’allegato 2, tabella 5 OPan'
        linkURL: 'https://www.fedlex.admin.ch/eli/cc/2008/416/it'
      lawVolume: 'Volume'
      lawLayingArea: 'Stalla a stabulazione singola'
      layingAreaInfo: 'Superfici di riposo non separate spazialmente dall’area di alimentazione, per ulteriori condizioni vedere l''Ordinanza sulla protezione degli animali, tabella 7.'
      lawLayingAreaMultiroom: 'La superficie di riposo è separata spazialmente dall''area di movimento e alimentazione, per ulteriori condizioni vedere l''Ordinanza sulla protezione degli animali, tabella 7.'
      layingAreaMultiroomInfo: "Aires de repos séparées de l'aire d'alimentation ; pour les autres conditions, voir l'ordonnance sur la protection des animaux, tableau 7."
      lawClearWidth: 'Larghezza netta'
      lawClearWidthInfo: '1.5 x garrese dell’animale più grande'
      lawClearHeight: 'Altezza netta'
      lawRunoutTime: 'Tempo di uscita all’aperto'
      lawRunoutAreaAdjacent: "Superficie di uscita all’aperto adiacente"
      lawRunoutAreaNonadjacent: "Superficie di uscita all’aperto non adiacente"
      lawPasturing: 'Pascolo'
      additionalRecommendations:
        title: 'Ulteriori raccomandazioni'
        furnishing: 'Attrezzatura'
        socialStructure: 'Struttura sociale e bisogni specifici della specie'
        indoor: 'Detenzione interna'
        outdoor: 'Detenzione all''aperto'
        specificNeeds: 'Esigenze specifiche della specie'
        specialRequirements: 'Altre esigenze'
        activity: 'Movimento'
        more: 'Inoltre'
      additionalLaws:
        title: 'Altre disposizioni di legge'
        furnishing: 'Attrezzatura'
        raisedAreas: 'Superfici rialzate'
        socialStructure: 'Struttura sociale'
        structuring: "Struttura"
        activity: 'Occupazione'
        exercise: 'Movimento'
      lawDetails: "Si prega di notare che i requisiti minimi previsti per legge rappresentano solo dei valori minimi in assoluto. In Svizzera è vietato tenere animali in gabbie più piccole e maltrattarli. \nPer una detenzione rispettosa degli animali sono necessarie superfici molto più grandi e un’attrezzatura ben strutturata (vedi raccomandazioni).\n"
      additionalInfo: 'Per maggiori informazioni'
