export default function () {
    return {
        title: "Tierhaltungs-Rechner",
        logoStsDE: require("../../assets/img/logo-sts-de.png"),
        logoStsFR: require("../../assets/img/logo-sts-fr.png"),
        logoStsIT: require("../../assets/img/logo-sts-it.png"),
        logoBlvDE: require("../../assets/img/logo-blv-de-1000.png"),
        logoBlvDEMobile: require("../../assets/img/logo-blv-de-vertical-600.png"),
        logoBlvFR: require("../../assets/img/logo-blv-fr-1000.png"),
        logoBlvFRMobile: require("../../assets/img/logo-blv-fr-vertical-600.png"),
        logoBlvIT: require("../../assets/img/logo-blv-it-1000.png"),
        logoBlvITMobile: require("../../assets/img/logo-blv-it-vertical-600.png"),
        icon68: require("../../assets/img/icon-68.png"),
        iconWarning: require('../../assets/img/icon-warning-black.svg'),
        iconWarningWhite: require('../../assets/img/icon-warning-white.svg'),
        screen: "categorySelection",
        category: false,
        animal: false,
        numberOfAnimals: 1,
        animalWeight: false,
        animalLength: false,
        lengthImage: null,
        lengthImages: {
            lizards: require("../../assets/img/reptiles/length-lizards.png"),
            snakes: require("../../assets/img/reptiles/length-snakes.png"),
            turtles: require("../../assets/img/reptiles/length-turtles.png"),
        },
        animalWithersHeights: [],
        withersHeightImage: require("../../assets/img/equidae/equidae-withers.png"),
        showNumberError: false,
        showLengthError: false,
        showWithersHeightError: false,
        availableScreens: ["categorySelection", "animalSelection", "multipleBirds", "numberOfAnimals", "weight", "length", "withersHeight", "results"],
        previousScreens: [],
        categories: {
            rodents: {
                index: "rodents",
                img: require("../../assets/img/rodents/rodents.jpg"),
                imgSmall: require("../../assets/img/rodents/srcset/rodents-320w.jpg"),
                imgMedium: require("../../assets/img/rodents/srcset/rodents-480w.jpg"),
                imgLarge: require("../../assets/img/rodents/srcset/rodents-640w.jpg"),
            },
            reptiles: {
                index: "reptiles",
                img: require("../../assets/img/reptiles/leopardgecko.jpg"),
                imgSmall: require("../../assets/img/reptiles/srcset/leopardgecko-320w.jpg"),
                imgMedium: require("../../assets/img/reptiles/srcset/leopardgecko-480w.jpg"),
                imgLarge: require("../../assets/img/reptiles/srcset/leopardgecko-640w.jpg"),
            },
            birds: {
                index: "birds",
                img: require("../../assets/img/birds/nymphensittich.jpg"),
                imgSmall: require("../../assets/img/birds/srcset/nymphensittich-320w.jpg"),
                imgMedium: require("../../assets/img/birds/srcset/nymphensittich-480w.jpg"),
                imgLarge: require("../../assets/img/birds/srcset/nymphensittich-640w.jpg"),
                imgMultiple: require("../../assets/img/birds/multiple-birds.jpg"),
                imgMultipleSmall: require("../../assets/img/birds/srcset/multiple-birds-320w.jpg"),
                imgMultipleMedium: require("../../assets/img/birds/srcset/multiple-birds-480w.jpg"),
                imgMultipleLarge: require("../../assets/img/birds/srcset/multiple-birds-640w.jpg"),
            },
            equidae: {
                index: "equidae",
                img: require("../../assets/img/equidae/pferd.jpg"),
                imgSmall: require("../../assets/img/equidae/srcset/pferd-320w.jpg"),
                imgMedium: require("../../assets/img/equidae/srcset/pferd-480w.jpg"),
                imgLarge: require("../../assets/img/equidae/srcset/pferd-640w.jpg"),
            }
        },
        animals: {
            rodents: {
                degu: {
                    index: "degu",
                    img: require("../../assets/img/rodents/degu.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/degu-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/degu-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/degu-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 5,
                    maxHardLimit: 8,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 5, 0) * 0.4;
                        },
                        height: "200 cm",
                        bedding: "50 - 60 cm",
                        latticeSpacing: "1.3 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 5, 0) * 0.2;
                        },
                        height: "70 cm",
                        bedding: "30 cm",
                    },
                },
                fancyMouse: {
                    index: "fancyMouse",
                    img: require("../../assets/img/rodents/farbmaus.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/farbmaus-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/farbmaus-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/farbmaus-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 6,
                    maxHardLimit: 10,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 0.8 + Math.max(numberOfAnimals - 2, 0) * 0.3;
                        },
                        height: "100 cm",
                        bedding: "20 - 40 cm",
                        latticeSpacing: "0.7 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.18 + Math.max(numberOfAnimals - 2, 0) * 0.05;
                        },
                        height: "-",
                        bedding: "-",
                    },
                },
                goldenHamster: {
                    index: "goldenHamster",
                    img: require("../../assets/img/rodents/goldhamster.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/goldhamster-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/goldhamster-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/goldhamster-640w.jpg"),
                    maxHardLimit: 1,
                    recommendations: {
                        area: function () {
                            return 1;
                        },
                        height: "100 cm",
                        bedding: "40 - 80 cm",
                        latticeSpacing: "0.7 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.18;
                        },
                        height: "-",
                        bedding: "15 cm",
                    },
                },
                rabbit: {
                    index: "rabbit",
                    img: require("../../assets/img/rodents/kaninchen.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/kaninchen-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/kaninchen-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/kaninchen-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 6,
                    hasWeightOption: true,
                    weightOptions: {
                        de_CH: {
                            0: "bis 2.3 Kg",
                            1: "2.3 bis 3.5 Kg",
                            2: "3.5 bis 5.5 Kg",
                            3: "mehr als 5.5 Kg",
                        },
                        fr_CH: {
                            0: "jusqu'à 2,3 kg",
                            1: "entre 2,3 et 3,5 kg",
                            2: "entre 3,5 et 5,5 kg",
                            3: "plus de 5,5 kg",
                        },
                        it_CH: {
                            0: "fino a 2.3 Kg",
                            1: "da 2.3 a 3.5 Kg",
                            2: "da 3.5 a 5.5 Kg",
                            3: "più di 5.5 Kg",
                        },
                    },
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 6 + Math.max(numberOfAnimals - 2, 0) * 0.5;
                        },
                        height: "70 - 100 cm",
                        bedding: "5 - 10 cm",
                        latticeSpacing: "2.5 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalWeight = null) {
                            const weightOptionStrings = {
                                "bis 2.3 Kg": "bis 2.3 Kg",
                                "2.3 bis 3.5 Kg": "2.3 bis 3.5 Kg",
                                "3.5 bis 5.5 Kg": "3.5 bis 5.5 Kg",
                                "mehr als 5.5 Kg": "mehr als 5.5 Kg",
                                "jusqu'à 2,3 kg": "bis 2.3 Kg",
                                "entre 2,3 et 3,5 kg": "2.3 bis 3.5 Kg",
                                "entre 3,5 et 5,5 kg": "3.5 bis 5.5 Kg",
                                "plus de 5,5 kg": "mehr als 5.5 Kg",
                                "fino a 2.3 Kg": "bis 2.3 Kg",
                                "da 2.3 a 3.5 Kg": "2.3 bis 3.5 Kg",
                                "da 3.5 a 5.5 Kg": "3.5 bis 5.5 Kg",
                                "più di 5.5 Kg": "mehr als 5.5 Kg",
                            };

                            animalWeight = weightOptionStrings[animalWeight];

                            const values = {
                                "bis 2.3 Kg": {
                                    2: 0.48,
                                    3: 0.815,
                                    4: 1.15,
                                    5: 1.418,
                                    6: 1.686,
                                },
                                "2.3 bis 3.5 Kg": {
                                    2: 0.48,
                                    3: 0.815,
                                    4: 1.15,
                                    5: 1.418,
                                    6: 1.686,
                                },
                                "3.5 bis 5.5 Kg": {
                                    2: 0.72,
                                    3: 1.22,
                                    4: 1.72,
                                    5: 2.12,
                                    6: 2.52,
                                },
                                "mehr als 5.5 Kg": {
                                    2: 0.93,
                                    3: 1.53,
                                    4: 2.28,
                                    5: 2.76,
                                    6: 3.24,
                                },
                            };

                            if (animalWeight in values && numberOfAnimals in values[animalWeight]) {
                                const area = values[animalWeight][numberOfAnimals];

                                return area;
                            } else {
                                return "0";
                            }
                        },
                        height: function (numberOfAnimals, animalWeight) {
                            const weightOptionStrings = {
                                "bis 2.3 Kg": "bis 2.3 Kg",
                                "2.3 bis 3.5 Kg": "2.3 bis 3.5 Kg",
                                "3.5 bis 5.5 Kg": "3.5 bis 5.5 Kg",
                                "mehr als 5.5 Kg": "mehr als 5.5 Kg",
                                "jusqu'à 2,3 kg": "bis 2.3 Kg",
                                "entre 2,3 et 3,5 kg": "2.3 bis 3.5 Kg",
                                "entre 3,5 et 5,5 kg": "3.5 bis 5.5 Kg",
                                "plus de 5,5 kg": "mehr als 5.5 Kg",
                                "fino a 2.3 Kg": "bis 2.3 Kg",
                                "da 2.3 a 3.5 Kg": "2.3 bis 3.5 Kg",
                                "da 3.5 a 5.5 Kg": "3.5 bis 5.5 Kg",
                                "più di 5.5 Kg": "mehr als 5.5 Kg",
                            };

                            animalWeight = weightOptionStrings[animalWeight];

                            switch (animalWeight) {
                                case "bis 2.3 Kg":
                                    return "40 ";
                                case "2.3 bis 3.5 Kg":
                                    return "50 ";
                                case "3.5 bis 5.5 Kg":
                                    return "60 ";
                                case "mehr als 5.5 Kg":
                                    return "60 ";
                                default:
                                    return 0;
                            }
                        },
                        bedding: "-",
                    },
                },
                guineaPig: {
                    index: "guineaPig",
                    img: require("../../assets/img/rodents/meerschweinchen.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/meerschweinchen-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/meerschweinchen-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/meerschweinchen-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 6,
                    maxHardLimit: 8,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 4 + Math.max(numberOfAnimals - 4, 0) * 0.5;
                        },
                        height: "50 cm",
                        bedding: "5 - 10 cm",
                        latticeSpacing: "2.5 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 2, 0) * 0.2;
                        },
                        height: "-",
                        bedding: "-",
                    },
                },
                rat: {
                    index: "rat",
                    img: require("../../assets/img/rodents/ratte.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/ratte-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/ratte-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/ratte-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 6,
                    maxHardLimit: 8,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 5, 0) * 0.4;
                        },
                        height: "200 cm",
                        bedding: "-",
                        latticeSpacing: "1.2 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 5, 0) * 0.05;
                        },
                        height: "70 cm",
                        bedding: "-",
                    },
                },
                gerbil: {
                    index: "gerbil",
                    img: require("../../assets/img/rodents/rennmaus.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/rennmaus-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/rennmaus-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/rennmaus-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 5,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 1 + Math.max(numberOfAnimals - 2, 0) * 0.4;
                        },
                        height: "100 cm",
                        bedding: "40 - 80 cm",
                        latticeSpacing: "1 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 5, 0) * 0.05;
                        },
                        height: "-",
                        bedding: "25 cm",
                    },
                },
                dwarfHamster: {
                    index: "dwarfHamster",
                    img: require("../../assets/img/rodents/zwerghamster.jpg"),
                    imgSmall: require("../../assets/img/rodents/srcset/zwerghamster-320w.jpg"),
                    imgMedium: require("../../assets/img/rodents/srcset/zwerghamster-480w.jpg"),
                    imgLarge: require("../../assets/img/rodents/srcset/zwerghamster-640w.jpg"),
                    minSoftLimit: 4,
                    maxHardLimit: 5,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 0.8 + Math.max(numberOfAnimals - 2, 0) * 0.3;
                        },
                        height: "100 cm",
                        bedding: "40 - 80 cm",
                        latticeSpacing: "0.7 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals) {
                            return 0.18 + Math.max(numberOfAnimals - 1, 0) * 0.05;
                        },
                        height: "-",
                        bedding: "15 cm",
                    },
                },
            },
            reptiles: {
                royalPython: {
                    index: "royalPython",
                    img: require("../../assets/img/reptiles/koenigspython.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/koenigspython-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/koenigspython-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/koenigspython-640w.jpg"),
                    lengthMeasurementCategory: "snakes",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 5,
                    minLength: 20,
                    maxLength: 140,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 1.8 + Math.max(numberOfAnimals - 2, 0) * 0.5;
                        },
                        length: "180 cm",
                        width: "100 cm",
                        height: function (animalLength) {
                            if (animalLength >= 130) {
                                return "110 cm";
                            } else {
                                return "100 cm";
                            }
                        },
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 0.5 * animalLength + Math.max(numberOfAnimals - 2, 0) * 0.2 * animalLength * 0.5 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength;
                        },
                        width: function (animalLength) {
                            return Math.round(animalLength * 0.5 + Number.EPSILON);
                        },
                        height: function (animalLength) {
                            return Math.round(animalLength * 0.75 + Number.EPSILON);
                        },
                    },
                },
                cornSnake: {
                    index: "cornSnake",
                    img: require("../../assets/img/reptiles/kornnatter.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/kornnatter-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/kornnatter-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/kornnatter-640w.jpg"),
                    lengthMeasurementCategory: "snakes",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 3,
                    maxHardLimit: 5,
                    minLength: 20,
                    maxLength: 150,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 1.44 + Math.max(numberOfAnimals - 2, 0) * 0.4;
                        },
                        length: "180 cm",
                        width: "80 cm",
                        height: function (animalLength) {
                            if (animalLength >= 140) {
                                return "110 cm";
                            } else {
                                return "100 cm";
                            }
                        },
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 0.5 * animalLength + Math.max(numberOfAnimals - 2, 0) * 0.2 * animalLength * 0.5 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength;
                        },
                        width: function (animalLength) {
                            return Math.round(animalLength * 0.5 + Number.EPSILON);
                        },
                        height: function (animalLength) {
                            return Math.round(animalLength * 0.7 + Number.EPSILON);
                        },
                    },
                },
                greekTortoise: {
                    index: "greekTortoise",
                    img: require("../../assets/img/reptiles/griechische_landschildkroete.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/griechische_landschildkroete-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/griechische_landschildkroete-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/griechische_landschildkroete-640w.jpg"),
                    lengthMeasurementCategory: "turtles",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    maxHardLimit: 9,
                    minLength: 3,
                    maxLength: 40,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 10 + Math.max(numberOfAnimals - 4, 0) * 2.5;
                        },
                        length: "400 cm",
                        width: "250 cm",
                        height: "-",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 8 * animalLength * 4 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 8;
                        },
                        width: function (animalLength) {
                            return animalLength * 4;
                        },
                        height: "-",
                    },
                },
                moorishTortoise: {
                    index: "moorishTortoise",
                    img: require("../../assets/img/reptiles/maurische_landschildkroete.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/maurische_landschildkroete-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/maurische_landschildkroete-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/maurische_landschildkroete-640w.jpg"),
                    lengthMeasurementCategory: "turtles",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    maxHardLimit: 9,
                    minLength: 3,
                    maxLength: 40,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 10 + Math.max(numberOfAnimals - 4, 0) * 2.5;
                        },
                        length: "400 cm",
                        width: "250 cm",
                        height: "-",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 8 * animalLength * 4 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 8;
                        },
                        width: function (animalLength) {
                            return animalLength * 4;
                        },
                        height: "-",
                    },
                },
                wideShelledTortoise: {
                    index: "wideShelledTortoise",
                    img: require("../../assets/img/reptiles/breitrandschildkroete.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/breitrandschildkroete-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/breitrandschildkroete-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/breitrandschildkroete-640w.jpg"),
                    lengthMeasurementCategory: "turtles",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    maxHardLimit: 9,
                    minLength: 3,
                    maxLength: 40,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 10 + Math.max(numberOfAnimals - 4, 0) * 2.5;
                        },
                        length: "400 cm",
                        width: "250 cm",
                        height: "-",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 8 * animalLength * 4 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 8;
                        },
                        width: function (animalLength) {
                            return animalLength * 4;
                        },
                        height: "-",
                    },
                },
                crownedGecko: {
                    index: "crownedGecko",
                    img: require("../../assets/img/reptiles/kronengecko.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/kronengecko-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/kronengecko-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/kronengecko-640w.jpg"),
                    lengthMeasurementCategory: "lizards",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 3,
                    minLength: 1,
                    maxLength: 12,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 0.48 + Math.max(numberOfAnimals - 2, 0) * 0.2;
                        },
                        length: "80 cm",
                        width: "60 cm",
                        height: "120 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 6 * animalLength * 2 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 6;
                        },
                        width: function (animalLength) {
                            return animalLength * 2;
                        },
                        height: function (animalLength) {
                            return animalLength * 8;
                        },
                    },
                },
                boaConstrictor: {
                    index: "boaConstrictor",
                    img: require("../../assets/img/reptiles/abgottschlange.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/abgottschlange-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/abgottschlange-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/abgottschlange-640w.jpg"),
                    lengthMeasurementCategory: "snakes",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 3,
                    minLength: 20,
                    maxLength: 300,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 5.25 + Math.max(numberOfAnimals - 2, 0) * 1;
                        },
                        length: "350 cm",
                        width: "150 cm",
                        height: function (animalLength) {
                            if (animalLength >= 260) {
                                return "225 cm";
                            } else {
                                return "200 cm";
                            }
                        },
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 1 * animalLength * 0.5 + Math.max(numberOfAnimals - 2, 0) * 0.5 * animalLength * 0.2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 1;
                        },
                        width: function (animalLength) {
                            return Math.round(animalLength * 0.5 + Number.EPSILON);
                        },
                        height: function (animalLength) {
                            return Math.round(animalLength * 0.75 + Number.EPSILON);
                        },
                    },
                },
                muskTurtle: {
                    index: "muskTurtle",
                    img: require("../../assets/img/reptiles/gewoehnliche_moschusschildkroete.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/gewoehnliche_moschusschildkroete-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/gewoehnliche_moschusschildkroete-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/gewoehnliche_moschusschildkroete-640w.jpg"),
                    lengthMeasurementCategory: "turtles",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 4,
                    minLength: 2,
                    maxLength: 15,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 0.9 + Math.max(numberOfAnimals - 2, 0) * 0.1;
                        },
                        length: "150 cm",
                        width: "60 cm",
                        height: "50 cm",
                        waterBasin: function (numberOfAnimals, animalLength) {
                            const area = 0.9 + Math.max(numberOfAnimals - 2, 0) * 0.1;

                            return Math.round(area * 0.67 * 100 + Number.EPSILON) / 100 + " m² ";
                        },
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 2 * animalLength * 2 + Math.max(numberOfAnimals - 2, 0) * 1 * animalLength * 1 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 2;
                        },
                        width: function (animalLength) {
                            return animalLength * 2;
                        },
                        height: "-",
                        waterBasin: function (numberOfAnimals, animalLength) {
                            const area = animalLength * 4 * animalLength * 3 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;

                            return Math.ceil(area / 100 + Number.EPSILON) / 100 + " m² ";
                        },
                    },
                },
                kingSnake: {
                    index: "kingSnake",
                    img: require("../../assets/img/reptiles/dreiecksnatter.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/dreiecksnatter-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/dreiecksnatter-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/dreiecksnatter-640w.jpg"),
                    lengthMeasurementCategory: "snakes",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 1,
                    maxHardLimit: 5,
                    minLength: 15,
                    maxLength: 140,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 1.44 + Math.max(numberOfAnimals - 2, 0) * 0.4;
                        },
                        length: "180 cm",
                        width: "80 cm",
                        height: "80 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 1 * animalLength * 0.5 + Math.max(numberOfAnimals - 2, 0) * 0.5 * animalLength * 0.2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 1;
                        },
                        width: function (animalLength) {
                            return Math.round(animalLength * 0.5 + Number.EPSILON);
                        },
                        height: function (animalLength) {
                            return Math.round(animalLength * 0.5 + Number.EPSILON);
                        },
                    },
                },
                madagascarDayGecko: {
                    index: "madagascarDayGecko",
                    img: require("../../assets/img/reptiles/grosser_madagaskar_taggecko.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/grosser_madagaskar_taggecko-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/grosser_madagaskar_taggecko-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/grosser_madagaskar_taggecko-640w.jpg"),
                    lengthMeasurementCategory: "lizards",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 4,
                    minLength: 2,
                    maxLength: 15,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 1 + Math.max(numberOfAnimals - 2, 0) * 0.25;
                        },
                        length: "100 cm",
                        width: "100 cm",
                        height: "150 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 6 * animalLength * 6 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 6;
                        },
                        width: function (animalLength) {
                            return animalLength * 6;
                        },
                        height: function (animalLength) {
                            return animalLength * 8;
                        },
                    },
                },
                goldDustDayGecko: {
                    index: "goldDustDayGecko",
                    img: require("../../assets/img/reptiles/goldstaub_taggecko.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/goldstaub_taggecko-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/goldstaub_taggecko-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/goldstaub_taggecko-640w.jpg"),
                    lengthMeasurementCategory: "lizards",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 4,
                    minLength: 1,
                    maxLength: 7,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 0.25 + Math.max(numberOfAnimals - 2, 0) * 0.15;
                        },
                        length: "50 cm",
                        width: "50 cm",
                        height: "70 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 6 * animalLength * 6 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 6;
                        },
                        width: function (animalLength) {
                            return animalLength * 6;
                        },
                        height: function (animalLength) {
                            return animalLength * 8;
                        },
                    },
                },
                leopardGecko: {
                    index: "leopardGecko",
                    img: require("../../assets/img/reptiles/leopardgecko.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/leopardgecko-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/leopardgecko-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/leopardgecko-640w.jpg"),
                    lengthMeasurementCategory: "lizards",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 3,
                    minLength: 2,
                    maxLength: 12,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 1.05 + Math.max(numberOfAnimals - 2, 0) * 0.15;
                        },
                        length: "140 cm",
                        width: "75 cm",
                        height: "75 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 6 * animalLength * 6 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 6;
                        },
                        width: function (animalLength) {
                            return animalLength * 6;
                        },
                        height: function (animalLength) {
                            return animalLength * 2;
                        },
                    },
                },
                dwarfBeardedDragon: {
                    index: "dwarfBeardedDragon",
                    img: require("../../assets/img/reptiles/zwergbartagame.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/zwergbartagame-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/zwergbartagame-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/zwergbartagame-640w.jpg"),
                    lengthMeasurementCategory: "lizards",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 3,
                    minLength: 2,
                    maxLength: 14,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 0.9 + Math.max(numberOfAnimals - 2, 0) * 0.2;
                        },
                        length: "150 cm",
                        width: "60 cm",
                        height: "100 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 5 * animalLength * 4 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 5;
                        },
                        width: function (animalLength) {
                            return animalLength * 4;
                        },
                        height: function (animalLength) {
                            return animalLength * 4;
                        },
                    },
                },
                centralBeardedDragon: {
                    index: "centralBeardedDragon",
                    img: require("../../assets/img/reptiles/streifenkoepfige_bartagame.jpg"),
                    imgSmall: require("../../assets/img/reptiles/srcset/streifenkoepfige_bartagame-320w.jpg"),
                    imgMedium: require("../../assets/img/reptiles/srcset/streifenkoepfige_bartagame-480w.jpg"),
                    imgLarge: require("../../assets/img/reptiles/srcset/streifenkoepfige_bartagame-640w.jpg"),
                    lengthMeasurementCategory: "lizards",
                    minHardLimit: 0,
                    minSoftLimit: 2,
                    maxSoftLimit: 2,
                    maxHardLimit: 3,
                    minLength: 2,
                    maxLength: 20,
                    hasLengthOption: true,
                    recommendations: {
                        area: function (numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 2, 0) * 0.5;
                        },
                        length: "250 cm",
                        width: "80 cm",
                        height: "100 cm",
                    },
                    laws: {
                        area: function (numberOfAnimals, animalLength) {
                            return animalLength * 5 * animalLength * 4 + Math.max(numberOfAnimals - 2, 0) * 2 * animalLength * 2 * animalLength;
                        },
                        length: function (animalLength) {
                            return animalLength * 5;
                        },
                        width: function (animalLength) {
                            return animalLength * 4;
                        },
                        height: function (animalLength) {
                            return animalLength * 4;
                        },
                    },
                },
            },
            birds: {
                budgerigar: {
                    index: "budgerigar",
                    img: require("../../assets/img/birds/wellensittich.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/wellensittich-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/wellensittich-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/wellensittich-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                cockatiel: {
                    index: "cockatiel",
                    img: require("../../assets/img/birds/nymphensittich.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/nymphensittich-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/nymphensittich-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/nymphensittich-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 6, 0) * 0.05;
                        },
                        height: "0.6 m",
                        volume: function(numberOfAnimals){
                            return 0.3 + Math.max(numberOfAnimals - 6, 0) * 0.03;
                        },
                    },
                },
                agapornid: {
                    index: "agapornid",
                    img: require("../../assets/img/birds/agapornid.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/agapornid-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/agapornid-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/agapornid-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                amazon: {
                    index: "amazon",
                    img: require("../../assets/img/birds/amazone.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/amazone-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/amazone-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/amazone-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 5,
                    maxHardLimit: 6,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 6 + Math.max(numberOfAnimals - 4, 0) * 1;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.7 + Math.max(numberOfAnimals - 2, 0) * 0.1;
                        },
                        height: "1.2 m",
                        volume: function(numberOfAnimals){
                            return 0.84 + Math.max(numberOfAnimals - 2, 0) * 0.12;
                        },
                    },
                },
                congoGreyParrot: {
                    index: "congoGreyParrot",
                    img: require("../../assets/img/birds/kongo-graupapagei.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/kongo-graupapagei-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/kongo-graupapagei-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/kongo-graupapagei-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 4,
                    maxHardLimit: 6,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 6 + Math.max(numberOfAnimals - 4, 0) * 1;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.7 + Math.max(numberOfAnimals - 2, 0) * 0.1;
                        },
                        height: "1.2 m",
                        volume: function(numberOfAnimals){
                            return 0.84 + Math.max(numberOfAnimals - 2, 0) * 0.12;
                        },
                    },
                },
                tarantaParrot: {
                    index: "tarantaParrot",
                    img: require("../../assets/img/birds/tarantapapagei.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/tarantapapagei-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/tarantapapagei-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/tarantapapagei-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 6, 0) * 0.05;
                        },
                        height: "0.6 m",
                        volume: function(numberOfAnimals){
                            return 0.3 + Math.max(numberOfAnimals - 6, 0) * 0.03;
                        },
                    },
                },
                rosellaConure: {
                    index: "rosellaConure",
                    img: require("../../assets/img/birds/rosellasittich.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/rosellasittich-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/rosellasittich-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/rosellasittich-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 6;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.7 + Math.max(numberOfAnimals - 2, 0) * 0.1;
                        },
                        height: "1.2 m",
                        volume: function(numberOfAnimals){
                            return 0.84 + Math.max(numberOfAnimals - 2, 0) * 0.12;
                        },
                    },
                },
                beautifulParakeet: {
                    index: "beautifulParakeet",
                    img: require("../../assets/img/birds/schoensittich.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/schoensittich-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/schoensittich-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/schoensittich-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 6, 0) * 0.05;
                        },
                        height: "0.6 m",
                        volume: function(numberOfAnimals){
                            return 0.3 + Math.max(numberOfAnimals - 6, 0) * 0.03;
                        },
                    },
                },
                canary: {
                    index: "canary",
                    img: require("../../assets/img/birds/kanarienvogel.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/kanarienvogel-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/kanarienvogel-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/kanarienvogel-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                gouldianMadine: {
                    index: "gouldianMadine",
                    img: require("../../assets/img/birds/gouldamadinen.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/gouldamadinen-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/gouldamadinen-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/gouldamadinen-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                zebraFinch: {
                    index: "zebraFinch",
                    img: require("../../assets/img/birds/zebrafink.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/zebrafink-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/zebrafink-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/zebrafink-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                goldenBreasted: {
                    index: "goldenBreasted",
                    img: require("../../assets/img/birds/goldbruestchen.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/goldbruestchen-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/goldbruestchen-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/goldbruestchen-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                grayWaxbill: {
                    index: "grayWaxbill",
                    img: require("../../assets/img/birds/grauastrild.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/grauastrild-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/grauastrild-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/grauastrild-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.24 + Math.max(numberOfAnimals - 4, 0) * 0.05;
                        },
                        height: "0.5 m",
                        volume: function(numberOfAnimals){
                            return 0.12 + Math.max(numberOfAnimals - 4, 0) * 0.025;
                        },
                    },
                },
                diamondDove: {
                    index: "diamondDove",
                    img: require("../../assets/img/birds/diamanttaeubchen.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/diamanttaeubchen-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/diamanttaeubchen-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/diamanttaeubchen-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 8,
                    maxHardLimit: 20,
                    recommendations: {
                        area: function(numberOfAnimals) {
                            return 2 + Math.max(numberOfAnimals - 6, 0) * 0.2;
                        },
                        height: "2 m",
                        heightNumeric: 200
                    },
                    laws: {
                        area: function(numberOfAnimals) {
                            return 0.5 + Math.max(numberOfAnimals - 6, 0) * 0.05;
                        },
                        height: "0.6 m",
                        volume: function(numberOfAnimals){
                            return 0.3 + Math.max(numberOfAnimals - 6, 0) * 0.03;
                        },
                    },
                },
                macaw: {
                    index: "macaw",
                    img: require("../../assets/img/birds/ara.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/ara-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/ara-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/ara-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 1,
                    maxHardLimit: 2,
                    recommendations: {
                        area: "Aus Tierschutzgründen rät der Schweizer Tierschutz STS von der privaten Ara-Haltung ab. Der Platzbedarf für eine tiergerechte Ara-Haltung ist immens und kann von privaten Haltern meistens nicht erfüllt werden.",
                        height: "-"
                    },
                    laws: {
                        area: "10 m²",
                        height: "3 m (zu ergänzen mit 80%-Regelung)",
                        volume: function(numberOfAnimals){
                            return 30 + Math.max(numberOfAnimals - 2, 0) * 3;
                        },
                    },
                },
                cockatoo: {
                    index: "cockatoo",
                    img: require("../../assets/img/birds/kakadu.jpg"),
                    imgSmall: require("../../assets/img/birds/srcset/kakadu-320w.jpg"),
                    imgMedium: require("../../assets/img/birds/srcset/kakadu-480w.jpg"),
                    imgLarge: require("../../assets/img/birds/srcset/kakadu-640w.jpg"),
                    minHardLimit: 2,
                    maxSoftLimit: 1,
                    maxHardLimit: 2,
                    recommendations: {
                        area: "Aus Tierschutzgründen rät der Schweizer Tierschutz STS von der privaten Kakadu-Haltung ab (ausser Nymphensittich). Der Platzbedarf für eine tiergerechte Kakadu-Haltung ist immens und kann von privaten Haltern meistens nicht erfüllt werden. ",
                        height: "-"
                    },
                    laws: {
                        area: "10 m²",
                        height: "3 m (zu ergänzen mit 80%-Regelung)",
                        volume: function(numberOfAnimals){
                            return 30 + Math.max(numberOfAnimals - 2, 0) * 3;
                        },
                    },
                },
            },
            equidae: {
                horse: {
                    index: "horse",
                    img: require("../../assets/img/equidae/pferd.jpg"),
                    imgSmall: require("../../assets/img/equidae/srcset/pferd-320w.jpg"),
                    imgMedium: require("../../assets/img/equidae/srcset/pferd-480w.jpg"),
                    imgLarge: require("../../assets/img/equidae/srcset/pferd-640w.jpg"),
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    minWithersHeight: 149,
                    maxWithersHeight: 200,
                    hasWithersHeightOption: true,
                    recommendations: {
                        layingArea: function(witherHeights) {
                            let layingArea = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 162) {
                                    layingArea += 13.5;
                                } else if (witherHeights[i] < 175) {
                                    layingArea += 15.75;
                                } else {
                                    layingArea += 18;
                                }
                            }
                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 2) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            } else if (highest < 175) {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(numberOfAnimals) {
                            return 150 * numberOfAnimals;
                        }
                    },
                    laws: {
                        layingArea: function(witherHeights) {
                            let layingArea = {
                                value: 0,
                                tolerance: 0,
                                multiroom: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 162) {
                                    layingArea.value += 9;
                                    layingArea.tolerance += 8;
                                    layingArea.multiroom += 6;
                                } else if (witherHeights[i] < 175) {
                                    layingArea.value += 10.5;
                                    layingArea.tolerance += 9;
                                    layingArea.multiroom += 7.5;
                                } else {
                                    layingArea.value += 12;
                                    layingArea.tolerance += 10.5;
                                    layingArea.multiroom += 8;
                                }
                            }

                            if (witherHeights.length >= 5) {
                                layingArea.multiroom = layingArea.multiroom * 0.8;
                            }

                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 1.5) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            } else if (highest < 175) {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(witherHeights) {
                            let runoutArea = {
                                adjacent: 0,
                                nonadjacent: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 162) {
                                    runoutArea.adjacent += 20;
                                    runoutArea.nonadjacent += 30;
                                } else if (witherHeights[i] < 175) {
                                    runoutArea.adjacent += 24;
                                    runoutArea.nonadjacent += 36;
                                } else {
                                    runoutArea.adjacent += 24;
                                    runoutArea.nonadjacent += 36;
                                }
                            }

                            return runoutArea;
                        }
                    },
                },
                pony: {
                    index: "pony",
                    img: require("../../assets/img/equidae/pony.jpg"),
                    imgSmall: require("../../assets/img/equidae/srcset/pony-320w.jpg"),
                    imgMedium: require("../../assets/img/equidae/srcset/pony-480w.jpg"),
                    imgLarge: require("../../assets/img/equidae/srcset/pony-640w.jpg"),
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    minWithersHeight: 40,
                    maxWithersHeight: 148,
                    hasWithersHeightOption: true,
                    recommendations: {
                        layingArea: function(witherHeights) {
                            let layingArea = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea += 7.75;
                                } else if (witherHeights[i] < 134) {
                                    layingArea += 10.5;
                                } else {
                                    layingArea += 12;
                                }
                            }

                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 2) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                };
                            } else {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(numberOfAnimals) {
                            return 150 * numberOfAnimals;
                        }
                    },
                    laws: {
                        layingArea: function(witherHeights) {
                            let layingArea = {
                                value: 0,
                                tolerance: 0,
                                multiroom: 0
                            }; 

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea.value += 5.5;
                                    layingArea.tolerance += 5.5;
                                    layingArea.multiroom += 4;
                                } else if (witherHeights[i] < 134) {
                                    layingArea.value += 7;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 4.5;
                                } else {
                                    layingArea.value += 8;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 5.5;
                                }
                            }

                            if (layingArea.tolerance >= layingArea.value) {
                                layingArea.tolerance = 0;
                            }

                            if (witherHeights.length >= 5) {
                                layingArea.multiroom = Math.round(layingArea.multiroom * 8) / 10;
                            }

                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 1.5) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                    tolerance: 0
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                    tolerance: 0
                                };
                            } else {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2.0
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(witherHeights) {
                            let runoutArea = {
                                adjacent: 0,
                                nonadjacent: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    runoutArea.adjacent += 12;
                                    runoutArea.nonadjacent += 18;
                                } else if (witherHeights[i] < 134) {
                                    runoutArea.adjacent += 14;
                                    runoutArea.nonadjacent += 21;
                                } else {
                                    runoutArea.adjacent += 16;
                                    runoutArea.nonadjacent += 24;
                                }
                            }

                            return runoutArea;
                        }
                    },
                },
                donkey: {
                    index: "donkey",
                    img: require("../../assets/img/equidae/esel.jpg"),
                    imgSmall: require("../../assets/img/equidae/srcset/esel-320w.jpg"),
                    imgMedium: require("../../assets/img/equidae/srcset/esel-480w.jpg"),
                    imgLarge: require("../../assets/img/equidae/srcset/esel-640w.jpg"),
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    minWithersHeight: 40,
                    maxWithersHeight: 180,
                    hasWithersHeightOption: true,
                    recommendations: {
                        layingArea: function(witherHeights) {
                            let layingArea = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea += 7.75;
                                } else if (witherHeights[i] < 134) {
                                    layingArea += 10.5;
                                } else if (witherHeights[i] < 148) {
                                    layingArea += 12;
                                } else if (witherHeights[i] < 162) {
                                    layingArea += 13.5;
                                } else {
                                    layingArea += 15.75;
                                }
                            }
                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 2) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                };
                            } else if (highest < 148) {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            }  else if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(numberOfAnimals) {
                            return 150 * numberOfAnimals;
                        }
                    },
                    laws: {
                        layingArea: function(witherHeights) {
                            let layingArea = {
                                value: 0,
                                tolerance: 0,
                                multiroom: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea.value += 5.5;
                                    layingArea.tolerance += 5.5;
                                    layingArea.multiroom += 4;
                                } else if (witherHeights[i] < 134) {
                                    layingArea.value += 7;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 4.5;
                                } else if (witherHeights[i] < 148) {
                                    layingArea.value += 8;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 5.5;
                                }  else if (witherHeights[i] < 162) {
                                    layingArea.value += 9;
                                    layingArea.tolerance += 8;
                                    layingArea.multiroom += 6;
                                } else {
                                    layingArea.value += 10.5;
                                    layingArea.tolerance += 9;
                                    layingArea.multiroom += 7.5;
                                }
                            }

                            if (witherHeights.length >= 5) {
                                layingArea.multiroom = Math.round(layingArea.multiroom * 8) / 10;
                            }

                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 1.5) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                    tolerance: 0
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                    tolerance: 0
                                };
                            } else if (highest < 148) {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            } else if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(witherHeights) {
                            let runoutArea = {
                                adjacent: 0,
                                nonadjacent: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    runoutArea.adjacent += 12;
                                    runoutArea.nonadjacent += 18;
                                } else if (witherHeights[i] < 134) {
                                    runoutArea.adjacent += 14;
                                    runoutArea.nonadjacent += 21;
                                } else if (witherHeights[i] < 148) {
                                    runoutArea.adjacent += 16;
                                    runoutArea.nonadjacent += 24;
                                } else if (witherHeights[i] < 162) {
                                    runoutArea.adjacent += 20;
                                    runoutArea.nonadjacent += 30;
                                } else {
                                    runoutArea.adjacent += 24;
                                    runoutArea.nonadjacent += 36;
                                }
                            }

                            return runoutArea;
                        }
                    },
                },
                mule: {
                    index: "mule",
                    img: require("../../assets/img/equidae/maultier.jpg"),
                    imgSmall: require("../../assets/img/equidae/srcset/maultier-320w.jpg"),
                    imgMedium: require("../../assets/img/equidae/srcset/maultier-480w.jpg"),
                    imgLarge: require("../../assets/img/equidae/srcset/maultier-640w.jpg"),
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    minWithersHeight: 80,
                    maxWithersHeight: 190,
                    hasWithersHeightOption: true,
                    recommendations: {
                        layingArea: function(witherHeights) {
                            let layingArea = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea += 7.75;
                                } else if (witherHeights[i] < 134) {
                                    layingArea += 10.5;
                                } else if (witherHeights[i] < 148) {
                                    layingArea += 12;
                                } else if (witherHeights[i] < 162) {
                                    layingArea += 13.5;
                                } else {
                                    layingArea += 15.75;
                                }
                            }
                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 2) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                };
                            } else if (highest < 148) {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            }  else if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            }  else if (highest < 175) {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(numberOfAnimals) {
                            return 150 * numberOfAnimals;
                        }
                    },
                    laws: {
                        layingArea: function(witherHeights) {
                            let layingArea = {
                                value: 0,
                                tolerance: 0,
                                multiroom: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea.value += 5.5;
                                    layingArea.tolerance += 5.5;
                                    layingArea.multiroom += 4;
                                } else if (witherHeights[i] < 134) {
                                    layingArea.value += 7;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 4.5;
                                } else if (witherHeights[i] < 148) {
                                    layingArea.value += 8;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 5.5;
                                }  else if (witherHeights[i] < 162) {
                                    layingArea.value += 9;
                                    layingArea.tolerance += 8;
                                    layingArea.multiroom += 6;
                                } else {
                                    layingArea.value += 10.5;
                                    layingArea.tolerance += 9;
                                    layingArea.multiroom += 7.5;
                                }
                            }

                            if (witherHeights.length >= 5) {
                                layingArea.multiroom = Math.round(layingArea.multiroom * 8) / 10;
                            }

                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 1.5) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                    tolerance: 0
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                    tolerance: 0
                                };
                            } else if (highest < 148) {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            } else if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(witherHeights) {
                            let runoutArea = {
                                adjacent: 0,
                                nonadjacent: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    runoutArea.adjacent += 12;
                                    runoutArea.nonadjacent += 18;
                                } else if (witherHeights[i] < 134) {
                                    runoutArea.adjacent += 14;
                                    runoutArea.nonadjacent += 21;
                                } else if (witherHeights[i] < 148) {
                                    runoutArea.adjacent += 16;
                                    runoutArea.nonadjacent += 24;
                                } else if (witherHeights[i] < 162) {
                                    runoutArea.adjacent += 20;
                                    runoutArea.nonadjacent += 30;
                                } else {
                                    runoutArea.adjacent += 24;
                                    runoutArea.nonadjacent += 36;
                                }
                            }

                            return runoutArea;
                        }
                    },
                },
                hinny: {
                    index: "hinny",
                    img: require("../../assets/img/equidae/maulesel.jpg"),
                    imgSmall: require("../../assets/img/equidae/srcset/maulesel-320w.jpg"),
                    imgMedium: require("../../assets/img/equidae/srcset/maulesel-480w.jpg"),
                    imgLarge: require("../../assets/img/equidae/srcset/maulesel-640w.jpg"),
                    minSoftLimit: 2,
                    maxSoftLimit: 4,
                    minWithersHeight: 80,
                    maxWithersHeight: 190,
                    hasWithersHeightOption: true,
                    recommendations: {
                        layingArea: function(witherHeights) {
                            let layingArea = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea += 7.75;
                                } else if (witherHeights[i] < 134) {
                                    layingArea += 10.5;
                                } else if (witherHeights[i] < 148) {
                                    layingArea += 12;
                                } else if (witherHeights[i] < 162) {
                                    layingArea += 13.5;
                                } else {
                                    layingArea += 15.75;
                                }
                            }
                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 2) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                };
                            } else if (highest < 148) {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            }  else if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            }  else if (highest < 175) {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(numberOfAnimals) {
                            return 150 * numberOfAnimals;
                        }
                    },
                    laws: {
                        layingArea: function(witherHeights) {
                            let layingArea = {
                                value: 0,
                                tolerance: 0,
                                multiroom: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    layingArea.value += 5.5;
                                    layingArea.tolerance += 5.5;
                                    layingArea.multiroom += 4;
                                } else if (witherHeights[i] < 134) {
                                    layingArea.value += 7;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 4.5;
                                } else if (witherHeights[i] < 148) {
                                    layingArea.value += 8;
                                    layingArea.tolerance += 7;
                                    layingArea.multiroom += 5.5;
                                }  else if (witherHeights[i] < 162) {
                                    layingArea.value += 9;
                                    layingArea.tolerance += 8;
                                    layingArea.multiroom += 6;
                                } else {
                                    layingArea.value += 10.5;
                                    layingArea.tolerance += 9;
                                    layingArea.multiroom += 7.5;
                                }
                            }

                            if (witherHeights.length >= 5) {
                                layingArea.multiroom = Math.round(layingArea.multiroom * 8) / 10;
                            }

                            return layingArea;
                        },
                        clearWidth: function(witherHeights) {
                            let highest = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            return (highest * 1.5) / 100;
                        },
                        clearHeight: function(witherHeights) {
                            let highest = 0;
                            let clearHeight = 0;

                            for (let i in witherHeights) {
                                if (witherHeights[i] > highest) {
                                    highest = witherHeights[i];
                                }
                            }

                            if (highest < 120) {
                                clearHeight = {
                                    value: 1.8,
                                    tolerance: 0
                                };
                            } else if (highest < 134) {
                                clearHeight = {
                                    value: 1.9,
                                    tolerance: 0
                                };
                            } else if (highest < 148) {
                                clearHeight = {
                                    value: 2.1,
                                    tolerance: 2
                                };
                            } else if (highest < 162) {
                                clearHeight = {
                                    value: 2.3,
                                    tolerance: 2.2
                                };
                            } else {
                                clearHeight = {
                                    value: 2.5,
                                    tolerance: 2.2
                                };
                            }

                            return clearHeight;
                        },
                        runoutArea: function(witherHeights) {
                            let runoutArea = {
                                adjacent: 0,
                                nonadjacent: 0
                            };

                            for (let i in witherHeights) {
                                if (witherHeights[i] < 120) {
                                    runoutArea.adjacent += 12;
                                    runoutArea.nonadjacent += 18;
                                } else if (witherHeights[i] < 134) {
                                    runoutArea.adjacent += 14;
                                    runoutArea.nonadjacent += 21;
                                } else if (witherHeights[i] < 148) {
                                    runoutArea.adjacent += 16;
                                    runoutArea.nonadjacent += 24;
                                } else if (witherHeights[i] < 162) {
                                    runoutArea.adjacent += 20;
                                    runoutArea.nonadjacent += 30;
                                } else {
                                    runoutArea.adjacent += 24;
                                    runoutArea.nonadjacent += 36;
                                }
                            }

                            return runoutArea;
                        }
                    },
                }
            }
        },
    };
}
